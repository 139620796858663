import { Grid, Typography } from "@mui/joy";
import LayoutItem from "./LayoutItem";

const LayoutSelection = ({ onClick }: { onClick: any }) => {
    return (
        <div style={{ display: 'flex', width: "70%", height: "540px", flexDirection: 'column' }}>
            <div>
                <Typography level='h4' fontSize="xl">Choose a layout</Typography>
                <Typography level='body-sm' fontSize={'md'}>Select layout and the panel type for your thinking space</Typography>
            </div>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 8, lg: 10 }}
                sx={{ flexGrow: 1, marginTop: "50px" }}
            >
                <LayoutItem id={0} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "text",
                        gr10: "text"
                    }
                } />
                <LayoutItem id={1} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "text",
                        gr10: "draw"
                    }
                } />
                <LayoutItem id={2} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "draw",
                        gr10: "draw"
                    }
                } />
                <LayoutItem id={3} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "text",
                        gr10: "number"
                    }
                } />
                <LayoutItem id={4} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "text",
                        gr10: "code"
                    }
                } />
                <LayoutItem id={5} onClick={onClick} boxes={2} orderOfType={
                    {
                        gr00: "code",
                        gr10: "code"
                    }
                } />
                <LayoutItem id={6} onClick={onClick} boxes={3} orderOfType={
                    {
                        gr00: "text",
                        gr10: "draw",
                        gr11: "number"
                    }
                } />
                <LayoutItem id={7} onClick={onClick} boxes={3} orderOfType={
                    {
                        gr00: "text",
                        gr10: "code",
                        gr11: "number"
                    }
                } />
                <LayoutItem id={8} onClick={onClick} boxes={4} orderOfType={
                    {
                        gr00: "text",
                        gr10: "text",
                        gr01: "text",
                        gr11: "text"
                    }
                } />
                <LayoutItem id={9} onClick={onClick} boxes={4} orderOfType={
                    {
                        gr00: "text",
                        gr10: "text",
                        gr01: "text",
                        gr11: "number"
                    }
                } />
            </Grid>
        </div>
    );
}

export default LayoutSelection;