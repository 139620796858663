/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import { Button, Container, ContainerProps, FormControl, FormHelperText, FormLabel, Input, LinearProgress, Typography, useColorScheme } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../core/AuthProvider";
import xadlogo from '../assets/logoww.png';
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { LoginButton } from "../components/Navigation";
import TPLogo from '../assets/tp_long_logo.png';
import TPLogoW from '../assets/tp_logo_white.png';
import * as Sentry from "@sentry/react";
import { Add } from "@mui/icons-material";
import { createNewSpace } from "./functions/spacemgmt";
import { isMobile } from "react-device-detect";
import { firstTabOfWid, firstWidNameOfUser, getLastWorkedWid } from "../utilities/common";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../state/store";
import { setIsNewTabOpening } from "../state/allEditorsData/allEditorDataSlice";

const envRunning = import.meta.env.PROD;

export const Login = (): JSX.Element => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [txtUsername, setTxtUsername] = useState<string>("");
    const [txtPassword, setTxtPassword] = useState<string>("");
    const [txtConfirmPassword, setTxtConfirmPassword] = useState<string>("");
    const authContext = useContext(AuthContext);
    const { user, initializing, handleGoogleLogin, createUser, forgotPassword, handleLinkCredentialForUnamePwd, handleLinkGoogleCredential, signInAnon,
        handleLinkGithubCredential, handleGithubLogin
    } = authContext;
    const [loggingin, setLoggingIn] = useState<boolean>(false);
    const [mode, setMode] = useState<number>(0);
    const [creatingSpace, setCreatingSpace] = useState<boolean>(false);
    const [showTryBtn, setShowTryBtn] = useState<boolean>(true);

    const [usernameError, setUsernameError] = useState<boolean>(false);
    const [usernameErrorText, setUsernameErrorText] = useState<string>("");

    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [passwordErrorText, setPasswordErrorText] = useState<string>("");

    const { mode: cmode, systemMode } = useColorScheme();


    const dispatch = useDispatch<AppDispatch>();



    const signInInSpace = async (user: any) => {

        const wid = sessionStorage.getItem("wid");
        const tid = sessionStorage.getItem("tid");

        // console.log("wid", wid, "tid", tid);
        if (wid && tid) {
            navigate('/' + wid + '/' + tid);
        } else {

            const userData = await getDocs(collection(db, "data", user.uid, "userInfo"));

            if (userData.docs.length === 0) {
                const userId = user?.uid;
                await setDoc(doc(db, "data", userId, "userInfo", userId), {
                    id: userId,
                    name: user?.displayName,
                    email: user?.email,
                    photo: user?.photoURL,
                    created: new Date().getTime(),
                    modified: new Date().getTime(),
                    plan: "free",
                    planStarted: new Date().getTime(),
                    paid: false
                });
            }


            let rWid = null;
            const lastWid = await getLastWorkedWid(user);
            const wid = await firstWidNameOfUser(user.uid);

            if (lastWid) {
                rWid = lastWid;
            } else if (wid) {
                rWid = wid.data.wid;
            } else { //anonymous new user doesn't have any

            }

            if (rWid) {
                sessionStorage.setItem("userId", user?.uid);
                sessionStorage.setItem("wid", rWid);
                sessionStorage.setItem("tid", "1");
                if (tid) {
                    navigate('/' + rWid + '/' + tid);
                } else {
                    const firstTab = await firstTabOfWid(rWid);
                    // console.log("First tab1", firstTab);
                    if (firstTab) {
                        navigate('/' + rWid + '/' + firstTab);
                    } else {
                        navigate('/' + rWid + '/1');//anon user new worspace
                    }
                }
            } else {
                const { wid } = await createNewSpace(user);
                // if (tid) {
                //     navigate('/' + wid + '/' + tid);
                // } else {


                dispatch(setIsNewTabOpening(true));

                navigate('/' + wid + '/1');//anon/user new worspace
                // }
            }
        }

    }

    const signIn = async () => {
        setLoggingIn(true);
        try {
            // console.log("Sign in with username: ", txtUsername, " and password: ", txtPassword);
            const { user } = await signInWithEmailAndPassword(auth, txtUsername, txtPassword);
            await signInInSpace(user);

        } catch (error) {
            Sentry.captureException(error);
            setLoggingIn(false);
        }

    }

    useEffect(() => {
        document.title = "Login - ThinkPost";
    }, [])

    useEffect(() => {
        // console.log("User", initializing, user);
        if (!initializing) {
            if (user && user.uid) {
                const wid = sessionStorage.getItem("wid");
                const tid = sessionStorage.getItem("tid");

                //scenarios are, anonymous user coming to signup or logged in user coming here by mistake
                if (user?.providerData?.length > 0) {
                    signInInSpace(user);
                } else {
                    if (!creatingSpace && user.isAnonymous) { //if not under creating space, it is for signup
                        setLoading(false);
                        setShowTryBtn(false);
                    } else if (wid && tid) {
                        navigate('/' + wid + '/' + tid);
                    }
                }


                // if (user.uid) {
                //     if (id === "0") {
                //         if (wid && tid) {
                //             // console.log("logged in user1", user.uid, wid, tid);
                //             navigate('/' + wid + '/' + tid);
                //         } else {
                //             // console.log("logged in user2", user);
                //             firstWidNameOfUser(user.uid).then(async (wid: any) => {

                //                 // console.log("Wid", wid);
                //                 if (wid) {
                //                     sessionStorage.setItem("userId", user?.uid);
                //                     sessionStorage.setItem("wid", wid.data.wid);
                //                     sessionStorage.setItem("tid", "1");
                //                     navigate('/' + wid.data.wid + '/1');
                //                 } else {
                //                     const { wid } = await createNewSpace(user);
                //                     navigate('/' + wid + '/1');
                //                 }
                //             });

                //         }
                //     } else {
                //         //anonymous user signing up
                //         if (user?.providerData?.length > 0) {
                //             //has a provider, so should be signed up
                //             // console.log("logged in user2", user);
                //             firstWidNameOfUser(user.uid).then(async (wid: any) => {

                //                 // console.log("Wid", wid);
                //                 if (wid) {
                //                     sessionStorage.setItem("userId", user?.uid);
                //                     sessionStorage.setItem("wid", wid.data.wid);
                //                     sessionStorage.setItem("tid", "1");
                //                     navigate('/' + wid.data.wid + '/1');
                //                 } else {
                //                     const { wid } = await createNewSpace(user);
                //                     navigate('/' + wid + '/1');
                //                 }
                //             });
                //         } else {
                //             //signup
                //             setLoading(false);
                //         }

                //     }
                // }
            } else {
                //or guest user landed here wants to signup
                setLoading(false);
            }
        }
    }, [user, initializing, creatingSpace])

    const signInAnony = async () => {
        setLoading(true);
        if (!initializing && !user) {
            // console.log("Anonymous sign in")
            signInAnon(() => {
                setLoading(false);
            }, async (user: any) => {
                if (user) {
                    setCreatingSpace(true);
                    await signInInSpace(user);
                }
            })
        }
    }

    const goBackToSpace = () => {
        const userId = user?.uid;
        const wid = sessionStorage.getItem("wid");
        const tid = sessionStorage.getItem("tid");

        if (wid && tid) {
            navigate('/' + wid + '/' + tid);
        } else {
            firstWidNameOfUser(userId).then(async (wid: any) => {

                // console.log("Wid", wid);
                if (wid) {
                    sessionStorage.setItem("userId", user?.uid);
                    sessionStorage.setItem("wid", wid.data.wid);
                    sessionStorage.setItem("tid", "1");
                    navigate('/' + wid.data.wid + '/1');
                } else {
                    const { wid } = await createNewSpace(user);
                    navigate('/' + wid + '/1');
                }
            });
        }
    }

    const returnLoginPart = () => {
        return (
            <>
                <div style={{ display: "flex", width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    {cmode === "light" || (cmode === "system" && systemMode === "light") ? <img src={TPLogo} alt="logo" style={{ height: "40px", width: "180px" }} /> : <img src={TPLogoW} alt="logo" style={{ height: "40px", width: "180px" }} />}
                </div>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "80%",
                        gap: 1,
                        maxWidth: "280px !important",
                        position: 'relative',
                        paddingTop: "60px"
                    }}
                    maxWidth="xs"
                >

                    {mode === 0 ? (
                        <>
                            <LoginButton disabled={loggingin || initializing} onClick={() => {
                                setLoggingIn(true);
                                if (user && user.isAnonymous) {
                                    handleLinkGoogleCredential(() => {
                                        alert("Error linking account, Please try again with a different account.")
                                        setLoggingIn(false);
                                    }, async (user: any) => {
                                        await signInInSpace(user);
                                        setLoggingIn(false);
                                    });
                                } else {
                                    handleGoogleLogin(() => {
                                        setLoggingIn(false);
                                    }, async (user: any) => {
                                        await signInInSpace(user);
                                        setLoggingIn(false);
                                    });
                                }
                            }} signInMethod="google.com" />
                            {envRunning ? (<LoginButton disabled={loggingin || initializing} onClick={() => {
                                setLoggingIn(true);
                                if (user && user.isAnonymous) {
                                    handleLinkGithubCredential(() => {
                                        alert("Error linking account, Please try again with a different account.")
                                        setLoggingIn(false);
                                    }, async (user: any) => {
                                        await signInInSpace(user);
                                        setLoggingIn(false);
                                    });
                                } else {
                                    handleGithubLogin(() => {
                                        alert("Error linking account, Please try again with a different account.")
                                        setLoggingIn(false);
                                    }, async (user: any) => {
                                        await signInInSpace(user);
                                        setLoggingIn(false);
                                    });
                                }
                            }} signInMethod="github.com" />) : null}

                            <Input sx={{ marginTop: "30px" }} value={txtUsername} onChange={
                                (e) => setTxtUsername(e.target.value)
                            } placeholder="Username" />
                            <Input type='password' value={txtPassword} onChange={
                                (e) => setTxtPassword(e.target.value)
                            } placeholder="Password" onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    signIn();
                                }
                            }} />

                            <Button disabled={loggingin || initializing} onClick={signIn}>Sign In</Button>
                            <Button disabled={loggingin || initializing} onClick={() => setMode(3)} children={"Forgot Password?"} variant="plain" />
                            {showTryBtn ? (
                                <>
                                    <Typography level='body-md' sx={{ marginTop: "20px", textAlign: 'center' }}>Try our app (no login needed)</Typography>
                                    <Button variant='solid' disabled={loggingin || initializing} onClick={signInAnony} color='primary' startDecorator={<Add />}>Open a new space</Button>

                                </>
                            ) : <>
                                <Typography level='body-md' sx={{ marginTop: "20px", textAlign: 'center' }}>Try our app (no login needed)</Typography>
                                <Button variant='solid' disabled={loggingin || initializing} onClick={goBackToSpace} color='primary' startDecorator={<Add />}>Go back to space</Button>

                            </>}
                            <Button style={{ marginTop: "30px" }} disabled={loggingin || initializing} onClick={() => {
                                setMode(1);
                            }} children={"Sign Up!"} variant="soft" />
                            {/* <Button disabled={loggingin || initializing} onClick={() => setMode(3)} children={"Forgot Password?"} variant="outlined" /> */}
                        </>
                    ) : mode === 1 ? (
                        <>
                            {/* <Input value={txtUsername} onChange={
                                (e) => {
                                    setTxtUsername(e.target.value)
                                }
                            } placeholder="Username" />
                            <Input type='password' value={txtPassword} onChange={
                                (e) => {
                                    setTxtPassword(e.target.value)
                                }
                            } placeholder="Password" />
                             <Input type='password' value={txtPassword} onChange={
                                (e) => {
                                    setTxtPassword(e.target.value)
                                }
                            } placeholder="Password" /> */}
                            <FormControl sx={{ marginTop: "10px" }} error={usernameError}>
                                <FormLabel>Email:</FormLabel>
                                <Input
                                    type={"email"}
                                    value={txtUsername}
                                    placeholder="Email"
                                    onChange={(e) => {
                                        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                            e.target.value
                                        )) {
                                            setUsernameError(true);
                                            setUsernameErrorText("Invalid email address");
                                        } else {
                                            setUsernameError(false);
                                            setUsernameErrorText("");
                                        }
                                        setTxtUsername(e.target.value)
                                    }}
                                />
                                <FormHelperText>{usernameErrorText}</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ marginTop: "10px" }} error={passwordError}>
                                <FormLabel>New Password:</FormLabel>
                                <Input
                                    value={txtPassword}
                                    onChange={(e) => {
                                        setPasswordError(false);
                                        setTxtPassword(e.target.value)
                                    }}
                                    placeholder="Password"
                                    type={"password"}
                                />
                            </FormControl>
                            <FormControl sx={{ marginTop: "10px" }} error={passwordError}>
                                <FormLabel>Confirm Password:</FormLabel>
                                <Input
                                    value={txtConfirmPassword}
                                    onChange={(e) => {
                                        setPasswordError(false);
                                        setTxtConfirmPassword(e.target.value)
                                    }}
                                    placeholder="Confirm password"
                                    type={"password"}
                                />
                                <FormHelperText>{
                                    passwordErrorText
                                }</FormHelperText>
                            </FormControl>

                            <Button sx={{ marginTop: "20px" }} disabled={loggingin || initializing} onClick={() => {
                                setLoggingIn(true);
                                if (user && user.isAnonymous) {
                                    handleLinkCredentialForUnamePwd(txtUsername, txtPassword, () => {
                                        alert("Error linking account, Please try again with a different account.")
                                        setLoggingIn(false);
                                    }, async (user: any) => {
                                        await signInInSpace(user);
                                        setLoggingIn(false);
                                    });
                                } else {

                                    // console.log('txtPassword', txtPassword)

                                    if (txtPassword !== txtConfirmPassword) {
                                        setPasswordError(true);
                                        setPasswordErrorText("Passwords do not match");
                                        setLoggingIn(false);
                                        return;
                                    }

                                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

                                    if (txtPassword.length < 6 || !passwordRegex.test(txtPassword)) {
                                        setPasswordError(true);
                                        setPasswordErrorText("Password must be at least 6 characters long including 1 uppercase, 1 lowercase, 1 number and 1 special character");
                                        setLoggingIn(false);
                                        return;
                                    }

                                    setPasswordError(false);
                                    setPasswordErrorText("");

                                    createUser(txtUsername, txtPassword, () => {
                                        setLoggingIn(false);
                                        setMode(0);
                                    });
                                }

                            }}>Sign Up</Button>
                            <Button style={{ marginTop: "30px" }} disabled={loggingin || initializing} onClick={() => {
                                setMode(0);
                            }} children={"Back"} variant="outlined" />
                        </>
                    ) : (
                        <>
                            <Input value={txtUsername} onChange={
                                (e) => setTxtUsername(e.target.value)
                            } placeholder="Username" />

                            <Button disabled={loggingin || initializing} onClick={() => {
                                setLoggingIn(true);
                                forgotPassword(txtUsername, () => {
                                    setLoggingIn(false);
                                }, () => {
                                    setLoggingIn(false);
                                    alert("Password reset link has been sent to the email, Please check in the Spam/Junk folder if it is not in the inbox.");
                                    setMode(0);
                                });
                            }}>Reset my password</Button>
                            <Button disabled={loggingin || initializing} style={{ marginTop: "30px" }} onClick={() => {
                                setMode(0);
                            }} children={"Back"} variant="outlined" />
                        </>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: "40px" }}>
                        <Typography sx={{ textAlign: 'center', color: "gray", fontSize: "13px" }}>By signing in or using our app, you are agreeing to</Typography>

                        <a href="https://thinkpost.io/termsconditions.html" target='_blank' style={{ textDecorationLine: 'none' }}><Typography sx={{ marginTop: "20px", color: "gray", fontSize: "13px" }}><b>Terms of services</b></Typography></a>
                        <a href="https://thinkpost.io/privacypolicy.html" target='_blank' style={{ textDecorationLine: 'none' }}><Typography sx={{ color: "gray", fontSize: "13px" }}><b>Privacy Policy</b></Typography></a>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: "20px", marginTop: "30px" }}>
                        <a target='_blank' href="https://zameermfm.medium.com"><img src={xadlogo} alt="logo" style={{ height: "19px", width: "80px" }} /></a>
                    </div>
                </Container ></>)
    }

    return loading ? (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "80%",
                gap: 1,
                maxWidth: "280px !important",
            }} maxWidth="xs"><div className="linear-progress-container">
                <div className="linear-progress">
                    <LinearProgress size="md" />
                </div>
            </div></Container>) : isMobile ? (
                <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {returnLoginPart()}
                </div>
            ) : (
        <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {returnLoginPart()}
            </div >
        </div >
    );
};

export type LoginProps = Omit<ContainerProps, "children">;