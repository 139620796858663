// AuthProvider.js
import {
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider, signInWithPopup,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInAnonymously,
    EmailAuthProvider,
    linkWithCredential,
    linkWithPopup,
    GithubAuthProvider
} from "firebase/auth";
import { createContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../firebase";
import { AppDispatch } from "../state/store";
import { useDispatch } from "react-redux";
import { replaceAllEditorsData, setAllEditorsData, setAllTabs, setAllTabsUnsavedData, setCurrentTabShared, setCurrentWorkspaceSmallInfo, setExportLayoutOrder, setIsSpaceIsOwner, setShowNavBar, setUnsavedChanges } from "../state/allEditorsData/allEditorDataSlice";
import * as Sentry from "@sentry/react";

export const AuthContext = createContext<any>(null);

function AuthProvider({ children }: { children: PropTypes.ReactNodeLike }) {
    let [initializing, setInitializing] = useState(true);
    let [user, setUser] = useState<any>(null);
    let [userCrendential, setUserCredential] = useState<any>(null);
    const dispatch = useDispatch<AppDispatch>();

    const createUser = (email: string, password: string, errorCallBack: any) => {
        setInitializing(true);

        try {

            createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
            Sentry.captureException(error);
            setInitializing(false);
            errorCallBack()
        }
    };

    const forgotPassword = (email: string, errorCallBack: any, successCallback: any,) => {
        setInitializing(true);
        try {
            sendPasswordResetEmail(auth, email);
            setInitializing(false);
            successCallback();
        } catch (error) {
            Sentry.captureException(error);
            setInitializing(false);
            errorCallBack()
        }
    }

    let authChanged = useCallback((firebaseUser: any) => {
        // console.log("Auth changed", firebaseUser);
        if (firebaseUser) {
            firebaseUser.getIdToken().then((idToken: any) => {
                localStorage.setItem("userIdno", idToken);
            }).catch((error: any) => {
                Sentry.captureException(error);
                // console.log("Error getting id token", error);
            });
            setUser(firebaseUser);
        }
        setInitializing(false);
    }, []);

    const handleLinkCredentialForUnamePwd = async (email: string, password: string, errorCallBack: any, successCallback: any) => {
        if (!user) return errorCallBack("User not found");
        setInitializing(true);
        const credential = EmailAuthProvider.credential(email, password);
        linkWithCredential(user, credential)
            .then((usercred) => {
                setInitializing(false);
                successCallback(usercred.user);
                setUser(user);
            }).catch((error) => {
                Sentry.captureException(error);
                setInitializing(false);
                // console.log("Account linking error", error);
                errorCallBack("Account linking error");
            });
    }

    const handleLinkGithubCredential = async (errorCallBack: any, successCallback: any) => {
        if (!user) return errorCallBack("User not found");
        setInitializing(true);
        const provider = new GithubAuthProvider();
        linkWithPopup(user, provider).then((result) => {
            // Accounts successfully linked.
            const credential = GithubAuthProvider.credentialFromResult(result);
            setUserCredential(credential);
            setUser(result.user);
            setInitializing(false);
            successCallback(result.user);
        }).catch((error) => {
            Sentry.captureException(error);
            setInitializing(false);
            // console.log("Account linking error", error);
            errorCallBack("Account linking error");
        });
    }

    const handleLinkGoogleCredential = async (errorCallBack: any, successCallback: any) => {

        if (!user) return errorCallBack("User not found");
        setInitializing(true);
        const provider = new GoogleAuthProvider();
        linkWithPopup(user, provider).then((result) => {
            // Accounts successfully linked.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            setUserCredential(credential);
            setUser(result.user);
            setInitializing(false);
            successCallback(result.user);
        }).catch((error) => {
            Sentry.captureException(error);
            setInitializing(false);
            // console.log("Account linking error", error);
            errorCallBack("Account linking error");
        });
    }

    const handleGoogleLogin = async (errorCallBack: any, successCallback: any) => {
        setInitializing(true);
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: "select_account",

        })
        try {
            const result = await signInWithPopup(auth, provider);
            // console.log('User Info:', result.user);
            successCallback(result.user);
            setUser(result.user);
        } catch (error) {
            Sentry.captureException(error);
            setInitializing(false);
            errorCallBack()
        }
    };

    const handleGithubLogin = async (errorCallBack: any, successCallback: any) => {
        setInitializing(true);
        const provider = new GithubAuthProvider();
        provider.setCustomParameters({
            allow_signup: 'true'
        })
        try {
            const result = await signInWithPopup(auth, provider);
            // console.log('User Info:', result.user);
            successCallback(result.user);
            setUser(result.user);
        } catch (error) {
            Sentry.captureException(error);
            setInitializing(false);
            errorCallBack()
        }
    }


    useEffect(() => {
        const subscriber = onAuthStateChanged(auth, authChanged);
        return subscriber;
    }, [authChanged]);

    let signInAnon = async (errorCallback: any, successCallback: any,) => {
        setInitializing(true);
        try {
            let res = await signInAnonymously(
                auth
            );

            if (res.user) {
                setUser(res.user);
                return successCallback(res.user)
            };

            errorCallback("Something went Wrong.");
        } catch (error) {
            Sentry.captureException(error);
            setInitializing(false);
            // console.log('error logging in', error)
            errorCallback("Something went Wrong.");
        }
    }

    let signout = async (callback: any) => {
        try {
            await signOut(auth);
            //clear all local storage
            sessionStorage.setItem("hideNavBar", "false");
            sessionStorage.setItem("allTabInfo", "");
            sessionStorage.setItem("allspaces", "");
            sessionStorage.setItem("userId", "");
            localStorage.setItem("userIdno", "");
            sessionStorage.setItem("wid", "");
            sessionStorage.setItem("tid", "");

            //reset redux states
            dispatch(setAllEditorsData([]));
            dispatch(setAllTabsUnsavedData({}));
            dispatch(replaceAllEditorsData([]));
            dispatch(setUnsavedChanges(false))
            dispatch(setShowNavBar(true));
            // dispatch(saveDataBlocksBackup([]));
            dispatch(setCurrentTabShared(false));
            dispatch(setIsSpaceIsOwner(false));
            dispatch(setCurrentWorkspaceSmallInfo({}))
            dispatch(setAllTabs([]));
            dispatch(setExportLayoutOrder([]));

            setUser(null);
            callback();
        } catch (error) {
            Sentry.captureException(error);
            // console.log('error logging out', error)
        }

    };

    // console.log("initializing123", initializing, user);

    return (
        <AuthContext.Provider value={{
            initializing, user, userCrendential, signout, handleGoogleLogin, createUser, forgotPassword, signInAnon,
            handleLinkCredentialForUnamePwd, handleLinkGoogleCredential, handleGithubLogin, handleLinkGithubCredential
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
