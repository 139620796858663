import { configureStore } from "@reduxjs/toolkit";
import allEditorsReducer from "./allEditorsData/allEditorDataSlice";
import mobileNavData from "./mobileNavData/mobileNavData";

export const store = configureStore({
    reducer: {
        allEditorsData: allEditorsReducer,
        mobileNavData: mobileNavData,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;