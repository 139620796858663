import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

const loadCss = (href: any, onLoad: any, onError: any) => {
    const link = document.createElement('link');
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = href;
    link.onload = onLoad;
    link.onerror = onError;
    link.setAttribute('dynamic-theme', 'true');
    return link;
};

const ReadonlyCssLoader = () => {
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);

    useEffect(() => {
        let linkElement = null;
        if (allEditorsData.currentTabShared) {
            const existingLink = document.querySelector('link[dynamic-theme]');
            if (existingLink) {
                document.head.removeChild(existingLink);
            }

            const cssFile = '/readonlyEditor.css';
            linkElement = loadCss(cssFile,
                () => {
                    // console.log('CSS loaded successfully')
                },
                () => {
                    // console.error('Error loading CSS')
                }
            );
            document.head.appendChild(linkElement);
        }
        return () => {
            if (linkElement) {
                Array.from(document.head.children).forEach((child: any) => {
                    if (child === linkElement) {
                        document.head.removeChild(child);
                    }
                });
            }
        };
    }, [allEditorsData.currentTabShared]);

    return null;
};

export default ReadonlyCssLoader;
