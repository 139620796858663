import { ContentCopy, Download, Publish } from "@mui/icons-material";
import { Button, DialogContent, DialogTitle, Divider, IconButton, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import copy from 'copy-to-clipboard';
import { mainParser } from "./FileExporterFuncs/MarkDown/MainParser";
import { setDrawingUploadingImageStatus, setDrawPanelExportTriggerFlag, setExportLayoutOrder } from "../../state/allEditorsData/allEditorDataSlice";
import { AuthContext } from "../../core/AuthProvider";
import { useParams } from "react-router-dom";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import showdown from 'showdown';
// import ajax from "@codexteam/ajax";


var converter = new showdown.Converter();
converter.setOption('tables', true);
converter.setOption('tasklists', true);
converter.setOption('simpleLineBreaks', true);
converter.setOption('openLinksInNewWindow', true);
converter.setOption('emoji', true);
converter.setOption('strikethrough', true);
converter.setOption('underline', true);
converter.setOption('smoothLivePreview', true);
converter.setOption('simplifiedAutoLink', true);


const FileExporterPanel = ({ exportOpen, setExportOpen }: { exportOpen: boolean, setExportOpen: any }) => {
    const dispatch = useDispatch<AppDispatch>();
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const [exportMode, setExportMode] = useState<any>(0);
    const [selectedPanels, setSelectedPanels] = useState<any>([]);
    const [copyBtnDisabled, setCopyBtnDisabled] = useState<boolean>(false);
    const [disableDownloadBtn, setDisableDownloadBtn] = useState<boolean>(false);
    const [exportMarkdownMode, setExportMarkdownMode] = useState<any>(0);
    const [takeOffClearanceForExport, setTakeOffClearanceForExport] = useState<boolean>(false);
    const [selectedTool, setSelectedTool] = useState<any>(0);
    const [panelSizes, setPanelSizes] = useState<any>([]);
    // const apiUrl = import.meta.env.VITE_ENDPOINT_URL_BASE;

    const panelToPanelIdMap = {
        1: "hpanel_0_0",
        2: "hpanel_1_0",
        3: "hpanel_0_1",
        4: "hpanel_1_1",
    }

    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";

    const authContext = useContext(AuthContext);
    const { user } = authContext;

    useEffect(() => {

        setExportMode(0);
    }, [wid, tid])

    useEffect(() => {
        const rPanelSizes = allEditorsData.dataBlocks.map((data: any) => {
            return data.size;
        });

        setPanelSizes(rPanelSizes);


    }, [wid, tid, allEditorsData.dataBlocks])

    useEffect(() => {
        if (allEditorsData.cTabexportLayoutOrder) {

            // console.log(allEditorsData.cTabexportLayoutOrder);
            // 
            //restructure panelSize clone to have the same index as the panel id
            // console.log({ panelSizes });
            const panelSizeClone = JSON.parse(JSON.stringify(panelSizes));
            //swap second and third index
            const temp = panelSizeClone[1];
            panelSizeClone[1] = panelSizeClone[2];
            panelSizeClone[2] = temp;
            // console.log({ panelSizeClone });

            const filteredPanels = allEditorsData.cTabexportLayoutOrder.filter((panel: any) => panelSizeClone[panel - 1] > 10);

            // console.log({ filteredPanels }, allEditorsData.cTabexportLayoutOrder, { panelSizeClone });
            // setSelectedPanels(filteredPanels);


            setSelectedPanels(filteredPanels);
        } else {
            setSelectedPanels([]);
        }
    }, [allEditorsData.cTabexportLayoutOrder, tid, wid, panelSizes])

    const handlePanelSelection = (panel: any) => {
        let rSelectedPanels = [];
        if (selectedPanels.includes(panel)) {
            rSelectedPanels = selectedPanels.filter((p: any) => p !== panel)
            setSelectedPanels(rSelectedPanels);
        } else {
            rSelectedPanels = [...selectedPanels, panel];
            setSelectedPanels(rSelectedPanels);
        }

        // console.log({ rSelectedPanels });
        dispatch(setExportLayoutOrder(rSelectedPanels));
        saveTabExportLayoutData(rSelectedPanels);
    }

    const saveTabExportLayoutData = async (rSelectedPanels: any) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "spaces", wid, "tabs", tid, "tabInfo", "exportlayout"), {
            layout: rSelectedPanels
        }, { merge: true });


        await batch.commit();
    }

    // useEffect(() => {
    //     console.log({ takeOffClearanceForExport })
    // }, [takeOffClearanceForExport])

    useEffect(() => {
        if (takeOffClearanceForExport && exportMarkdownMode !== 0) {

            const drawPanels = allEditorsData.dataBlocks.filter((data: any) => data.mode === 'draw');
            if (drawPanels.length > 0) {
                drawPanels.forEach((panelData: any) => {
                    dispatch(setDrawingUploadingImageStatus({ id: panelData.id, status: 0 }))
                });
            }
            continueMarkdownParse();

            setTakeOffClearanceForExport(false);
            setExportMarkdownMode(0);
        }
    }, [takeOffClearanceForExport, exportMarkdownMode])

    // const downloadMarkdownPdf = (markdown: any,) => {
    //     console.log("uploaded pdf file", markdown)
    //     ajax.post({
    //         url: apiUrl + 'convertmdPdf',
    //         data: {
    //             markdown
    //         },
    //         type: ajax.contentType.JSON,
    //         // headers: envRunning && idTokenNew && {
    //         //     Authorization: idTokenNew
    //         // },
    //     }).then((response: any) => {
    //         console.log("uploaded pdf success file", response)
    //         // return {
    //         //     success: 1,
    //         //     file: {
    //         //         url: response.body.file.url,
    //         //         name: response.body.file.name,
    //         //         size: response.body.file.size,
    //         //         title: response.body.file.title,
    //         //         extension: response.body.file.extension,
    //         //         // any other image data you want to store, such as width, height, color, extension, etc
    //         //     },
    //         //     user
    //         // };
    //         setDisableDownloadBtn(false);
    //     }).catch((error: any) => {
    //         setDisableDownloadBtn(false);
    //         console.log("uploaded pdf error file", error)
    //     });
    // }

    // const startPdf = () => {
    //     setDisableDownloadBtn(true);
    //     setExportMarkdownMode(3);
    //     mardownParse()
    // }

    const downloadMarkdownFile = (data: any) => {
        if (!data) return;

        if (selectedTool === 0) {
            const element = document.createElement('a');
            const file = new Blob([data], { type: 'text/markdown' });
            element.href = URL.createObjectURL(file);
            element.download = 'exported.md';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } else if (selectedTool === 1) {
            const html = converter.makeHtml(data);
            const element = document.createElement('a');
            const file = new Blob([html], { type: 'text/html' });
            element.href = URL.createObjectURL(file);
            element.download = 'exported.html';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    }

    useEffect(() => {
        const drawPanels = allEditorsData.dataBlocks.filter((data: any) => data.mode === 'draw');

        if (drawPanels.length > 0 && exportMarkdownMode === 1 || exportMarkdownMode === 2) {

            // console.log("allEditorsData.drawingUploadingImageStatus", allEditorsData.drawingUploadingImageStatus)
            // console.log("allEditorsData.drawingFileUploadingImageStatus", allEditorsData.drawingFileUploadingImageStatus)
            // const allObjectKeys = Object.keys(allEditorsData.drawingUploadingImageStatus);
            // const allObjectFileKeys = Object.keys(allEditorsData.drawingFileUploadingImageStatus);

            // const allImagesAreExported = allObjectKeys.every((key: any) => {
            //     return allEditorsData.drawingUploadingImageStatus[key].exportedPath !== undefined || allEditorsData.drawingUploadingImageStatus[key].exportedPath !== null;
            // });

            // const allFilesAreExported = allObjectFileKeys.every((key: any) => {
            //     return allEditorsData.drawingFileUploadingImageStatus[key].exportedFilePath !== undefined || allEditorsData.drawingFileUploadingImageStatus[key].exportedFilePath !== null;
            // });

            // console.log({ allAreExported })

            const allImagesAreExported = allEditorsData.drawingUploadingImageStatus.map((data: any) => {
                return data.exportedPath !== undefined || data.exportedPath !== null;
            });

            const allFilesAreExported = allEditorsData.drawingFileUploadingImageStatus.map((data: any) => {
                return data.exportedFilePath !== undefined || data.exportedFilePath !== null;
            });

            let allClearedForTakeOff: any = [];
            if (allImagesAreExported && allFilesAreExported) {
                drawPanels.forEach((panelData: any) => {
                    if (panelData.exportedPath) {
                        allClearedForTakeOff.push(true);
                    }
                });
            }

            // console.log(allClearedForTakeOff.length, drawPanels.length);

            // console.log({ allClearedForTakeOff })

            if (allClearedForTakeOff.length === drawPanels.length) {

                // console.log('take off')
                setTakeOffClearanceForExport(true);
            }
        } else {
            setTakeOffClearanceForExport(true);
        }
    }, [allEditorsData.dataBlocks, allEditorsData.drawingUploadingImageStatus, exportMarkdownMode])

    const mardownParse = () => {

        //check if there are panels with draw mode
        const drawPanels = allEditorsData.dataBlocks.filter((data: any) => data.mode === 'draw');
        if (drawPanels.length > 0) {
            let newPanelTriggerData = {};
            drawPanels.forEach((panelData: any) => {
                const panelId = panelData.id;
                const flag = allEditorsData.drawPanelExportTriggerFlag[panelId]?.flag || false;
                // console.log({ panelId, flag })
                const newObj = { [panelId]: !flag };
                newPanelTriggerData = { ...newPanelTriggerData, ...newObj };
            });

            dispatch(setDrawPanelExportTriggerFlag(newPanelTriggerData));
        }
    }

    const continueMarkdownParse = () => {
        let allData: any = [];
        const selectedPanelIds = selectedPanels.map((panel: 1 | 2 | 3 | 4) => panelToPanelIdMap[panel]);
        selectedPanelIds.forEach((panel: any) => {
            //text editors parsing
            const panelData = allEditorsData.dataBlocks.find((data: any) => data.id === panel) as any;
            // console.log('panelData.mode', panelData.mode)
            if (panelData.mode === 'edit' || !panelData.mode) {
                if (panelData.data && panelData.data?.blocks) {
                    const parsedData = mainParser(panelData.data?.blocks);
                    allData = [...allData, ...parsedData];
                }
            } else if (panelData.mode === 'draw') {
                // console.log('panelData', panelData)
                allData.push(`![image](${panelData.exportedPath})\n`);
                allData.push(`[Excalidraw File](${panelData.exportedFilePath})\n`);
            } else if (panelData.mode === 'code') {
                allData.push("```");
                allData.push(panelData.data);
                allData.push("```");
            } else if (panelData.mode === 'math') {
                // [
                //     {
                //       "id": 0,
                //       "expression": "Apples 45*23",
                //       "height": 32,
                //       "text": 1035
                //     }
                //   ]

                const mathData = panelData.data;
                allData.push('| Expression | Value |');
                allData.push('|---|---|');
                mathData.forEach((data: any) => {
                    allData.push(`| ${data.expression} | ${data.text === undefined || data.text === null ? "" : data.text} |`);
                });
            }
            allData.push('\n');
        });
        // console.log({ allData });
        const newData = allData.map((data: any) => data || " ").join('\n');

        if (exportMarkdownMode === 1) {
            downloadMarkdownFile(newData);
            setDisableDownloadBtn(false);
            setExportMarkdownMode(0);
        } else if (exportMarkdownMode === 2) {

            if (selectedTool === 0) {
                copy(newData);
            } else if (selectedTool === 1) {
                const html = converter.makeHtml(newData);
                copy(html);
            }
            setTimeout(() => {
                setCopyBtnDisabled(false);
                setExportMarkdownMode(0);
            }, 1000);

        }
        // else if (exportMarkdownMode === 3) {
        //     downloadMarkdownPdf(newData);
        //     setExportMarkdownMode(0);
        // }
    }

    const exportFileSaveMarkdown = () => {
        setTakeOffClearanceForExport(false);
        setDisableDownloadBtn(true);
        setExportMarkdownMode(1);
        mardownParse()
    }

    const exportCopyDataMarkdown = () => {
        setTakeOffClearanceForExport(false);
        setCopyBtnDisabled(true);
        setExportMarkdownMode(2);
        mardownParse()
    }

    // console.log(panelSizes.length > 0, panelSizes[0], panelSizes[0] > 90)

    return (<Modal
        aria-labelledby="exporting"
        aria-describedby="exporting the space and tab"
        open={exportOpen}
        onClose={() => setExportOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        <ModalDialog>
            <DialogTitle>Export</DialogTitle>
            {exportMode === 0 ? (
                <>
                    <DialogContent>Please arrange the tab contents for consolidated export onto a single page.<br /> (Click on a panel to select)</DialogContent>
                    <ModalClose variant="plain" sx={{ m: 1 }} />

                    <div style={{ width: "100%", height: "230px", marginTop: "50px" }}>
                        <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: "20px", alignItems: 'center', justifyContent: 'center', }}>
                            <div style={{ display: "flex", flexDirection: "column", flex: 0.5, justifyContent: 'center', height: "200px" }}>
                                <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", gap: "10px" }}
                                >
                                    {(panelSizes.length > 0 && panelSizes[0] && panelSizes[0] > 10) ? <Button variant={
                                        selectedPanels.includes(1) ? "solid" : "outlined"} color={
                                            selectedPanels.includes(1) ? "primary" : "neutral"
                                        } sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px", justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                            handlePanelSelection(1);
                                        }}>1</Button> : (
                                        <Button variant="outlined" disabled sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px" }}></Button>
                                    )}
                                    {(panelSizes.length > 2 && panelSizes[2] && panelSizes[2] > 10) ? <Button variant={
                                        selectedPanels.includes(2) ? "solid" : "outlined"
                                    } color={
                                        selectedPanels.includes(2) ? "primary" : "neutral"
                                    } sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px", justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                        handlePanelSelection(2);
                                    }}>2</Button> : (
                                        <Button variant="outlined" disabled sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px" }}></Button>
                                    )}
                                </div>
                                <div style={{ display: "flex", width: '100%', flexDirection: "row", justifyContent: "space-between", gap: 10, marginTop: "10px", }}
                                >
                                    {(panelSizes.length > 1 && panelSizes[1] && panelSizes[1] > 10) ? <Button variant={
                                        selectedPanels.includes(3) ? "solid" : "outlined"
                                    } color={
                                        selectedPanels.includes(3) ? "primary" : "neutral"
                                    } sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px", justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                        handlePanelSelection(3);
                                    }}>3</Button> : (
                                        <Button variant="outlined" disabled sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px" }}></Button>
                                    )}
                                    {(panelSizes.length > 3 && panelSizes[3] && panelSizes[3] > 10) ? <Button variant={
                                        selectedPanels.includes(4) ? "solid" : "outlined"
                                    } color={
                                        selectedPanels.includes(4) ? "primary" : "neutral"
                                    } sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px", justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                                        handlePanelSelection(4);
                                    }}>4</Button> : (
                                        <Button variant="outlined" disabled sx={{ fontSize: "25px", display: 'flex', flex: 0.5, width: '100%', height: "80px" }}></Button>
                                    )}
                                </div>
                            </div>
                            <Divider orientation="vertical" />
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", flex: 0.5, height: "200px", gap: "10px", justifyContent: 'center' }}>
                                {selectedPanels.length > 0 ?
                                    selectedPanels.map((panel: any, index: any) => {
                                        return (<Button key={index} variant="soft" sx={{ fontSize: "25px", width: '100%', height: "40px" }}>{panel}</Button>)
                                    }) : <>
                                        <Typography level="body-sm">Click the buttons to arrange the order</Typography>
                                    </>
                                }
                                {/* <Button variant="outlined" sx={{ fontSize: "25px", display: 'flex', width: '100%', height: "40px", justifyContent: 'center', alignItems: 'center' }} onClick={() => { }}>1</Button>
                        <Button variant="outlined" sx={{ fontSize: "25px", display: 'flex', width: '100%', height: "40px", justifyContent: 'center', alignItems: 'center' }} onClick={() => { }}>2</Button>
                        <Button variant="outlined" sx={{ fontSize: "25px", display: 'flex', width: '100%', height: "40px", justifyContent: 'center', alignItems: 'center' }} onClick={() => { }}>3</Button>
                        <Button variant="outlined" sx={{ fontSize: "25px", display: 'flex', width: '100%', height: "40px", justifyContent: 'center', alignItems: 'center' }} onClick={() => { }}>4</Button> */}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "130px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "30px", width: "300px", gap: "10px", alignItems: 'center' }}>
                            <Button startDecorator={<Publish />} sx={{ display: 'flex', width: "100%", flex: 0.5 }} onClick={() => setExportMode(1)}>Export</Button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <DialogContent>Select the export destination</DialogContent>
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <div style={{ width: "100%", height: "300px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10px", width: "500px", gap: "10px", alignItems: 'center' }}>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px" }}>
                                <Button variant={
                                    selectedTool === 0 ? "solid" : "outlined"
                                } onClick={() => { setSelectedTool(0) }}>Markdown</Button>
                                {/* <Button variant={
                                    selectedTool === 1 ? "solid" : "outlined"
                                } onClick={() => { setSelectedTool(1) }}>PDF</Button> */}
                                <Button variant={
                                    selectedTool === 1 ? "solid" : "outlined"} onClick={() => { setSelectedTool(1) }}>HTML</Button>
                            </div>
                        </div>
                        {selectedTool === 0 || selectedTool === 1 ? (
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: "50px", width: "200px", gap: "10px" }}>
                                <IconButton disabled={copyBtnDisabled} variant="solid" color='primary' onClick={exportCopyDataMarkdown} sx={{ width: "50px" }}><ContentCopy /></IconButton>
                                <Button disabled={disableDownloadBtn} startDecorator={<Download />} sx={{ flex: 1 }} onClick={exportFileSaveMarkdown}>Download</Button>
                            </div>
                        ) : null}
                        {/* {selectedTool === 1 ? (
                            <Button disabled={disableDownloadBtn} startDecorator={<Download />} sx={{ marginTop: "50px", width: "200px" }} onClick={startPdf}>Download</Button>
                        ) : null} */}
                        {/* <Button startDecorator={<FindInPage />} sx={{ marginTop: "10px", width: "200px" }} onClick={() => { }}>Preview</Button> */}
                        <Button sx={{ marginTop: "10px", width: "200px" }} onClick={() => setExportMode(0)}>Back</Button>
                    </div>
                </>
            )}


        </ModalDialog>
    </Modal >);
}

export default FileExporterPanel;