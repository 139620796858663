import { createContext } from "react";
import { create, all, ConfigOptions } from 'mathjs'

export const LibsContext = createContext<any>(undefined);

function LibsProvider({ children }: { children: React.ReactNode }) {


    // create a mathjs instance with configuration
    const config: ConfigOptions = {
        relTol: 1e-12,
        absTol: 1e-15,
        matrix: 'Matrix' as 'Matrix',
        number: 'number',
        precision: 64,
        predictable: false,
        randomSeed: null
    }
    const math = create(all, config)



    return (
        <LibsContext.Provider value={{
            math
        }}>
            {children}
        </LibsContext.Provider>
    );
}

export default LibsProvider;