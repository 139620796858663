/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */


import { createElement } from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { BaseLayout, MainLayout, MainLayoutWithSimpleToolbar, RootError, SubWithLayout } from "../components/Navigation";
import { Mainspace } from "./mainspace";
import { Explorer } from "./explorer";
import { Login } from "./login";
import { RedirectUrl } from "../components/Navigation/redirect";
import Settings from "./settings";

/**
 * Application routes
 * https://reactrouter.com/en/main/routers/create-browser-router
 */
export const router = createBrowserRouter([
    {
        path: "",
        element: <BaseLayout />,
        errorElement: <RootError />,
        children: [
            { path: "login", element: <Login /> },
            { path: "help", element: <RedirectUrl url="https://thinkposthelp.xadlabs.com" /> },
            { path: "about", element: <RedirectUrl url="https://www.xadlabs.com/notes/introducing-thinkpost" /> }
        ],
    },
    {
        path: "",
        element: <SubWithLayout />,
        errorElement: <RootError />,
        children: [
            { path: "/explorer", element: <Explorer /> },
        ],
    },
    {
        path: "",
        element: <MainLayoutWithSimpleToolbar title="" />,
        errorElement: <RootError />,
        children: [
            { path: "/settings", element: <Settings /> },
        ],
    },
    {
        path: "",
        element: <MainLayout />,
        errorElement: <RootError />,
        children: [
            { index: true, element: <Navigate to="/login" replace /> },
            { path: "/:wid/:tid", element: <Mainspace /> },
        ],
    },
]);

export function Router(): JSX.Element {

    window.addEventListener('vite:preloadError', (_event: any) => {
        // window.reload()
        window.location.reload()
    })


    return createElement(RouterProvider, { router });
}
