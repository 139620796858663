/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import { Button, ButtonProps } from "@mui/joy";
import { PersonOutline, Google, GitHub } from "@mui/icons-material";
export type SignInMethod = "google.com" | "anonymous" | "github.com";

export function LoginButton(props: LoginButtonProps): JSX.Element {
    const { signInMethod, ...other } = props;

    const icon =
        signInMethod === "google.com" ? (
            <Google />
        ) : signInMethod === "github.com" ? (
            <GitHub />
        ) : signInMethod === "anonymous" ? (
            <PersonOutline />
        ) : null;

    return (
        <Button
            startDecorator={icon}
            variant="outlined"
            onClick={() => { }}
            loading={false}
            children={
                signInMethod === "google.com"
                    ? "Continue via Google"
                    : signInMethod === 'github.com' ? "Continue via Github" : signInMethod === "anonymous"
                        ? "Continue as anonymous"
                        : "unknown"
            }
            {...other}
        />
    );
}

export type LoginButtonProps = Omit<
    ButtonProps<
        "button",
        {
            signInMethod: SignInMethod;
        }
    >,
    "children"
>;