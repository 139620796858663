/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import { Box, useColorScheme, } from "@mui/joy";
import { Suspense } from "react";
import { ActionButtons, ToolbarProps } from "./toolbar";
import TPLogo from '../../assets/tp_long_logo.png';

export function SimpleToolbar(props: ToolbarProps): JSX.Element {
    const { sx, title, ...other } = props;
    const { mode, systemMode } = useColorScheme();
    return (
        <Box
            sx={{
                zIndex: 1000,
                backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : 'black',
                alignItems: "center",
                borderBottom: "1px solid",
                borderColor: "divider",
                display: "flex",
                gap: 1,
                px: 2,
                ...sx,
            }}
            component="header"
            {...other}
        >
            <img src={TPLogo} alt="logo" style={{ height: "20px", width: "90px" }} />
            <div style={{ height: "30px", width: "100%", position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', left: "0px", zIndex: 98 }}>
                <b style={{ color: "#32383E" }}>{title}</b>
            </div>
            <Box sx={{ flexGrow: 1 }} component="span" />

            <Suspense>
                <ActionButtons resetStuff={() => { }} />
            </Suspense>
        </Box>
    );
}
