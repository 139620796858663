import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { setAllEditorsData, setUnsavedChanges } from "../../../state/allEditorsData/allEditorDataSlice"
import ReactCodeMirror, { EditorState, EditorView } from "@uiw/react-codemirror";
import { DialogContent, DialogTitle, Modal, ModalDialog, Option, Select, Stack, useColorScheme } from "@mui/joy";
import { dracula } from '@uiw/codemirror-theme-dracula';
import { whiteLight } from '@uiw/codemirror-theme-white';
import { monokai } from '@uiw/codemirror-theme-monokai';
import { material } from '@uiw/codemirror-theme-material';
import { tokyoNight } from '@uiw/codemirror-theme-tokyo-night';
import { bbedit } from '@uiw/codemirror-theme-bbedit';
import { githubDark, githubLight } from '@uiw/codemirror-theme-github';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import { ifArrayOrObjectOrString } from "../../../utilities/common";
import customKeymap from "../CodeEditor/helper";
import isEqual from "lodash.isequal";
import { useParams } from "react-router-dom";

const MCodeEditor = forwardRef(({ id, data, readonly, langu, change, showCustom, onEdChange }: { id: string, data: any, readonly: boolean, langu?: string, change?: any, showCustom?: boolean, onEdChange?: any }, ref: ForwardedRef<any>) => {

    useImperativeHandle(ref, () => ({
        resetCodes: () => {
            // console.log("Resetting code editor");
            setValue("");
            setUpdatedCode("");
        },
    }));

    const tid = useParams<{ tid: string }>().tid || "";
    const { mode, systemMode } = useColorScheme();
    const [value, setValue] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const [updatedCode, setUpdatedCode] = useState("");
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const [lang, setLang] = useState<any>("javascript");
    const [codeStyle, setCodeStyle] = useState<any>({});
    const [open, setOpen] = useState(false);


    // console.log('readonly', readonly)

    useEffect(() => {
        if (data && !allEditorsData.isSpaceIsOwner) {
            setValue(data);
            setUpdatedCode(data);
        }
    }, [data, allEditorsData.isSpaceIsOwner])

    useEffect(() => {
        // console.log("Show custom", showCustom);
        setOpen(showCustom || false);
    }, [showCustom]);

    useEffect(() => {
        if (allEditorsData.codeEditorStyle) {
            setCodeStyle(allEditorsData.codeEditorStyle);
        }
    }, [allEditorsData.codeEditorStyle]);

    useEffect(() => {
        if (langu) {
            setLang(langu);
        }
    }, [langu])

    useEffect(() => {
        if (data) {
            const clData = typeof data === "object" ? "" : data;
            setValue(clData);
            setUpdatedCode(clData);
        }
    }, [data]);


    const debouncedSave = debounce(() => {
        if (!allEditorsData.isSpaceIsOwner) return;

        // console.log("Updated code", updatedCodeCloned);
        const blockData = allEditorsData.dataBlocks.find((editor) => editor.id === id)?.data;


        if (isEqual(updatedCode, blockData)) return;
        if (updatedCode === "" && (ifArrayOrObjectOrString(blockData) === 'array' && blockData.length === 0)) return;
        const updatedCodeCloned = JSON.parse(JSON.stringify(updatedCode));
        dispatch(setAllEditorsData({ id, data: updatedCodeCloned, mode: "code", tabId: tid }));
        dispatch(setUnsavedChanges(true));
    }, 1000);

    useEffect(() => {
        // console.log('updatedCode', updatedCode);
        debouncedSave();
        return () => {
            debouncedSave.cancel();
        };
    }, [updatedCode]);

    const onChange = useCallback((rvalue: any) => {
        if (allEditorsData.isSpaceIsOwner) {
            setValue(rvalue);
            setUpdatedCode(rvalue);
            onEdChange && onEdChange(rvalue);
        }
    }, [allEditorsData.isSpaceIsOwner]);

    const getStyle = (style: string) => {
        switch (style) {
            case "default":
                return whiteLight;
            case "dracula":
                return dracula;
            case "monokai":
                return monokai;
            case "material":
                return material;
            case "tokyonight":
                return tokyoNight;
            case "bbedit":
                return bbedit;
            case "github":
                return mode === "light" || (mode === "system" && systemMode === "light") ? githubLight : githubDark;
            case "sublime":
                return sublime;
            default:
                return whiteLight;
        }
    }

    return (
        <>
            <Modal open={open} onClose={() => { setOpen(false) }}>
                <ModalDialog>
                    <DialogTitle>Select color schema</DialogTitle>
                    <DialogContent>It will affect all the code editors</DialogContent>
                    <Stack spacing={2}>
                        <Select sx={{ width: "200px", borderRadius: "0px", marginLeft: "30px" }} variant="plain" value={codeStyle} onChange={(_event: React.SyntheticEvent | null,
                            newValue: string | null,) => {
                            setCodeStyle(newValue || "default");
                            dispatch(setAllEditorsData({ codeEditorStyle: newValue || "default", tabId: tid }));
                        }}>
                            <Option value="default">Default</Option>
                            <Option value="dracula">Dracula</Option>
                            <Option value="monokai">Monokai</Option>
                            <Option value="material">Material</Option>
                            <Option value="tokyonight">Tokyo Night</Option>
                            <Option value="bbedit">BBEdit</Option>
                            <Option value="github">Web Editor</Option>
                            <Option value="sublime">Desktop Editor</Option>
                        </Select>
                    </Stack>
                </ModalDialog>
            </Modal>
            <div style={{ width: '100%', height: '100%', overflowY: 'scroll' }} >
                <div style={{ display: 'flex', width: '100%', height: '50px', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', width: "100%", flexDirection: 'row', height: "30px" }} >
                        <Select disabled={readonly} sx={{ width: "200px", borderRadius: "0px", marginLeft: "30px" }} variant="plain" value={lang} onChange={(_event: React.SyntheticEvent | null,
                            newValue: string | null,) => {
                            setLang(newValue || "javascript");
                            change(newValue || "javascript", codeStyle);
                        }}>
                            <Option value="javascript">Javascript</Option>
                            <Option value="jsx">JSX</Option>
                            <Option value="typescript">Typescript</Option>
                            <Option value="tsx">TSX</Option>
                            <Option value="json">JSON</Option>
                            <Option value="html">HTML</Option>
                            <Option value="css">CSS</Option>
                            <Option value="python">Python</Option>
                            <Option value="markdown">Markdown</Option>
                            <Option value="xml">XML</Option>
                            <Option value="sql">SQL</Option>
                            <Option value="mysql">MySQL</Option>
                            <Option value="pgsql">PostgreSQL</Option>
                            <Option value="java">Java</Option>
                            <Option value="rust">Rust</Option>
                            <Option value="cpp">C++</Option>
                            <Option value="php">PHP</Option>
                            <Option value="ruby">Ruby</Option>
                            <Option value="shell">Shell</Option>
                            <Option value="go">Go</Option>
                            <Option value="csharp">C#</Option>
                            <Option value="swift">Swift</Option>
                            <Option value="kotlin">Kotlin</Option>
                            <Option value="dart">Dart</Option>
                            <Option value="yaml">YAML</Option>
                            <Option value="toml">TOML</Option>
                            <Option value="plaintext">Plain Text</Option>

                        </Select>
                    </div>
                </div>
                <ReactCodeMirror id={id + "codeeditor"} readOnly={readonly} style={{ backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? "#ffffff" : "#282a36", paddingTop: "0px", height: 'calc(100%-100px)' }} theme={codeStyle === 'default' ? (mode === "light" || (mode === "system" && systemMode === "light") ? whiteLight : dracula) : getStyle(codeStyle)} value={value} extensions={[EditorView.lineWrapping, loadLanguage(lang),
                EditorState.readOnly.of(readonly),
                readonly ? customKeymap : null,
                ].filter(Boolean) as any} onChange={onChange} />
            </div >
        </>);
})

export default MCodeEditor;