import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from "react";
import EditorJS from "zmeditorjsnew";
import Header from '@editorjs/header';
import Checklist from 'zmeditorjslib-checklist';
import DragDrop from 'zmeditorjslib-drag-drop';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { setAllEditorsData, setUnsavedChanges } from "../../../state/allEditorsData/allEditorDataSlice"
import Underline from '@editorjs/underline';
import Strikethrough from '@sotaproject/strikethrough';
import CodeTool from '@editorjs/code';
import YoutubeEmbed from 'editorjs-youtube-embed';
import Table from '@editorjs/table'
import Alerts from "editorjs-alert";
import LinkTool from 'zmeditorjslib-link';
import Image from "zmeditorjslib-imagenew";
import Attaches from "zmeditorjslib-attaches";
import Undo from 'editorjs-undo';
import ReadonlyCssLoader from "../Editor/ReadonlyCssLoader";
import Paragraph from '@editorjs/paragraph';
import ajax from "@codexteam/ajax";
import { AuthContext } from "../../../core/AuthProvider";
import InlineCode from "@editorjs/inline-code";
import TextColor from "zmeditorjslib-text-color-plugin";
import isEqual from "lodash.isequal";
import omit from "lodash.omit";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { useParams } from "react-router-dom";
import PlainPaste from "zmeditorjslib-plain-paste";
import NestedList from "zmeditorjslib-nested-list";
import { ListConvertTune } from "zmeditorjslib-list-convert";
// import EmojiPicker from 'zmeditorjslib-emoji-picker';

const MEditorComponent = forwardRef(({ id, style, data, loading, readonly, onEdChange }: { id: any, style: any, data?: any, loading?: boolean, readonly?: boolean, onEdChange?: any }, ref: React.Ref<any>) => {
    const dispatch = useDispatch<AppDispatch>();
    const initBlockDone = useRef(false);
    const apiUrl = import.meta.env.VITE_ENDPOINT_URL_BASE;
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const ejInstance: any = useRef();
    const envRunning = import.meta.env.PROD;
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";
    // console.log('envRunning', envRunning, idToken);

    useImperativeHandle(ref, () => {
        return {
            reset() {
                ejInstance?.current?.clear();
            }
        }
    });

    useEffect(() => {
        let unsubscribe: any;
        if (!allEditorsData.isSpaceIsOwner) {
            const panelRef = query(collection(db, "spaces", wid, "tabs", tid, "/panels"));
            unsubscribe = onSnapshot(panelRef, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const docdata = doc.data();
                    if (doc.id === "hpanel" + id && doc.data().mode === 'edit') {
                        // console.log('panelRef', JSON.parse(docdata.data));
                        const jsonParsed = JSON.parse(docdata.data);
                        if (Object.keys(jsonParsed).length > 0) {
                            ejInstance.current?.render(JSON.parse(docdata.data));
                        }
                    }
                });
            });
        }
        return () => {
            unsubscribe && unsubscribe();
        }
    }, []);

    const initEditor = (editorData: any) => {
        const editor = new EditorJS({
            placeholder: allEditorsData.isSpaceIsOwner ? "Start here, Type / to see all the options" : "",
            holder: 'editorjs' + id,
            minHeight: 0,
            readOnly: readonly,
            logLevel: "ERROR" as any,
            onReady: () => {
                ejInstance.current = editor;
                if (editor) {
                    new DragDrop(editor, {
                        onDragEnd: () => {
                            if (ejInstance?.current) {
                                if (ejInstance.current.blocks.getBlocksCount() === 1) {
                                    ejInstance.current.blocks.insert("myparagraph", [], {}, ejInstance.current.blocks.getBlocksCount(), false);
                                }
                            }
                        }

                    });
                    new Undo({ editor });
                    new PlainPaste(editor);
                    // new EmojiPicker(editor);
                }
                // const currentPanelData = allEditorsData.dataBlocks.find((panel: any) => panel.id === "hpanel" + id);
                if (ejInstance.current.blocks.getBlocksCount() === 1) {
                    ejInstance.current.blocks.insert("myparagraph", [], {}, ejInstance.current.blocks.getBlocksCount(), false);
                }
            },

            data: editorData || {},
            onChange: async () => {
                if (allEditorsData.isSpaceIsOwner && ejInstance.current) {
                    const datam = await editor?.saver?.save();
                    if (!datam) return;

                    let content = JSON.parse(JSON.stringify(datam));
                    if (!content) return;

                    const currentPanelData = allEditorsData.dataBlocks.find((panel: any) => panel.id === "hpanel" + id);
                    // const sanitizedContents = content.blocks.filter((block: any) => block.data.text.trim() !== "");

                    // console.log('initBlockDone', initBlockDone);

                    if (initBlockDone.current) {
                        const newData = { id: "hpanel" + id, data: content, mode: "edit" };

                        const newcurrentPanelData = JSON.parse(JSON.stringify(currentPanelData));
                        newcurrentPanelData.mode = "edit";

                        if (newData.data?.blocks?.length === 1 && newData.data?.blocks[0]?.type === "myparagraph" && newData.data?.blocks[0]?.data?.text.trim() === "") {
                            return;
                        }

                        // console.log('newData', newData.data?.blocks, 'currentPanelData', newcurrentPanelData.data?.blocks, 'isequal', isEqual(omit(newcurrentPanelData.data?.blocks, 'id'), omit(newData.data?.blocks, 'id')));
                        if (currentPanelData && !isEqual(omit(newcurrentPanelData.data?.blocks, 'id'), omit(newData.data?.blocks, 'id'))) {

                            onEdChange && onEdChange(content);
                            dispatch(setAllEditorsData({ id: "hpanel" + id, data: content, mode: "edit", tabId: tid }));
                            dispatch(setUnsavedChanges(true));
                        }
                    }

                    if (!initBlockDone.current) {
                        if (content && content.blocks.length >= ejInstance.current.blocks.getBlocksCount()) {
                            ejInstance.current.blocks.insert("myparagraph", [], {}, ejInstance.current.blocks.getBlocksCount(), false);
                            // console.log("Init block done");
                        }
                        initBlockDone.current = true;
                        // console.log('initBlockDone');
                    }
                }
            },
            defaultBlock: 'myparagraph',
            tools: {
                // markdownParser: MDParser,
                // markdownImporter: MDImporter,
                header: Header,
                NestedList,
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                // Marker: {
                //     class: Marker,
                //     shortcut: 'CMD+SHIFT+M',
                // },
                underline: Underline,
                strikethrough: Strikethrough,
                code: CodeTool,
                youtube: YoutubeEmbed,
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
                alert: Alerts,
                linkTool: {
                    class: LinkTool,
                    config: {
                        beforeCall: async () => {
                            let idTokenNew = "";
                            if (envRunning) {
                                idTokenNew = await user.getIdToken();
                                localStorage.setItem('userIdno', idTokenNew as string);
                            }

                            return envRunning && idTokenNew && {
                                "Content-Type": "application/json",
                                Authorization: idTokenNew
                            }
                        },
                        endpoint: apiUrl + "fetchurl",

                    }
                },
                image: {
                    class: Image as any,
                    config: {
                        // endpoints: {
                        //     byFile: apiUrl + 'image', // Your backend file uploader endpoint
                        //     byUrl: apiUrl + 'file', // Your endpoint that provides uploading by Url
                        // },
                        buttonContent: "Select an image (5MB max)",
                        uploader: {
                            async uploadByFile(file: any) {
                                const formData = new FormData();
                                formData.append('file', file);
                                // your own uploading logic here

                                let idTokenNew = "";
                                if (envRunning) {
                                    idTokenNew = await user.getIdToken();
                                    localStorage.setItem('userIdno', idTokenNew as string);
                                }


                                return ajax.post({
                                    url: apiUrl + 'image',
                                    data: formData,
                                    type: ajax.contentType.JSON,
                                    headers: envRunning && idTokenNew && {
                                        Authorization: idTokenNew
                                    },
                                }).then((response: any) => {
                                    // console.log("uploaded success file", response)
                                    return {
                                        success: 1,
                                        file: {
                                            url: response.body.file.url,
                                            // any other image data you want to store, such as width, height, color, extension, etc
                                        },
                                        user
                                    };
                                });
                            },

                            /**
                             * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
                             * @param {string} url - pasted image URL
                             * @return {Promise.<{success, file: {url}}>}
                             */
                            async uploadByUrl(url: any) {
                                // your ajax request for uploading

                                let idTokenNew = "";
                                if (envRunning) {
                                    idTokenNew = await user.getIdToken();
                                    localStorage.setItem('userIdno', idTokenNew as string);
                                }

                                return ajax.post({
                                    url: apiUrl + 'file',
                                    data: { url },
                                    type: ajax.contentType.JSON,
                                    headers: envRunning && {
                                        Authorization: idTokenNew
                                    },
                                }).then((response: any) => {
                                    // console.log("uploaded success url")

                                    return {
                                        success: 1,
                                        file: {
                                            url: response.body.file.url,
                                            // any other image data you want to store, such as width, height, color, extension, etc
                                        },
                                        user
                                    }
                                })
                            },
                        },
                        // additionalRequestHeaders: envRunning && {
                        //     Authorization: idToken
                        // }
                    },
                    inlineToolbar: true,
                },
                attaches: {
                    class: Attaches as any,
                    config: {
                        // endpoint: apiUrl + 'upload-file-post',
                        // additionalRequestHeaders: envRunning && {
                        //     Authorization: sessionStorage.getItem('userIdno')
                        // }
                        buttonText: "Select a file (5MB max)",
                        uploader: {
                            async uploadByFile(file: any) {
                                const formData = new FormData();
                                formData.append('file', file);
                                // your own uploading logic here

                                let idTokenNew = "";
                                if (envRunning) {
                                    idTokenNew = await user.getIdToken();
                                    localStorage.setItem('userIdno', idTokenNew as string);
                                }


                                return ajax.post({
                                    url: apiUrl + 'upload-file-post',
                                    data: formData,
                                    type: ajax.contentType.JSON,
                                    headers: envRunning && idTokenNew && {
                                        Authorization: idTokenNew
                                    },
                                }).then((response: any) => {
                                    // console.log("uploaded success file", response)
                                    return {
                                        success: 1,
                                        file: {
                                            url: response.body.file.url,
                                            name: response.body.file.name,
                                            size: response.body.file.size,
                                            title: response.body.file.title,
                                            extension: response.body.file.extension,
                                            // any other image data you want to store, such as width, height, color, extension, etc
                                        },
                                        user
                                    };
                                });
                            },
                        }
                    }
                },
                myparagraph: {
                    class: Paragraph,
                    inlineToolbar: true,
                    config: {
                        preserveBlank: true,
                    },
                } as any,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+M',
                },
                Marker: {
                    class: TextColor, // if load from CDN, please try: window.ColorPlugin
                    config: {
                        defaultColor: "#fffbcc",
                        colorCollections: ['#fffbcc', '#E0F7E0', '#E5E5FF', '#D4E6F4', '#F2C59F', '#CDDC39'],
                        type: 'marker',
                        //  icon: `<svg fill="#000000" height="200px" width="200px" version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M17.6,6L6.9,16.7c-0.2,0.2-0.3,0.4-0.3,0.6L6,23.9c0,0.3,0.1,0.6,0.3,0.8C6.5,24.9,6.7,25,7,25c0,0,0.1,0,0.1,0l6.6-0.6 c0.2,0,0.5-0.1,0.6-0.3L25,13.4L17.6,6z"></path> <path d="M26.4,12l1.4-1.4c1.2-1.2,1.1-3.1-0.1-4.3l-3-3c-0.6-0.6-1.3-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9L19,4.6L26.4,12z"></path> </g> <g> <path d="M28,29H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h24c0.6,0,1,0.4,1,1S28.6,29,28,29z"></path> </g> </g></svg>`,
                    }
                },
                listConvert: ListConvertTune
            },
        });
    };

    // This will run only once
    useEffect(() => {
        // console.log('EditorComponent', id, data, loading);
        if (!ejInstance.current && id && (data !== null && data !== undefined) && !loading) {
            // console.log('initEditor', data);
            initEditor(data);
        }
        return () => {
            ejInstance?.current?.destroy();
            ejInstance.current = null;
        };
    }, [id, data, loading,]);

    return <>
        <ReadonlyCssLoader />
        <div style={style} id={'editorjs' + id}></div ></>;
});

export default MEditorComponent;