import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import { keyGen } from "../../utilities/common";


export async function createNewSpace(
    user: any,
    name?: string,
    setStorage: boolean = true
): Promise<any> {
    const userId = user?.uid;
    const wid = keyGen(12);
    const tid = "1";

    const batch = writeBatch(db);
    //create a new workspace in firebase

    const wname = name?.trim() ? name : "New Space";
    // console.log("Creating new space", wid, wid, tid);
    batch.set(doc(db, "spaces", wid), {
        id: wid,
        createdby: userId,
    });

    batch.set(doc(db, "spaces", wid, "tabs", tid), {
        id: tid,
        no: 1,
        title: "Tab 1",
        public: false,
        createdby: userId,
    });

    batch.set(doc(db, "data", userId), {
        id: userId,
    });



    batch.set(doc(db, "data", userId, "spaces", wid), {
        userId, wid,
        title: wname,
        created: new Date().getTime(),
        createdby: userId,
        modified: new Date().getTime(),
    });

    const ids = ["vpanel_0_0", "vpanel_1_0",
        // "vpanel_2_0", 
        "hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1",
        // "hpanel_2_0", "hpanel_2_1"
    ];
    const sizes = [50, 49,
        // 0.1, 
        99, 0.1, 99, 0.1,
        // 99, 0.1
    ];
    for (let i = 0; i < ids.length; i++) {
        const panelId = ids[i];
        batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
            id: panelId,
            size: sizes[i],
            data: "{}",
            mode: "edit"
        });
    }

    await batch.commit();
    //create new workspace in firestore

    if (setStorage) {
        localStorage.setItem("userId", userId);
        sessionStorage.setItem("wid", wid);
        sessionStorage.setItem("tid", tid);
    }
    return { wid, tid };
}