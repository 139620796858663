/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */


import { Box, BoxProps, Button, Divider, Dropdown, IconButton, Menu, MenuButton, MenuItem, Modal, ModalClose, Sheet, Typography, useColorScheme } from "@mui/joy";
import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import { ColorSchemeButton } from "./button-color-scheme";
import { AccountCircle, ExpandCircleDown, SaveOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../core/AuthProvider";
import { db } from "../../firebase";
import { collection, doc, getDocs, writeBatch } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { setUnsavedChanges } from "../../state/allEditorsData/allEditorDataSlice"
import { setCurrentPanel } from "../../state/mobileNavData/mobileNavData";
import debounce from "lodash.debounce";
import TPLogo from '../../assets/mobile_logo.png';

export function MobileToolbar(props: ToolbarProps): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const mobileNavData = useSelector((state: RootState) => state.mobileNavData);
    const [currentWSpaceInfo, setCurrentWSpaceInfo] = useState<any>({});
    const [allWorkspaces, setAllWorkspaces] = useState<any[]>([]);
    const [isWorkspaceDataLoaded, setIsWorkspaceDataLoaded] = useState<boolean>(false);

    const { mode, systemMode } = useColorScheme();

    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";
    const [selectedTabId, setSelectedTabId] = useState<string>("");
    const [openPanelsModal, setOpenPanelsModal] = useState<boolean>(false);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [savingData, setSavingData] = useState<boolean>(false);
    const { user } = authContext;
    const [tabs, setTabs] = useState<any>([]);
    const { sx, hideAllOptions, ...other } = props;
    const [isTabsLoaded, setIsTabsLoaded] = useState<boolean>(false);


    const saveAllPanelData = async () => {
        if (!user || !user.uid) return;

        setSavingData(true);

        const batch = writeBatch(db);
        const ids = ["hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1",
            // "hpanel_2_0", "hpanel_2_1"
        ];
        // const editorIds = ["editor_0_0", "editor_0_1", "editor_1_0", "editor_1_1", "editor_2_0", "editor_2_1", ];

        // console.log({ allEditorsData })

        for (let i = 0; i < ids.length; i++) {
            const panelId = ids[i];
            const content = allEditorsData.dataBlocks.find((data: any) => data.id === panelId)?.data || [];

            // if (typeof content === "string" && Math.abs(content.length - contentFromBackup.length) > 1) { // code editor
            //     continue;
            // }
            // if (typeof content === "object") { //text editor if there are sudden deletions, stop it

            //     if (Object.keys(content).length === 0 && Object.keys(contentFromBackup).length > 1) {
            //         continue;
            //     }

            // }
            // if (Array.isArray(content)) { //draw and maths
            //     if (content.length === 0 && contentFromBackup.length > 1) {
            //         continue;
            //     }
            // }
            // if (content) {
            //     batch.set(doc(db, wid, wid, "tabs", tid, "panels", panelId), {
            //         data: content
            //     }, { merge: true });
            // }

            // console.log("Saving panel data", panelId, content);
            if (content) {
                batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
                    data: JSON.stringify(content)
                }, { merge: true });
            }


        }

        batch.set(doc(db, "spaces", wid, "tabs", tid), {
            modified: new Date().getTime()
        }, { merge: true });

        setCurrentWSpaceInfo({
            ...currentWSpaceInfo,
            modified: new Date().getTime()
        });

        const tabInfo = { id: tid, lastModified: new Date().getTime() };
        const allExistingTabInfo = JSON.parse(sessionStorage.getItem("allTabInfo") || "{}");
        allExistingTabInfo[tid] = tabInfo;
        sessionStorage.setItem("allTabInfo", JSON.stringify(allExistingTabInfo));

        batch.set(doc(db, "data", user?.uid, "spaces", wid), {
            modified: new Date().getTime()
        }, { merge: true });

        await batch.commit();
        dispatch(setUnsavedChanges(false));
        // dispatch(saveDataBlocksBackup(allEditorsData.dataBlocks));
        setSavingData(false);

    }

    const debouncedSaveAllPanelData = debounce(saveAllPanelData, 10000); // 10 seconds

    useEffect(() => {
        if (allEditorsData.unsavedChanges && allEditorsData.dataBlocks) {
            debouncedSaveAllPanelData();
        }
        return () => {
            debouncedSaveAllPanelData.cancel();
        };
    }, [allEditorsData.unsavedChanges, allEditorsData.dataBlocks]);

    useEffect(() => {
        if (wid && tid) {
            setSelectedTabId(tid);
        }
    }, [wid, tid]);

    useEffect(() => {
        if (tabs.length === 0 && allEditorsData.allTabs.length > 0 && !isTabsLoaded) {
            // console.log("Setting tabs", allEditorsData.allTabs)
            setTabs(allEditorsData.allTabs);
            setIsTabsLoaded(true);
        }
    }, [allEditorsData.allTabs, isTabsLoaded])


    const setTitleSharedFromCall = async () => {
        // console.log("Fetching tabs for ", allEditorsData.currentWorkspaceSmallInfo.createdby);
        const querySnapshot = await getDocs(collection(db, "data", allEditorsData.currentWorkspaceSmallInfo.createdby, "spaces"));
        // console.log("querySnapshot", querySnapshot.docs)
        const wspaces: any[] = [];
        querySnapshot.forEach((doc: any) => {
            // console.log("Doc", doc.data());
            wspaces.push(
                doc.data()
            );

            if (doc.data().wid === wid) {
                document.title = doc.data().title;
                setCurrentWSpaceInfo({
                    ...doc.data(),
                    inputMode: 0
                });
            }
        });
        // console.log("All workspaces", wspaces)
        setAllWorkspaces(wspaces);
        setIsWorkspaceDataLoaded(true);
        const allspacesLocal = sessionStorage.getItem("allspaces");
        if (allspacesLocal) {
            const allspaces = JSON.parse(allspacesLocal);
            wspaces.forEach((space: any) => {
                const currentSpace = allspaces.find((space: any) => space.id === space.id);
                const mergedData = { ...currentSpace, ...space };
                allspaces[allspaces.indexOf(currentSpace)] = mergedData;
                sessionStorage.setItem("allspaces", JSON.stringify(allspaces));
            });
        }
    };

    useEffect(() => {
        if (wid && !isWorkspaceDataLoaded && allEditorsData.currentWorkspaceSmallInfo["createdby"]) {
            setTitleSharedFromCall();
        }
    }, [wid, user, allWorkspaces, allEditorsData.currentWorkspaceSmallInfo]);

    const getPanelHumanNames = (panelId: string) => {
        switch (panelId) {
            case "hpanel_0_0":
                return "First Panel Up";
            case "hpanel_0_1":
                return "First Panel Down";
            case "hpanel_1_0":
                return "Second Panel Up";
            case "hpanel_1_1":
                return "Second Panel Down";
            // case "hpanel_2_0":
            //     return "Third Panel Up";
            // case "hpanel_2_1":
            //     return "Third Panel Down";
            default:
                return "Panel";
        }
    }


    return (
        <>
            <Modal
                aria-labelledby="close-modal-title"
                open={openPanelsModal}
                onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpenPanelsModal(false);
                }}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 300,
                        minHeight: '250px',
                        borderRadius: 'md',
                        p: 3,
                    }}
                >
                    <ModalClose variant="outlined" />
                    <Typography
                        component="h2"
                        id="close-modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                    >
                        Select the panel
                    </Typography>
                    <div style={{
                        display: 'flex', flexDirection: 'row', width: '300px', height: "200px", marginTop: "30px", alignItems: 'center', justifyContent: 'center',
                    }}>
                        < div style={{ display: 'flex', flexDirection: 'column', flex: 0.3, gap: 5 }}>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_0_0"));
                                setOpenPanelsModal(false);
                            }}>First Panel Up</Button>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_0_1"));
                                setOpenPanelsModal(false);
                            }}>First Panel Down</Button>
                        </div>
                        <div style={{ display: 'flex', flex: 0.02, flexDirection: 'column' }} />
                        <div style={{ display: 'flex', flex: 0.3, flexDirection: 'column', gap: 5 }}>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_1_0"));
                                setOpenPanelsModal(false);
                            }}>Second Panel Up</Button>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_1_1"));
                                setOpenPanelsModal(false);
                            }}>Second Panel Down</Button>
                        </div>
                        {/* <div style={{ display: 'flex', flex: 0.02, flexDirection: 'column' }} /> */}
                        {/* <div style={{ display: 'flex', flex: 0.3, flexDirection: 'column', gap: 5 }}>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_2_0"));
                                setOpenPanelsModal(false);
                            }}>Third Panel Up</Button>
                            <Button variant="soft" style={{ borderRadius: "0px" }} onClick={() => {
                                dispatch(setCurrentPanel("hpanel_2_1"));
                                setOpenPanelsModal(false); dispatch(setCurrentPanel("hpanel_0_0"));
                            }}>Third Panel Down</Button>
                        </div> */}
                    </div>
                </Sheet >
            </Modal >
            <Box
                sx={{
                    alignItems: 'center',
                    justifyContent: "flex-start",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    height: "50px",
                    gap: 1,
                    px: 2,
                    ...sx,
                }}
                component="header"
                {...other}
            >
                <div style={{ display: 'flex', flexDirection: 'row', width: "50px", alignItems: 'center', justifyContent: 'center', }}>
                    <img src={TPLogo} alt="logo" style={{ height: "30px", width: "30px" }} />
                </div>
                <Box sx={{ display: "flex", flexGrow: 1, alignSelf: 'center', justifyContent: 'center' }} component="span" >
                    {(user && user?.uid && tabs.length > 0) || !hideAllOptions ? (
                        <>
                            <Dropdown>
                                <MenuButton
                                    disabled={allEditorsData.loadingEditorData}
                                    slots={{ root: IconButton }}
                                    slotProps={{ root: { variant: 'plain', color: 'neutral', sx: { width: "100px" } } }}
                                >
                                    {tabs.find((tab: any) => tab.id === selectedTabId)?.name}
                                    <ExpandCircleDown sx={{ marginLeft: "5px" }} />
                                </MenuButton>
                                <Menu>
                                    {
                                        tabs.map((tab: any, index: any) =>
                                        (
                                            <MenuItem key={index} variant={tab.id === selectedTabId ? 'soft' : 'plain'} onClick={() => {
                                                if (tid !== tab.id) {
                                                    if (allEditorsData.unsavedChanges) {
                                                        const confirmNavigation = window.confirm("You have unsaved changes. Are you sure you want to navigate?");
                                                        if (!confirmNavigation) {
                                                            return;
                                                        }
                                                    }
                                                    navigate('/' + wid + "/" + tab.id);
                                                }
                                            }}>{tab.name}</MenuItem>
                                        ))
                                    }
                                    {/* <MenuItem disabled={allEditorsData.loadingEditorData} onClick={newTab}>Create a new Tab</MenuItem> */}
                                </Menu>
                            </Dropdown>
                            {allEditorsData.isSpaceIsOwner && (
                                <>
                                    <IconButton disabled={savingData || allEditorsData.loadingEditorData} onClick={saveAllPanelData} variant="plain" style={{ marginLeft: "80px", borderRadius: "0px" }}>
                                        <SaveOutlined />
                                        {allEditorsData.unsavedChanges ? (<div style={{ position: "absolute", top: "0px", height: "8px", width: "8px", backgroundColor: 'maroon', marginRight: "-15px", marginTop: "5px", borderRadius: "4px" }} />) : null}
                                    </IconButton>
                                    <Divider orientation="vertical"></Divider>
                                </>
                            )}

                            {/* <IconButton disabled={savingData || allEditorsData.loadingEditorData} onClick={deleteTab} variant="plain" style={{ borderRadius: "0px" }}>
                                <DeleteOutline />
                            </IconButton> */}
                        </>) : null}
                </Box>
                <Suspense>
                    <ActionButtons />
                </Suspense>
            </Box >
            <Box
                sx={{
                    alignItems: "center",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    height: "50px",
                    justifyContent: 'center',
                    gap: 1,
                    px: 2,
                    marginTop: "50px",
                    gridArea: "2 / 2 / 2 / -1",
                }}
            >
                <Button variant="soft" onClick={() => setOpenPanelsModal(true)}>{"Selected Panel - " + getPanelHumanNames(mobileNavData.currentPanel)}</Button>
                {/* <Dropdown>
                    <MenuButton
                        disabled={allEditorsData.loadingEditorData}
                        slots={{ root: Button }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                    >
                        <Typography></Typography>
                    </MenuButton>
                    <Menu>
                        {
                            allPanels.map((panel: any, index: any) =>
                            (
                                <MenuItem key={index} variant={panel.id === mobileNavData.currentPanel ? 'soft' : 'plain'} onClick={() => {
                                    dispatch(setCurrentPanel(panel.id));
                                }}>{panel.name}</MenuItem>
                            ))
                        }
                    </Menu>
                </Dropdown> */}
            </Box >
            <Box
                sx={{
                    alignItems: "center",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    height: "50px",
                    gap: 1,
                    px: 2,
                    gridArea: "2 / 2 / 2 / -1",
                    justifyContent: 'center'
                }}
            > <Typography sx={{ marginLeft: "5px" }} variant="plain"><b style={{ color: mode === "light" || (mode === "system" && systemMode === "light") ? "#32383E" : 'white', fontSize: "15px" }}>{currentWSpaceInfo?.title ? currentWSpaceInfo?.title : ""}{
                !allEditorsData.isSpaceIsOwner ? " (Read only)" : ""
            }</b></Typography>

            </Box >
        </>
    );
}

function ActionButtons(): JSX.Element {
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const authContext = useContext(AuthContext);
    const { user, initializing, signout } = authContext;
    const navigate = useNavigate();
    // const gotoExplorer = () => {
    //     navigate('/explorer');
    // }

    return (
        <Fragment>

            {/* {user && user.uid ? (<IconButton className="explorer-btn" onClick={gotoExplorer} variant="plain">
                <CreateNewFolder />
            </IconButton>) : null} */}

            <ColorSchemeButton variant="plain" size="sm" />
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                >
                    <AccountCircle />
                </MenuButton>
                <Menu>
                    {!initializing && user && user.uid ? (<MenuItem onClick={() => {
                        if (allEditorsData.unsavedChanges) {
                            const confirmNavigation = window.confirm("You have unsaved changes. Are you sure you want to logout?");
                            if (!confirmNavigation) {
                                return;
                            }
                        }
                        signout(() => {
                            navigate('/login');
                        })
                    }}>Sign Out</MenuItem>
                    ) : (
                        <MenuItem onClick={() => {
                            navigate('/login');
                        }}>Sign In</MenuItem>
                    )
                    }
                    {user && user.uid ? (<MenuItem>Settings</MenuItem>) : null}
                    <MenuItem onClick={() => navigate("/help")}>About</MenuItem>
                </Menu>
            </Dropdown>
            {/* )} */}
        </Fragment>
    );
}
type ToolbarProps = Omit<BoxProps<"header">, "children"> & {
    hideAllOptions?: boolean;
};