import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';

import { parseMarkdownToParagraph } from './ParagraphTypeParser';

export function parseHtmlToMarkdown(html: any) {
  const output = unified()
    .use(rehypeParse) // Parse HTML to a syntax tree
    .use(rehypeRemark) // Turn HTML syntax tree to markdown syntax tree
    .use(remarkStringify) // Serialize HTML syntax tree
    .processSync(html);

  return String(output);
}

export function parseListToMarkdown(blocks: any) {
  // console.log('blocks list', blocks);
  switch (blocks.style) {
    case 'unordered':
      const parseNestedItems = (items: any[], level: number): string => {
        return items.map((item: any) => {
          const prefix = '*';
          const spacedRepeatPrefix = ' '.repeat(level * 2);
          const content = parseHtmlToMarkdown(item.content);
          const nestedItems = item.items ? parseNestedItems(item.items, level + 1) : '';
          return `${spacedRepeatPrefix + " " + prefix} ${content}${nestedItems}`;
        }).join('');
      };

      return parseNestedItems(blocks.items, 1) + '\n';
    case 'ordered':
      const parseOrdNestedItems = (items: any[], level: number): string => {
        return items.map((item: any, index: number) => {
          const prefix = `${index + 1}.`.padStart(level * 2, ' ');
          const content = parseHtmlToMarkdown(item.content);
          const nestedItems = item.items ? parseNestedItems(item.items, level + 1) : '';
          return `${prefix} ${content}${nestedItems}`;
        }).join('');
      };

      return parseOrdNestedItems(blocks.items, 1) + '\n';
    default:
      break;
  }

  return '';
}

export function parseMarkdownToList(listBlock: any) {
  const itemData: any = [];
  listBlock.children.forEach((listItemBlock: any) => {
    listItemBlock.children.forEach((paragraphBlock: any) => {
      const paragraphJson = parseMarkdownToParagraph(paragraphBlock);
      // paragraphJson is a list at this state (of just one element in most cases)
      itemData.push(...paragraphJson.map((e: any) => e.data.text));
    });
  });

  return {
    data: {
      items: itemData,
      style: listBlock.ordered ? 'ordered' : 'unordered',
    },
    type: 'list',
  };
}
