import EditorComponent from "./MobileEditors/MEditor";
import React, { createRef, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { setLoadingEditorData, setCodeEditorStyle, replaceAllEditorsData } from "../../state/allEditorsData/allEditorDataSlice"
import ExcalidrawEditor from "./MobileEditors/MExcaliDraw";
import MathSheet from "./MobileEditors/MMathSheet";
import CodeEditor from "./MobileEditors/MCodeEditor";
import { AuthContext } from "../../core/AuthProvider";
import { useParams } from "react-router-dom";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import { getRightData } from "../../utilities/common";


export type PanelGroupStorage = {
    getItem(name: string): string | null;
    setItem(name: string, value: string): void;
};

interface PanelProps {
    panelData: any;
}

const MobilePanels: React.FC<PanelProps> = ({ panelData }: PanelProps) => {
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = React.useState<boolean>(true);

    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";

    const [workingPanelData, setWorkingPanelData] = React.useState<any>({});
    const mobileNavData = useSelector((state: RootState) => state.mobileNavData);

    const authContext = useContext(AuthContext);
    const { user } = authContext;


    const [panelHPanel00, setPanelHPanel00] = React.useState<any>({ id: "hpanel_0_0", size: 99, data: {}, mode: "edit", type: {} });
    const [panelHPanel01, setPanelHPanel01] = React.useState<any>({ id: "hpanel_0_1", size: 0, data: {}, mode: '', type: {} });
    const [panelHPanel10, setPanelHPanel10] = React.useState<any>({ id: "hpanel_1_0", size: 99, data: {}, mode: "edit", type: {} });
    const [panelHPanel11, setPanelHPanel11] = React.useState<any>({ id: "hpanel_1_1", size: 0.1, data: {}, mode: '', type: {} });
    // const [panelHPanel20, setPanelHPanel20] = React.useState<any>({ id: "hpanel_2_0", size: 30, data: {}, mode: "edit" });
    // const [panelHPanel21, setPanelHPanel21] = React.useState<any>({ id: "hpanel_2_1", size: 0.1, data: {}, mode: '' });

    const drawHPanel00Ref = createRef<any>();
    const drawHPanel10Ref = createRef<any>();

    const codeHPanel00Ref = useRef<any>(null);
    const codeHPanel10Ref = useRef<any>(null);
    const codeHPanel01Ref = useRef<any>(null);
    const codeHPanel11Ref = useRef<any>(null);

    const mathHPanel00Ref = useRef<any>(null);
    const mathHPanel10Ref = useRef<any>(null);
    const mathHPanel01Ref = useRef<any>(null);
    const mathHPanel11Ref = useRef<any>(null);

    const editorHPanel00Ref = useRef<any>(null);
    const editorHPanel10Ref = useRef<any>(null);
    const editorHPanel01Ref = useRef<any>(null);
    const editorHPanel11Ref = useRef<any>(null);

    // const drawHPanel20Ref = createRef<any>();


    useEffect(() => {
        if (panelData.length === 0) return;

        // console.log("All panels data", panelData);

        const panelHPanel00Data = panelData.find((panel: any) => panel.id === "hpanel_0_0");
        const panelHPanel01Data = panelData.find((panel: any) => panel.id === "hpanel_0_1");
        const panelHPanel10Data = panelData.find((panel: any) => panel.id === "hpanel_1_0");
        const panelHPanel11Data = panelData.find((panel: any) => panel.id === "hpanel_1_1");
        // const panelHPanel20Data = panelData.find((panel: any) => panel.id === "hpanel_2_0");
        // const panelHPanel21Data = panelData.find((panel: any) => panel.id === "hpanel_2_1");


        setPanelHPanel00({
            id: "hpanel_0_0", data: getRightData(panelHPanel00Data), mode: panelHPanel00Data.mode, size: panelHPanel00Data.size,
            type: panelHPanel00Data.type
        });
        setPanelHPanel01({
            id: "hpanel_0_1", data: getRightData(panelHPanel01Data), mode: panelHPanel01Data.mode, size: panelHPanel01Data.size,
            type: panelHPanel01Data.type
        });
        setPanelHPanel10({
            id: "hpanel_1_0", data: getRightData(panelHPanel10Data), mode: panelHPanel10Data.mode, size: panelHPanel10Data.size,
            type: panelHPanel10Data.type
        });
        setPanelHPanel11({
            id: "hpanel_1_1", data: getRightData(panelHPanel11Data), mode: panelHPanel11Data.mode, size: panelHPanel11Data.size,
            type: panelHPanel11Data.type
        });
        // setPanelHPanel20({ id: "hpanel_2_0", data: JSON.parse(panelHPanel20Data.data), mode: panelHPanel20Data.mode, size: panelHPanel00Data.size });
        // setPanelHPanel21({ id: "hpanel_2_1", data: JSON.parse(panelHPanel21Data.data), mode: panelHPanel21Data.mode, size: panelHPanel00Data.size });

        dispatch(replaceAllEditorsData([
            { id: "hpanel_0_0", data: JSON.parse(panelHPanel00Data.data), mode: panelHPanel00Data.mode },
            { id: "hpanel_0_1", data: JSON.parse(panelHPanel01Data.data), mode: panelHPanel01Data.mode },
            { id: "hpanel_1_0", data: JSON.parse(panelHPanel10Data.data), mode: panelHPanel10Data.mode },
            { id: "hpanel_1_1", data: JSON.parse(panelHPanel11Data.data), mode: panelHPanel11Data.mode },
            // { id: "hpanel_2_0", data: JSON.parse(panelHPanel20Data.data), mode: panelHPanel20Data.mode },
            // { id: "hpanel_2_1", data: JSON.parse(panelHPanel21Data.data), mode: panelHPanel21Data.mode },
        ]));

        setLoading(false);
        // console.log("dispatching false")
        dispatch(setLoadingEditorData(false));

    }, [panelData])

    // useEffect(() => {
    //     if (workingPanelData.id === "hpanel_0_0") {
    //         setPanelHPanel00(workingPanelData);
    //     } else if (workingPanelData.id === "hpanel_0_1") {
    //         setPanelHPanel01(workingPanelData);
    //     }
    //     else if (workingPanelData.id === "hpanel_1_0") {
    //         setPanelHPanel10(workingPanelData);
    //     }
    //     else if (workingPanelData.id === "hpanel_1_1") {
    //         setPanelHPanel11(workingPanelData);
    //     }
    // }, [workingPanelData])

    useEffect(() => {

        // console.log("MobileNavData", mobileNavData.currentPanel);
        if (mobileNavData.currentPanel === "hpanel_0_0") {
            // console.log("setting panelHPanel00", panelHPanel00)
            const dataBlocksFromAllEditors = allEditorsData.dataBlocks.find((dataBlock: any) => dataBlock.id === "hpanel_0_0");
            const data = dataBlocksFromAllEditors ? dataBlocksFromAllEditors.data : {};
            const oldData = panelHPanel00.data;

            // console.log('new data', data)

            setWorkingPanelData(
                { ...panelHPanel00, data: { ...oldData, ...data } }
            )

        } else if (mobileNavData.currentPanel === "hpanel_0_1") {
            const dataBlocksFromAllEditors = allEditorsData.dataBlocks.find((dataBlock: any) => dataBlock.id === "hpanel_0_1");
            const data = dataBlocksFromAllEditors ? dataBlocksFromAllEditors.data : {};

            // console.log('new data', data)
            const oldData = panelHPanel01.data;
            // console.log('old data', oldData)
            // 
            // console.log('merged', { ...oldData, ...data })


            setWorkingPanelData(
                { ...panelHPanel01, data: { ...oldData, ...data } }
            )


        }
        else if (mobileNavData.currentPanel === "hpanel_1_0") {

            const dataBlocksFromAllEditors = allEditorsData.dataBlocks.find((dataBlock: any) => dataBlock.id === "hpanel_1_0");
            const data = dataBlocksFromAllEditors ? dataBlocksFromAllEditors.data : {};

            const oldData = panelHPanel10.data;

            setWorkingPanelData(
                { ...panelHPanel10, data: { ...oldData, ...data } }
            )
        }
        else if (mobileNavData.currentPanel === "hpanel_1_1") {
            const dataBlocksFromAllEditors = allEditorsData.dataBlocks.find((dataBlock: any) => dataBlock.id === "hpanel_1_1");
            const data = dataBlocksFromAllEditors ? dataBlocksFromAllEditors.data : {};
            const oldData = panelHPanel11.data;

            setWorkingPanelData(
                { ...panelHPanel11, data: { ...oldData, ...data } }
            )
        }

    }, [panelHPanel00, panelHPanel01, panelHPanel10, panelHPanel11, mobileNavData.currentPanel])

    // console.log("MobilePanels", { panelData, workingPanelData, loading });

    // useEffect(() => {
    //     if (workingPanelData.id === "hpanel_0_0") {
    //         setPanelHPanel00(workingPanelData);
    //     } else if (workingPanelData.id === "hpanel_0_1") {
    //         setPanelHPanel01(workingPanelData);
    //     }
    //     else if (workingPanelData.id === "hpanel_1_0") {
    //         setPanelHPanel10(workingPanelData);
    //     }
    //     else if (workingPanelData.id === "hpanel_1_1") {
    //         setPanelHPanel11(workingPanelData);
    //     }
    // }, [workingPanelData])


    const getMainPanelView = (panelId: string) => {
        // if (panelId === "hpanel_0_0") {

        let workingData = null;
        // console.log('panelId', panelId, panelData)

        let drawpanelcRef = null;
        let codepanelcRef = null;
        let mathpanelcRef = null;
        let editorPanelRef = null


        if (panelId === "hpanel_0_0") {
            drawpanelcRef = drawHPanel00Ref;
            codepanelcRef = codeHPanel00Ref;
            mathpanelcRef = mathHPanel00Ref;
            editorPanelRef = editorHPanel00Ref;
            workingData = panelHPanel00;
        } else if (panelId === "hpanel_1_0") {
            drawpanelcRef = drawHPanel10Ref;
            codepanelcRef = codeHPanel10Ref;
            mathpanelcRef = mathHPanel10Ref;
            editorPanelRef = editorHPanel10Ref;
            workingData = panelHPanel10;
        } else if (panelId === "hpanel_0_1") {
            codepanelcRef = codeHPanel01Ref;
            mathpanelcRef = mathHPanel01Ref;
            editorPanelRef = editorHPanel01Ref;
            workingData = panelHPanel01;
        } else if (panelId === "hpanel_1_1") {
            codepanelcRef = codeHPanel11Ref;
            mathpanelcRef = mathHPanel11Ref;
            editorPanelRef = editorHPanel11Ref;
            workingData = panelHPanel11;
        }

        const setData = (data: any) => {
            // console.log('setting data', data, panelId)
            if (panelId === "hpanel_0_0") {
                setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, data }));
            } else if (panelId === "hpanel_1_0") {
                // console.log('setting panelHPanel10', data)
                setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, data }));
            } else if (panelId === "hpanel_0_1") {
                setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, data }));
            } else if (panelId === "hpanel_1_1") {
                setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, data }));
            }
        }

        if (!workingData) return null;
        const panelData = workingData;

        if (panelData.mode === 'math') {
            return <MathSheet ref={mathpanelcRef} type={panelData.type} id={panelId} data={panelData.data} showRefs={panelData.showInfo ?
                true : false
            } onEdChange={setData} />
        } else if (panelData.mode === 'draw') {
            return <ExcalidrawEditor showReference={panelData.showInfo ?
                true : false} readonly={allEditorsData.currentTabShared} data={panelData.data} ref={drawpanelcRef} id={panelId} onEdChange={setData} />
        } else if (panelData.mode === 'code') {
            return <CodeEditor ref={codepanelcRef} showCustom={
                panelData.showCustom ?
                    true : false
            } readonly={allEditorsData.currentTabShared} id={panelId} data={panelData.data} langu={panelData.type && panelData.type.lang ? panelData.type.lang : null} change={(lang: string, editorStyle: string) => {
                const newObj = panelData.type;
                const newType = { ...newObj, lang };
                saveTypeData(newType, panelId);
                setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, type: newType }));
                saveEditorStyle(editorStyle);
                dispatch(setCodeEditorStyle(editorStyle));
            }} onEdChange={setData} />
        }
        return <div className="editor-wrapper"><EditorComponent ref={editorPanelRef} readonly={allEditorsData.currentTabShared} loading={loading} onEdChange={setData} data={panelData.data} style={{ height: '80vh', paddingLeft: '30px' }} id={
            panelId.slice(-4)
        } /></div>
    }

    const saveTypeData = async (type: any, panelId: string) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
            id: panelId,
            type,
        }, { merge: true });

        batch.commit();
    }

    const saveEditorStyle = async (style: any) => {
        const userId = user?.uid;
        if (!userId) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "data", userId, "userInfo", "prefs"), {
            codeEditorStyle: style,
        }, { merge: true });

        batch.commit();
    }

    // allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_0_0')?.data || {} => for colors we needed to get from redux because of the updated state there,
    // the component state doens't cover it
    return (
        <>
            <div style={{ position: 'relative', marginTop: "30px", height: !workingPanelData.mode || workingPanelData.mode === "edit" ? "calc(100% - 30px)" : "95%", paddingTop: '40px', width: '100%' }}>
                {/* <div className='main-space-top-left-button-container-desktop' >
                    {showShowNavBarButton()}
                    {helpButton(mobileNavData.currentPanel)}
                    {customizeButton(mobileNavData.currentPanel)}
                    {menuBlock(mobileNavData.currentPanel)}
                </div> */}
                {!loading ? getMainPanelView(mobileNavData.currentPanel) : null}
            </div>
        </>
    );
};

export default MobilePanels;