/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */


import { Avatar, Box, BoxProps, Button, ButtonGroup, Checkbox, DialogContent, DialogTitle, Divider, Dropdown, IconButton, Input, Menu, MenuButton, MenuItem, Modal, ModalClose, ModalDialog, Switch, Tooltip, Typography, useColorScheme } from "@mui/joy";
import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import { ColorSchemeButton } from "./button-color-scheme";
import { AccountCircle, Add, CopyAllOutlined, CreateNewFolder, DeleteOutline, Group, InfoOutlined, MenuOutlined, MoreHoriz, Publish, PushPin, SaveOutlined, Update } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../core/AuthProvider";
import { db } from "../../firebase";
import { setDoc, doc, writeBatch, getDocs, collection } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { setUnsavedChanges, setWholeEditorLoading, setAllTabs, setAllTabsIncludingDeleted, setCodeEditorStyle, setUserPrefs, setDrawingUploadingImageStatus, setDrawPanelExportTriggerFlag, setExportLayoutOrder, setCurrentTabArchived, setIsNewTabOpening, setIdMode, setIsLoadingTabFinished, setShowNavBar } from "../../state/allEditorsData/allEditorDataSlice"
import debounce from "lodash.debounce";
import { isMobile } from "react-device-detect";
import TPLogo from '../../assets/tp_long_logo.png';
import TPLogoW from '../../assets/tp_logo_white.png';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import * as Sentry from "@sentry/react";
// import { useDrag, useDrop, DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import FileExporterPanel from "../MainUI/FileExporterPanel";
import ShowTabInfoPanel from "../MainUI/ShowTabInfoPanel";
import ArchivedTabsModal from "../MainUI/ArchivedTabs";
import { returnOnlyUniqueArrayBasedOnAttribute } from "../../utilities/common";

TimeAgo.addDefaultLocale(en)


// const types = {
//     BUTTON: 'button',
// };

// const DragButton = ({ id, index, moveButton, children }:
//     { id: string, index: number, moveButton: any, children: any }
// ) => {
//     const [, drag] = useDrag({
//         type: types.BUTTON,
//         item: { id, index },
//         collect: (monitor) => ({
//             isDragging: monitor.isDragging(),
//         }),
//     });

//     const [, drop] = useDrop({
//         accept: types.BUTTON,
//         hover(item: any) {
//             if (item.index !== index) {
//                 moveButton(item.index, index);
//                 item.index = index;
//             }
//         },
//     });

//     return (
//         <div ref={(node) => drag(drop(node))}>
//             {children}
//         </div>
//     );
// };


export function Toolbar(props: ToolbarProps): JSX.Element {
    const timeAgo = new TimeAgo('en-US');
    const { mode, systemMode } = useColorScheme();
    const dispatch = useDispatch<AppDispatch>();
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    // const envRunning = import.meta.env.PROD;
    // const apiUrl = import.meta.env.VITE_ENDPOINT_URL_BASE;
    const [showArchived, setShowArchived] = useState<boolean>(false);

    const [initTabName, setInitTabName] = useState<string>("");
    const [showTitleError, setShowTitleError] = useState<boolean>(false);
    const [currentlyEditing, setCurrentlyEditing] = useState<boolean>(false);
    const [currentWSpaceInfo, setCurrentWSpaceInfo] = useState<any>({});

    const [tabShared, setCurTabShared] = useState<boolean>(false);
    const [isSpaceShared, setIsSpaceShared] = useState<boolean>(false);
    const [commonShared, setCommonShared] = useState<boolean>(false);
    const [allWorkspaces, setAllWorkspaces] = useState<any[]>([]);

    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";
    const [selectedTabId, setSelectedTabId] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [exportOpen, setExportOpen] = useState<boolean>(false);
    const [showInfoOpen, setShowInfoOpen] = useState<boolean>(false);
    const [isWorkspaceDataLoaded, setIsWorkspaceDataLoaded] = useState<boolean>(false);
    const [isTabsLoaded, setIsTabsLoaded] = useState<boolean>(false);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [savingData, setSavingData] = useState<boolean>(false);
    const { user } = authContext;
    const [tabs, setTabs] = useState<any>([]);
    const [hiddenTabs, setHiddenTabs] = useState<any>([]);
    const [allTabsInPage, setAllTabsInPage] = useState<any>([]);

    const { sx, hideAllOptions, ...other } = props;

    const [showWTitleError, setShowWTitleError] = useState<boolean>(false);
    const [initWorkspaceName, setInitWorkspaceName] = useState<string>("");


    const setTitle = (title: string, tabId: string) => {
        const tabsCloned = JSON.parse(JSON.stringify(tabs));
        const updatedTabs = tabsCloned.map((t: any) => {
            if (t.id.toString() === tabId.toString()) {
                t.name = title;
            }
            return t;
        });

        setTabs(updatedTabs);
    }

    const showInputField = (tabId: string, status: boolean) => {

        const tabsClone = JSON.parse(JSON.stringify(tabs));
        tabsClone.map((t: any) => {
            if (t.id.toString() === tabId.toString()) {
                t.showInput = status;
            }
            return t;
        });

        setTabs(tabsClone);
    }


    const saveAllPanelData = async () => {
        if (!user || !user.uid) return;

        if (!allEditorsData.isLoadingTabFinished) {
            return; //this is to stop saving data when tab is still loading because data update on store happens when changes occur
        }

        Sentry.addBreadcrumb({
            category: "auth",
            message: "Authenticated user " + user.uid + " saving data",
            level: "info",
        });

        try {
            setSavingData(true);
            const dataFromAllTabs = allEditorsData.allTabsUnsavedData;
            // console.log("dataFromAllTabs", dataFromAllTabs);
            const allTabKeys = Object.keys(dataFromAllTabs);
            const batch = writeBatch(db);

            allTabKeys.forEach((tabId: any) => {
                const ids = ["hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1"];

                const allEditorsDataValid = dataFromAllTabs[tabId];

                // console.log("allEditorsDataValid", allEditorsDataValid);

                for (let i = 0; i < ids.length; i++) {
                    // console.log('here')
                    const panelId = ids[i];
                    // console.log("Saving data for panel", panelId);
                    const content = allEditorsDataValid.find((data: any) => data.id === panelId)?.data || [];
                    const type = allEditorsDataValid.find((data: any) => data.id === panelId)?.type || {};

                    // console.log('content', content, 'type', type)
                    if (content) {
                        batch.set(doc(db, "spaces", wid, "tabs", tabId, "panels", panelId), {
                            data: JSON.stringify(content),
                            type
                        }, { merge: true });
                    }
                }

                // console.log("Saving data for tab", tabId);
                batch.set(doc(db, "data", user.uid, "userInfo", "lastWorkedWid"), {
                    lastWorked: wid
                }, { merge: true });

                batch.set(doc(db, "spaces", wid, "tabs", tabId), {
                    modified: new Date().getTime()
                }, { merge: true });


                // console.log("Saving data for tab2", tabId);
                const tabInfo = { id: tabId, lastModified: new Date().getTime() };
                const allExistingTabInfo = JSON.parse(sessionStorage.getItem("allTabInfo") || "{}");
                allExistingTabInfo[tabId] = tabInfo;
                sessionStorage.setItem("allTabInfo", JSON.stringify(allExistingTabInfo));
            });

            // console.log("saving data")
            setCurrentWSpaceInfo((prev: any) => {
                return {
                    ...prev,
                    modified: new Date().getTime()
                }
            });


            batch.set(doc(db, "data", user?.uid, "spaces", wid), {
                modified: new Date().getTime()
            }, { merge: true });

            await batch.commit();
            dispatch(setUnsavedChanges(false));
            // dispatch(saveDataBlocksBackup(allEditorsData.dataBlocks));
            setSavingData(false);
        } catch (e) {
            Sentry.captureException(e);
            // console.log("Error saving data", e);
        }


    }

    const debouncedSaveAllPanelData = debounce(saveAllPanelData, 5000); // 10 seconds

    useEffect(() => {
        if (allEditorsData.unsavedChanges && allEditorsData.dataBlocks) {
            if (allEditorsData.isSpaceIsOwner) {
                debouncedSaveAllPanelData();
            }
        }
        return () => {
            debouncedSaveAllPanelData.cancel();
        };
    }, [allEditorsData.unsavedChanges, allEditorsData.dataBlocks, allEditorsData.isSpaceIsOwner]);

    const getUserPrefs = () => {
        if (user && user.uid) {
            getDocs(collection(db, "data", user.uid, "userInfo")).then((workspace) => {
                workspace.forEach((doc) => {
                    if (doc.id === "prefs") {
                        dispatch(setCodeEditorStyle(doc.data().codeEditorStyle));
                    }
                    if (doc.id === user.uid) {
                        dispatch(setUserPrefs(doc.data()));
                    }
                });
            }).catch((e) => {
                Sentry.captureException(e);
                // console.log("Error fetching workspace", e);
            });
        }
    }

    useEffect(() => {
        window.name = "thinkpost";
    }, []);

    useEffect(() => {
        if (user && user.uid) {
            getUserPrefs();
        }
    }, [user])

    const setTitleSharedFromCall = async () => {
        // console.log("Fetching tabs for ", allEditorsData.currentWorkspaceSmallInfo.createdby);
        const querySnapshot = await getDocs(collection(db, "data", allEditorsData.currentWorkspaceSmallInfo.createdby, "spaces"));
        // console.log("querySnapshot", querySnapshot.docs)
        const wspaces: any[] = [];
        querySnapshot.forEach((doc: any) => {
            // console.log("Doc", doc.data());
            wspaces.push(
                doc.data()
            );

            if (doc.data().wid === wid) {
                document.title = doc.data().title;
                setCurrentWSpaceInfo({
                    ...doc.data(),
                    inputMode: 0
                });
            }
        });
        // console.log("All workspaces", wspaces)
        setAllWorkspaces(wspaces);
        setIsWorkspaceDataLoaded(true);
        // const allspacesLocal = sessionStorage.getItem("allspaces");
        // if (allspacesLocal) {
        //     const allspaces = JSON.parse(allspacesLocal);
        //     wspaces.forEach((space: any) => {
        //         const currentSpace = allspaces.find((wspace: any) => wspace.id === space.id);
        //         const mergedData = { ...currentSpace, ...space };
        //         allspaces[allspaces.indexOf(currentSpace)] = mergedData;
        //         sessionStorage.setItem("allspaces", JSON.stringify(allspaces));
        //     });
        // }
    };

    useEffect(() => {
        // console.log('allEditorsData.currentWorkspaceSmallInfo', allEditorsData.currentWorkspaceSmallInfo)
        if (wid && !isWorkspaceDataLoaded && allEditorsData.currentWorkspaceSmallInfo["createdby"]) {
            setTitleSharedFromCall();
        }
    }, [wid, user, allWorkspaces, allEditorsData.currentWorkspaceSmallInfo]);

    useEffect(() => {

        if (tabs.length > 0 && allTabsInPage.length > 0) {

            //only two ways it can come here, from empty to loading tab first time and loading from another tab to here
            if (!selectedTabId) {
                setSelectedTabId(tid);
            }
            const currentTab = allTabsInPage.find((tab: any) => tab.id.toString() === tid.toString());
            const currentTabIndex = allTabsInPage.findIndex((tab: any) => tab.id.toString() === tid.toString());

            // console.log("currentTab", currentTab);
            if (currentTab) {
                setCurTabShared(currentTab.public);
                // console.log("currentTab", currentTab, currentTabIndex, hiddenTabs.length);
                if (currentTabIndex >= 3 && hiddenTabs.length > 0) {
                    const lastTab = tabs[tabs.length - 1];

                    if (lastTab && lastTab.id.toString() === tid.toString()) return;

                    const indexInHiddenTabs = hiddenTabs.findIndex((tab: any) => tab.id.toString() === tid.toString());

                    // setTabs((prevTabs: any) => {
                    //     const updatedTabs = [...prevTabs];
                    //     updatedTabs[prevTabs.length - 1] = hiddenTabs[indexInHiddenTabs];

                    //     return updatedTabs;
                    // });

                    const tabsCloned = JSON.parse(JSON.stringify(tabs));
                    const updatedTabs = [...tabsCloned];
                    updatedTabs[updatedTabs.length - 1] = hiddenTabs[indexInHiddenTabs];
                    setTabs(updatedTabs);


                    // console.log("updatedTabs", updatedTabs);

                    // console.log("updatedTabs", updatedTabs);
                    if (allEditorsData.isSpaceIsOwner) {
                        updateTabOrders(updatedTabs, 0);
                    }

                    setHiddenTabs((prevHiddenTabs: any) => {
                        const prevHiddenTabAll = [...prevHiddenTabs];
                        prevHiddenTabAll.splice(indexInHiddenTabs, 1);
                        if (lastTab && !lastTab.archived) {
                            const updatedHiddenTabs = [lastTab, ...prevHiddenTabAll];
                            if (allEditorsData.isSpaceIsOwner) {
                                updateTabOrders(updatedHiddenTabs, updatedTabs.length);
                            }
                            return updatedHiddenTabs;
                        } else {
                            if (allEditorsData.isSpaceIsOwner) {
                                updateTabOrders(prevHiddenTabAll, updatedTabs.length);
                            }
                            return prevHiddenTabAll;
                        }

                    });
                }

            }
        }
    }, [tid, tabs, hiddenTabs, allTabsInPage, allEditorsData.isSpaceIsOwner])

    // const getAllTabsDataAndSavedToRedux = async (updatedTabs: any) => {
    //     const tabsExceptCurrentOne = updatedTabs.filter((tab: any) => tab.id.toString() !== tid.toString());
    //     for (let i = 0; i < tabsExceptCurrentOne.length; i++) {
    //         const tab = updatedTabs[i];
    //         const querySnapshot = await getDocs(collection(db, "spaces", wid, "tabs", tab.id, "panels"));

    //         querySnapshot.forEach((doc) => {
    // console.log("doc from other end", doc.data());

    // const data = doc.data().data;
    // const realData = JSON.parse(data);
    // const assemblingData = {
    //     id: doc.data().id,
    //     data: realData,
    //     type: doc.data().type,
    //     mode: doc.data().mode,
    //     size: doc.data().size
    // };

    // console.log("assemblingData", doc.data());

    //             dispatch(setAllTabsData({
    //                 tabId: tab.id,
    //                 id: doc.id,
    //                 data: doc.data()
    //             }))
    //         });

    //     }
    // };

    useEffect(() => {
        if (tabs.length === 0 && allEditorsData.allTabs.length > 0 && !isTabsLoaded) {

            setAllTabsInPage(allEditorsData.allTabs);

            const allTabs = allEditorsData.allTabs.filter((_tab: any, index: any) => index < 4);

            setTabs(allTabs);

            const hiddenTabsInList = allEditorsData.allTabs.filter((_tab: any, index: any) => index >= 4);
            setHiddenTabs(hiddenTabsInList);

            setIsTabsLoaded(true);
        }
    }, [allEditorsData.allTabs, isTabsLoaded])


    useEffect(() => {
        const currentTab = allEditorsData.allTabsIncludingDeleted.find((tab: any) => tab.id.toString() === tid.toString());

        if (allEditorsData.isSpaceIsShared) {
            setIsSpaceShared(true);
        }

        if (currentTab) {
            // console.log('allEditorsData.isSpaceIsShared', allEditorsData.isSpaceIsShared)
            setCurTabShared(currentTab.public);
            if (allEditorsData.isSpaceIsShared) {
                setCurTabShared(true);
            }
        }

    }, [tid, allEditorsData.allTabsIncludingDeleted, allEditorsData.isSpaceIsShared]);


    const newTab = async () => {
        if (!user || !user.uid) return;

        dispatch(setWholeEditorLoading(true));

        const tabNo = (allEditorsData.allTabsIncludingDeleted.length + 1);
        // console.log("Creating new tab", tabNo);
        // setTabs([...tabs, { id: tabNo, name: "Tab " + tabNo, showInput: false, public: false, modified: new Date().getTime(), no: tabNo }]);

        const newTid = organizeTabsWithAdd(tabNo);
        const batch = writeBatch(db);
        //create a new workspace in firebase

        batch.set(doc(db, "spaces", wid, "tabs", newTid), {
            id: newTid,
            no: tabNo,
            title: "Tab " + tabNo,
            modified: new Date().getTime(),
            public: false,
            createdby: user.uid,
        });

        const ids = ["vpanel_0_0", "vpanel_1_0",
            // "vpanel_2_0", 
            "hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1",
            // "hpanel_2_0", "hpanel_2_1"
        ];
        const sizes = [50, 49,
            // 0.1, 
            99, 0.1, 99, 0.1,
            //  99, 0.1
        ];
        for (let i = 0; i < ids.length; i++) {
            const panelId = ids[i];
            batch.set(doc(db, "spaces", wid, "tabs", newTid, "panels", panelId), {
                id: panelId,
                size: sizes[i],
                data: "{}",
                mode: "edit"
            });
        }

        dispatch(setIdMode(-1));
        dispatch(setIsLoadingTabFinished(false));
        dispatch(setIsNewTabOpening(true));
        dispatch(setWholeEditorLoading(true));
        await batch.commit();
        //create new workspace in firestore

        sessionStorage.setItem("tid", newTid);

        navigate('/' + wid + '/' + newTid);
    }

    const organizeTabsWithAdd = (tabNo: string, tabObj: any = null, isArchived: boolean = false) => {

        const newTab = tabObj ? JSON.parse(JSON.stringify(tabObj)) : { id: tabNo, name: "Tab " + tabNo, showInput: false, public: false, modified: new Date().getTime(), no: tabNo };
        newTab.archived = isArchived;
        let allTabsIncludingDeleted = JSON.parse(JSON.stringify(allEditorsData.allTabsIncludingDeleted));

        const newTid = tabNo + "";

        if (allTabsInPage.length >= 4) {

            const newTabs = [...tabs];
            const lastTabIndex = newTabs.length - 1;
            newTabs.splice(lastTabIndex, 1);

            // console.log('newTabs', newTabs)
            setSelectedTabId(newTid);

            setTabs([...newTabs, newTab]);


            const lastTab = JSON.parse(JSON.stringify(tabs[tabs.length - 1]));

            if (!lastTab.archived) {
                setHiddenTabs([lastTab, ...hiddenTabs]);
            }
            // setAllTabsInPage([...newTabs, lastTab, ...hiddenTabs]);
            setAllTabsInPage([...newTabs, newTab, lastTab, ...hiddenTabs]);


            const newTabCloned = JSON.parse(JSON.stringify(newTab));
            newTabCloned.deleted = false

            if (isArchived) {
                newTabCloned.archived = true;
            }

            allTabsIncludingDeleted.push(newTabCloned);

        } else {

            setSelectedTabId(newTid);
            setTabs([...tabs, newTab]);

            // console.log('setting new tab to alltabs',newTab)
            setAllTabsInPage([...tabs, newTab]);

            const newTabCloned = JSON.parse(JSON.stringify(newTab));
            newTabCloned.deleted = false

            if (isArchived) {
                newTabCloned.archived = true;
            }

            allTabsIncludingDeleted.push(newTab);
        }



        // console.log("called organizeTabsWithAdd");
        dispatch(setAllTabsIncludingDeleted(
            allTabsIncludingDeleted
        ))

        dispatch(setAllTabs(
            allTabsInPage
        ))


        return newTid;


    }

    const saveTabName = async (newName: string, tabId: string) => {
        await setDoc(doc(db, "spaces", wid, "tabs", tabId), {
            title: newName,
            modified: new Date().getTime()
        }, { merge: true });

        dispatch(setAllTabs(
            tabs
        ))
    }


    const deleteTab = () => {
        if (!user || !user.uid) return;

        if (tabs.length === 1) {
            alert("You cannot delete the last tab. Please create a new tab before deleting this one.");
            return;
        }

        const confirmDelete = window.confirm("Are you sure you want to delete this tab (and data)?");
        if (confirmDelete) {
            const batch = writeBatch(db);
            const ids = ["vpanel_0_0", "vpanel_1_0",
                // "vpanel_2_0", 
                "hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1",
                //  "hpanel_2_0", "hpanel_2_1"
            ];
            ids.forEach((panelId) => {
                batch.delete(doc(db, "spaces", wid, "tabs", tid, "panels", panelId));
            });
            // batch.delete(doc(db, wid, wid, "tabs", tid)); //to keep the numbering correct
            batch.set(doc(db, "spaces", wid, "tabs", tid), {
                deleted: true
            }, { merge: true });
            batch.commit();

            // const currentTabIndexInAll = allTabsInPage.findIndex((tab: any) => tab.id.toString() === tid.toString());
            organizeTabsWithDelete();
        }

    }

    // useEffect(() => {
    //     console.log("Tabs changed", tabs);
    // }, [tabs]);

    const organizeTabsWithDelete = (archiveAlso: boolean = false) => {
        const tabsExceptCurrent = tabs.filter((tab: any) => tab.id.toString() !== tid.toString());
        const currentTabIndex = tabs.findIndex((tab: any) => tab.id.toString() === tid.toString());

        let allTabsInPageNow = []
        let updatedTabs: any = [];
        if (hiddenTabs.length > 0) {
            const firstHiddenTab = hiddenTabs[0];
            if (firstHiddenTab) {
                updatedTabs = [...tabsExceptCurrent, firstHiddenTab];
                setTabs(updatedTabs);
                setHiddenTabs(hiddenTabs.filter((tab: any) => tab.id.toString() !== firstHiddenTab.id.toString()));
            }
            allTabsInPageNow = [...updatedTabs, ...hiddenTabs];
        } else {
            updatedTabs = tabsExceptCurrent;
            setTabs(updatedTabs);
            allTabsInPageNow = updatedTabs;
        }

        let workingIndex = 0;

        const nextTab = updatedTabs[currentTabIndex];
        const prevTab = updatedTabs[currentTabIndex - 1];

        let wTab = null;
        if (nextTab) {
            wTab = nextTab;
            workingIndex = nextTab.id;
        } else if (prevTab) {
            wTab = prevTab;
            workingIndex = prevTab.id;
        }

        // console.log('workingIndex', workingIndex + 1)
        setAllTabsInPage(allTabsInPageNow);
        setSelectedTabId(workingIndex.toString())

        dispatch(setAllTabs(
            updatedTabs
        ))
        dispatch(setUnsavedChanges(false));

        const allTabsIncludingDeletedCloned = JSON.parse(JSON.stringify(allEditorsData.allTabsIncludingDeleted));
        const tabInsideAllTabsIncludingDeletedIndex = allTabsIncludingDeletedCloned.findIndex((tab: any) => tab.id.toString() === tid.toString());
        if (tabInsideAllTabsIncludingDeletedIndex !== -1) {
            allTabsIncludingDeletedCloned[tabInsideAllTabsIncludingDeletedIndex].deleted = true;
            if (archiveAlso) {
                allTabsIncludingDeletedCloned[tabInsideAllTabsIncludingDeletedIndex].archived = true;
            }
        }
        // console.log("called organizeTabsWithDelete", allTabsIncludingDeletedCloned.length);
        dispatch(setAllTabsIncludingDeleted(allTabsIncludingDeletedCloned));

        dispatch(setIdMode(-1)); //wherever navigate i
        dispatch(setIsLoadingTabFinished(false)); //wherever navigate
        dispatch(setWholeEditorLoading(true));
        const nextTabId = wTab ? wTab.id : updatedTabs[0]?.id;
        navigate(`/${wid}/${nextTabId}`);
    }

    const hideNavBar = () => {
        dispatch(setShowNavBar(false));
        sessionStorage.setItem("hideNavBar", "false");
    }

    const setSpaceShared = async (tabId: string, val: boolean) => {
        if (tabId.toString() === selectedTabId.toString()) {
            if (val) {
                setCurTabShared(true);
            } else {
                const tabFromAllPages = allTabsInPage.find((tab: any) => tab.id.toString() === tabId.toString());
                if (tabFromAllPages) {
                    setCurTabShared(tabFromAllPages.public);
                }
            }
        }
        const batch = writeBatch(db);
        batch.set(doc(db, "spaces", wid, "tabs", "1"), {
            spaceShared: val,
        }, { merge: true });

        await batch.commit();

        setIsSpaceShared(val);
    }

    const setTabShared = async (tabId: string, val: boolean) => {
        const userId = user?.uid;
        if (!userId) return;

        // console.log("Setting tab shared", tabId, selectedTabId, val);

        if (tabId.toString() === selectedTabId.toString()) {
            setCurTabShared(val);
        }
        const batch = writeBatch(db);
        batch.set(doc(db, "spaces", wid, "tabs", tid), {
            public: val
        }, { merge: true });

        await batch.commit();
        // const tabsCloned = JSON.parse(JSON.stringify(tabs));
        // const newTabs = tabsCloned.map((tab: any) => {
        //     if (tab.id.toString() === tabId.toString()) {
        //         tab.public = val;
        //     }
        //     return tab;
        // });

        const allTabsInPageCloned = JSON.parse(JSON.stringify(allTabsInPage));
        const newAllTabsInPage = allTabsInPageCloned.map((tab: any) => {
            if (tab.id.toString() === tabId.toString()) {
                tab.public = val;
            }
            return tab;
        });
        setAllTabsInPage(newAllTabsInPage);

        // setTabs(newTabs);
        // dispatch(setAllTabs(
        //     newTabs
        // ))
    }

    useEffect(() => {
        // console.log("Tab shared", tabShared);
        if (tabShared) {
            setCommonShared(true);
        } else {
            setCommonShared(false);
        }
    }, [tabShared])

    // console.log('current wspace info', currentWSpaceInfo);

    // useEffect(()=>{
    //     const selectedIndex = tabs.findIndex((tab: any) => tab.id === selectedTabId);
    //     if (selectedIndex === -1 || Math.abs(selectedIndex - 3) > 1) {
    //         const newTabs = [...tabs];
    //         const currentTab = newTabs.find((tab: any) => tab.id === selectedTabId);
    //         if (currentTab) {
    //             newTabs.splice(selectedIndex, 1);
    //             newTabs.splice(3, 0, currentTab);
    //             setTabs(newTabs);
    //         }
    //     }
    // },[selectedTabId])

    const setWorkspaceTitle = (wid: string, title: string) => {
        const userId = user?.uid;
        if (!userId) return;

        setDoc(doc(db, "data", userId, "spaces", wid), {
            title,
            modified: new Date().getTime()
        }, { merge: true });

        const allSpaces = JSON.parse(sessionStorage.getItem("allspaces") || "[]");
        const updatedSpaces = allSpaces.map((space: any) => {
            if (space.wid === wid) {
                space.title = title;
            }
            return space;
        });
        sessionStorage.setItem("allspaces", JSON.stringify(updatedSpaces));
        document.title = title;
    }

    const resetStuffBeforeExplore = () => {
        setTabs([]);
    }

    const updateTabOrders = async (newTabs: any, stepOver: number) => {
        // console.log("Updating tab orders", newTabs);
        const batch = writeBatch(db);
        newTabs.forEach((tab: any, index: number) => {
            // console.log('tab', tab, index, stepOver)
            batch.set(doc(db, "spaces", wid, "tabs", tab.id.toString()), {
                no: index + 1 + stepOver
            }, { merge: true });
        });
        await batch.commit();
    }

    // const moveButton = (from: number, to: number) => {
    //     const newTabs = [...tabs];
    //     const [removed] = newTabs.splice(from, 1);
    //     newTabs.splice(to, 0, removed);
    //     setTabs(newTabs);

    //     updateTabOrders(newTabs);
    // }

    const addRemoveTabArchive = async (tabId: string, isAdd: boolean) => {
        const userId = user?.uid;
        if (!userId) return;


        dispatch(setWholeEditorLoading(true));

        try {

            // console.log("Setting tab shared", tabId, selectedTabId, val);

            const batch = writeBatch(db);
            batch.set(doc(db, "spaces", wid, "tabs", tabId), {
                archived: isAdd
            }, { merge: true });

            await batch.commit();
            // const tabsCloned = JSON.parse(JSON.stringify(tabs));
            // const newTabs = tabsCloned.map((tab: any) => {
            //     if (tab.id.toString() === tabId.toString()) {
            //         tab.public = val;
            //     }
            //     return tab;
            // });

            const allTabsInPageCloned = JSON.parse(JSON.stringify(allTabsInPage));
            const newAllTabsInPage = allTabsInPageCloned.map((tab: any) => {
                if (tab.id.toString() === tabId.toString()) {
                    tab.archived = isAdd;
                }
                return tab;
            });
            setAllTabsInPage(newAllTabsInPage);

            const allTabs = JSON.parse(JSON.stringify(tabs));
            const tabInTabsIndex = allTabs.findIndex((tab: any) => tab.id.toString() === tabId.toString());
            if (tabInTabsIndex > -1) {
                allTabs[tabInTabsIndex].archived = isAdd;
                setTabs(allTabs);
            }
            //both of the above has to work with the respective use effect to correctly update the tab

            if (isAdd) {
                organizeTabsWithDelete(true);
                //this should correctly reset the following
                // const allTabsIncludingDeletedCloned = JSON.parse(JSON.stringify(allEditorsData.allTabsIncludingDeleted));
                // const tabInAllTabsIcludedIndex = allTabsIncludingDeletedCloned.findIndex((tab: any) => tab.id.toString() === tabId.toString());
                // if (tabInAllTabsIcludedIndex > -1) {
                //     allTabsIncludingDeletedCloned[tabInAllTabsIcludedIndex].archived = true;
                //     // dispatch(setAllTabsIncludingDeleted([])); //to trigger the change
                //     console.log("called addRemoveTabArchive", allTabsIncludingDeletedCloned.length);
                //     dispatch(setAllTabsIncludingDeleted(allTabsIncludingDeletedCloned));
                //     //identify why the above one is having one less
                // }
            } else {
                const allTabsIncludingDeletedCloned = JSON.parse(JSON.stringify(allEditorsData.allTabsIncludingDeleted));
                const tabNoInAllTabsInPages = allTabsIncludingDeletedCloned.find((tab: any) => tab.id.toString() === tabId.toString());

                if (tabNoInAllTabsInPages) {
                    organizeTabsWithAdd(tabNoInAllTabsInPages.no, tabNoInAllTabsInPages, false);

                    if (tid.toString() === tabId.toString()) {
                        dispatch(setCurrentTabArchived(false));
                    }
                    dispatch(setIdMode(-1));
                    dispatch(setIsLoadingTabFinished(false));
                    dispatch(setWholeEditorLoading(true));
                    // console.log('navigating to', '/' + wid + '/' + tabNoInAllTabsInPages.id);
                    navigate('/' + wid + '/' + tabNoInAllTabsInPages.id);

                }
            }
        } catch (error) {
            dispatch(setWholeEditorLoading(true));
        }


    }

    const loadTabFromArchive = (tab: any) => {
        const allTabsInPageCloned = JSON.parse(JSON.stringify(allTabsInPage));
        const tabInAllPages = allTabsInPageCloned.find((t: any) => t.id.toString() === tab.id.toString());

        if (!tabInAllPages) {
            dispatch(setCurrentTabArchived(true));
            organizeTabsWithAdd(tab.no, tab, true);
            setSelectedTabId(tab.id.toString());
            dispatch(setIdMode(-1));
            dispatch(setIsLoadingTabFinished(false));
            dispatch(setWholeEditorLoading(true));
            navigate('/' + wid + '/' + tab.id);
        }

        // navigate('/' + wid + '/' + tab.id);
    }

    // console.log('currentWSpaceInfo', currentWSpaceInfo)

    const allTabsInPagesUnique = returnOnlyUniqueArrayBasedOnAttribute(allTabsInPage, "id");

    const gotoTab = (tabId: string) => {
        if (tid.toString() !== tabId.toString()) {
            // if (allEditorsData.isSpaceIsOwner && allEditorsData.unsavedChanges) {
            //     const confirmNavigation = window.confirm("You have unsaved changes. Are you sure you want to navigate?");
            //     if (!confirmNavigation) {
            //         return;
            //     }
            // }

            dispatch(setIdMode(-1));
            dispatch(setExportLayoutOrder([]));
            dispatch(setDrawingUploadingImageStatus({}));
            dispatch(setDrawPanelExportTriggerFlag({}))
            sessionStorage.setItem("tid", tid);
            setSelectedTabId(tabId);
            dispatch(setCurrentTabArchived(false));
            dispatch(setIsLoadingTabFinished(false));
            dispatch(setWholeEditorLoading(true));
            navigate('/' + wid + "/" + tabId);
        }
    }

    const tabsUnique = returnOnlyUniqueArrayBasedOnAttribute(tabs, "id");
    const hiddenTabsUnique = returnOnlyUniqueArrayBasedOnAttribute(hiddenTabs, "id");

    return (
        <>
            <ShowTabInfoPanel setShowPanel={setShowInfoOpen} showPanel={showInfoOpen} tabInfo={currentWSpaceInfo} />
            <FileExporterPanel exportOpen={exportOpen} setExportOpen={setExportOpen} />
            <ArchivedTabsModal loadTabFromArchive={loadTabFromArchive} restoreTab={(tabId: any) => addRemoveTabArchive(tabId, false)} setModOpen={setShowArchived} modOpen={showArchived} allTabsInPages={allEditorsData.allTabsIncludingDeleted} />
            <Modal
                aria-labelledby="sharing"
                aria-describedby="sharing the space and tab"
                open={open}
                onClose={() => setOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <ModalDialog>
                    <DialogTitle><Group /> Sharing (for real-time viewing)</DialogTitle>
                    <DialogContent>Choose the options to share your space/tabs <br />for real-time viewing (Accessible to anyone with the link)</DialogContent>
                    <ModalClose variant="plain" sx={{ m: 1 }} />

                    <Input readOnly sx={{ marginTop: "20px" }} value={window.location.href}
                        endDecorator={<Tooltip size="sm" title="Copy link" variant="solid"><IconButton onClick={() => {
                            const link = window.location.href;
                            navigator.clipboard.writeText(link);
                        }}><CopyAllOutlined /></IconButton></Tooltip>}
                    />

                    <div style={{ height: "250px", display: 'flex', marginTop: "20px", flexDirection: 'column', gap: "10px", overflowY: 'scroll', overflowX: 'hidden' }}>
                        {
                            allTabsInPagesUnique && allTabsInPagesUnique.filter((tab: any) => !tab.archived).map((tab: any, index: any) => {
                                return <Checkbox disabled={isSpaceShared} key={index} label={tab.name} checked={tab.public} onChange={(e: any) => {
                                    setTabShared(tab.id, e.target.checked);
                                }} />
                            })
                        }
                    </div>
                    <Typography level="body-md">Or Share the whole space, with all the tabs</Typography>
                    <Checkbox label="Share the space" checked={isSpaceShared} onChange={(e: any) => {
                        setSpaceShared(tid, e.target.checked);
                    }} />


                    <Button sx={{ marginTop: "30px" }} onClick={() => setOpen(false)}>Close</Button>

                </ModalDialog>
            </Modal >
            <Box
                sx={{
                    zIndex: 1000,
                    backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : 'black',
                    alignItems: "center",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                    display: "flex",
                    height: isMobile ? "50px" : undefined, //for explorer tab
                    gap: 1,
                    px: 2,
                    ...sx,
                }}
                component="header"
                {...other}
            >
                {
                    mode === "light" || (mode === "system" && systemMode === "light") ? <img src={TPLogo} alt="logo" style={{ height: "20px", width: "90px" }} /> : <img src={TPLogoW} alt="logo" style={{ height: "20px", width: "90px" }} />
                }

                {currentWSpaceInfo && currentWSpaceInfo.title ?
                    (<div style={{ height: "30px", width: "100%", position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', left: "0px", zIndex: 98 }}>
                        {currentWSpaceInfo?.inputMode === 1 ? (
                            <><Input onFocus={(e) => { e.stopPropagation(); setInitWorkspaceName(currentWSpaceInfo?.title) }} error={showWTitleError} style={{ backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'gainsboro' : 'black' }} sx={{ "fontSize": "14px" }} value={currentWSpaceInfo?.title} placeholder="Space name" variant="plain" onChange={(event: any) => {

                                if (event.target.value.length > 30) {
                                    setShowTitleError(true);
                                    return;
                                }
                                setShowWTitleError(false);
                                setCurrentWSpaceInfo((prev: any) => {
                                    return {
                                        ...prev,
                                        title: event.target.value
                                    }
                                });

                            }} onKeyDown={(event: any) => {
                                if (event.key === 'Enter') {
                                    if (event.target.value.trim() === "" || event.target.value.length > 30) {
                                        setShowWTitleError(true);
                                        return;
                                    }

                                    setShowWTitleError(false);
                                    setCurrentlyEditing(false);
                                    setCurrentWSpaceInfo((prev: any) => {
                                        return {
                                            ...prev,
                                            inputMode: 0
                                        }
                                    });
                                    setWorkspaceTitle(wid, event.target.value);
                                }
                            }} onBlur={(e) => {
                                e.stopPropagation();
                                setShowWTitleError(false);
                                setCurrentlyEditing(false);
                                setCurrentWSpaceInfo((prev: any) => {
                                    return {
                                        ...prev,
                                        inputMode: 0,
                                        title: initWorkspaceName
                                    }
                                });
                            }} />
                            </>
                        ) : <>
                            <Tooltip title={(allEditorsData.isSpaceIsOwner ? "Click to change space title" : currentWSpaceInfo?.title)} size="sm" sx={{ zIndex: 102 }} >
                                <Button variant="plain" onClick={() => {

                                    if (!allEditorsData.isSpaceIsOwner) return;

                                    setCurrentWSpaceInfo((prev: any) => {
                                        return {
                                            ...prev,
                                            inputMode: 1
                                        }
                                    });
                                }}><b style={{ color: mode === "light" || (mode === "system" && systemMode === "light") ? "#32383E" : "#eeeeee" }}>{currentWSpaceInfo?.title}</b></Button></Tooltip>
                            {allEditorsData.currentTabArchived ? (<Tooltip title="Archived Tab" variant="solid">
                                <InfoOutlined color='primary' sx={{ height: "20px" }} />
                            </Tooltip>) : null}

                        </>
                        }
                    </div>) : null}
                <Box sx={{ display: "flex", flexGrow: 1, marginLeft: "30px", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : 'black' }} component="span" >
                    {tabsUnique && tabsUnique.length > 0 ? (
                        <>
                            {/* <DndProvider backend={HTML5Backend}> */}
                            <ButtonGroup variant="plain" aria-label="plain button group" sx={{ backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : 'black', zIndex: 101 }}>
                                {
                                    tabsUnique.map((tab: any, index: any) => {
                                        // console.log('selectedTabId', selectedTabId, tab.id);
                                        const normalButton = tab && tab.showInput && allEditorsData.isSpaceIsOwner ? (
                                            <Input
                                                key={tab.id}
                                                autoFocus
                                                error={showTitleError}
                                                onFocus={() => setInitTabName(tab.name)}
                                                style={{ backgroundColor: 'transparent', zIndex: 101 }} value={tab.name} placeholder="Tab name" variant="plain" onChange={(event: any) => {

                                                    if (event.target.value.length > 20) {
                                                        setShowTitleError(true);
                                                        return;
                                                    }

                                                    //if first keystroke, then first set the initial value


                                                    setShowTitleError(false);

                                                    setTitle(event.target.value, tab.id);
                                                }} onKeyDown={(event: any) => {
                                                    if (event.key === 'Enter') {

                                                        if (event.target.value.trim() === "" || event.target.value.length > 20) {
                                                            setShowTitleError(true);
                                                            return;
                                                        }
                                                        setShowTitleError(false);
                                                        setCurrentlyEditing(false);
                                                        setInitTabName("");
                                                        setTitle(event.target.value, tab.id);
                                                        showInputField(tab.id, false);
                                                        saveTabName(event.target.value, tab.id);
                                                    }
                                                }} onBlur={() => {
                                                    setShowTitleError(false);
                                                    setCurrentlyEditing(false);
                                                    setTitle(initTabName, tab.id);
                                                    setInitTabName("");
                                                    showInputField(tab.id, false);
                                                }} />
                                        ) : !hideAllOptions && tab && tab.id ? (
                                            // <DragButton key={tab.id} id={tab.id} index={index} moveButton={moveButton}>
                                            <Tooltip key={tab.id} title={tab.name + (allEditorsData.isSpaceIsOwner ? " - Double Click to change" : "")} size="sm" sx={{ zIndex: 102 }} >
                                                <Button disabled={currentlyEditing} variant={tab.id.toString() === selectedTabId.toString() ? 'soft' : 'plain'} style={{ borderRadius: "0px" }} key={index} sx={tab.archived ? { color: 'dimgray' } : null} onClick={() => {
                                                    gotoTab(tab.id);
                                                }} onDoubleClick={() => {
                                                    if (!allEditorsData.isSpaceIsOwner) return;
                                                    setCurrentlyEditing(true);
                                                    showInputField(tab.id, true);
                                                }}>{tab.name}</Button></Tooltip>
                                            // </DragButton>
                                        ) : null;


                                        return normalButton;
                                    }
                                    )
                                }
                                {!hideAllOptions && hiddenTabsUnique && hiddenTabsUnique.length > 0 ? (
                                    <Dropdown>
                                        <MenuButton
                                            slots={{ root: IconButton }}
                                            slotProps={{ root: { variant: 'plain', color: 'neutral', style: { backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : 'black', zIndex: 101 } } }}
                                        >
                                            <MoreHoriz />
                                        </MenuButton>
                                        <Menu>
                                            {
                                                hiddenTabsUnique.map((tab: any, index: any) => {
                                                    return <MenuItem key={index} onClick={() => {
                                                        gotoTab(tab.id);
                                                    }}>{tab.name}</MenuItem>
                                                })
                                            }
                                        </Menu>
                                    </Dropdown>
                                ) : null}
                                {allEditorsData.isSpaceIsOwner && !hideAllOptions ?
                                    (
                                        <Tooltip title="Add a new tab" size="sm" sx={{ zIndex: 102 }} >
                                            <IconButton onClick={newTab} disabled={allEditorsData.loadingEditorData} style={{ borderRadius: "0px" }} color="neutral" variant="plain"><Add /></IconButton>
                                        </Tooltip>
                                    ) : null}

                            </ButtonGroup>
                            {/* </DndProvider> */}
                            {!hideAllOptions ?
                                (
                                    <div style={{ display: 'flex', flexDirection: 'row', zIndex: 102 }}>
                                        {allEditorsData.isSpaceIsOwner
                                            ? (
                                                <>
                                                    <Tooltip size="sm" title="Save Tab" variant="solid">
                                                        <IconButton className="save-delete-btn" disabled={savingData || allEditorsData.loadingEditorData} onClick={saveAllPanelData} variant="plain" style={{ marginLeft: "80px", borderRadius: "0px" }}>
                                                            <SaveOutlined />
                                                            {allEditorsData.unsavedChanges ? (<div style={{ position: "absolute", top: "0px", height: "8px", width: "8px", backgroundColor: 'maroon', marginRight: "-15px", marginTop: "5px", borderRadius: "4px" }} />) : null}
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Divider orientation="vertical"></Divider>
                                                    <Tooltip size="sm" title="Delete Tab" variant="solid">
                                                        <IconButton disabled={savingData || allEditorsData.loadingEditorData || allEditorsData.currentTabArchived} onClick={deleteTab} variant="plain" style={{ borderRadius: "0px" }}>
                                                            <DeleteOutline />
                                                        </IconButton>
                                                    </Tooltip>

                                                    {isMobile ? null :
                                                        <><Divider orientation="vertical"></Divider>
                                                            <Tooltip size="sm" title="Unpin title bar" variant="solid">
                                                                <IconButton disabled={savingData || allEditorsData.loadingEditorData} onClick={hideNavBar} variant="plain" style={{ borderRadius: "0px" }}>
                                                                    <PushPin sx={{ height: "20px" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Divider orientation="vertical"></Divider>
                                                            <Dropdown>
                                                                <MenuButton
                                                                    slots={{ root: IconButton }}
                                                                    slotProps={{ root: { variant: allEditorsData.currentTabArchived ? 'soft' : 'plain', color: 'neutral', style: { zIndex: 102, borderRadius: "0px" } } }}
                                                                >
                                                                    <MenuOutlined />
                                                                    {allEditorsData.currentTabArchived ? (<div style={{ position: "absolute", top: "0px", height: "8px", width: "8px", backgroundColor: '#0B6BCB', marginRight: "-15px", marginTop: "5px", borderRadius: "4px" }} />) : null}
                                                                </MenuButton>

                                                                <Menu>
                                                                    <MenuItem disabled={allEditorsData.currentTabArchived} onClick={() => {
                                                                        if (tabs.length === 1) {
                                                                            alert("You cannot archive the last tab. Please create a new tab before archiving this one.");
                                                                            return;
                                                                        }

                                                                        if (window.confirm("Are you sure you want to archive this tab?")) {

                                                                            // Call the function to add the tab to the archive
                                                                            addRemoveTabArchive(tid, true);
                                                                        }
                                                                    }}>Add to Archive</MenuItem>
                                                                    <MenuItem onClick={() => setShowArchived(true)}>Browse Archive</MenuItem>
                                                                </Menu>
                                                            </Dropdown>
                                                            <Divider orientation="vertical"></Divider>
                                                            <Tooltip size="sm" title="Share tab" variant="solid">
                                                                <Typography component="label" style={{ marginLeft: "10px" }} fontSize={'14px'} endDecorator={<Switch checked={commonShared} disabled={(user && user.isAnonymous) || allEditorsData.loadingEditorData || allEditorsData.currentTabArchived} sx={{ ml: 1, marginRight: "10px" }} onChange={() => {
                                                                    isMobile ? null :
                                                                        setOpen(true);
                                                                }} />}>
                                                                    Shared
                                                                </Typography>
                                                            </Tooltip>
                                                            <Divider orientation="vertical"></Divider>
                                                            <Tooltip size="sm" title="Export tab" variant="solid">
                                                                <IconButton disabled={savingData || allEditorsData.loadingEditorData || allEditorsData.unsavedChanges} onClick={() => setExportOpen(true)} variant="plain" style={{ borderRadius: "0px" }}>
                                                                    <Publish />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    }


                                                </>
                                            ) : allEditorsData.loadingEditorData ? null : (
                                                <>
                                                    <Tooltip size="sm" title="Read only mode" variant="solid">
                                                        <Typography sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "80px", marginRight: "10px" }}>
                                                            Read only
                                                        </Typography>
                                                    </Tooltip>
                                                    <Divider orientation="vertical"></Divider>
                                                    <Tooltip size="sm" title="Information about the space" variant="solid">
                                                        <IconButton disabled={savingData || allEditorsData.loadingEditorData} onClick={() => setShowInfoOpen(true)} variant="plain" style={{ borderRadius: "0px" }}>
                                                            <InfoOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Divider orientation="vertical"></Divider>
                                                    <Tooltip size="sm" title="Last modified time" variant="solid">
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', marginLeft: "10px", marginRight: "10px" }}>
                                                            <Typography startDecorator={
                                                                <Update sx={{ color: 'gray' }} />
                                                            } sx={{ color: 'gray' }}>{allEditorsData.readonlyLastUpdatedTime ? timeAgo.format(new Date(allEditorsData.readonlyLastUpdatedTime), 'mini') + " ago" : ""}</Typography>
                                                        </div>
                                                    </Tooltip>
                                                </>
                                            )}

                                        <Divider orientation="vertical"></Divider>
                                    </div>
                                ) : null}

                        </>) : null}
                </Box>
                <Suspense>
                    <ActionButtons resetStuff={resetStuffBeforeExplore} />
                </Suspense>
            </Box >
        </>
    );
}

export function ActionButtons({ resetStuff }: { resetStuff: any }): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const authContext = useContext(AuthContext);
    const { user, initializing, signout } = authContext;
    const navigate = useNavigate();
    const gotoExplorer = () => {
        resetStuff();
        navigate('/explorer');
    }

    return (
        <Fragment>
            {user && user.uid ? (
                <Tooltip size="sm" title="Browse/New Spaces" variant="solid">
                    <IconButton disabled={user.isAnonymous} sx={{ zIndex: 102 }} className="explorer-btn" onClick={gotoExplorer} variant="plain">
                        <CreateNewFolder />
                    </IconButton></Tooltip>) : null}


            <ColorSchemeButton variant="plain" size="sm" sx={{ zIndex: 102 }} />

            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral', style: { zIndex: 102 } } }}
                >
                    {allEditorsData.userPrefs && allEditorsData.userPrefs?.photo ? (
                        <Avatar sx={{ height: "23px", width: "23px" }} src={allEditorsData.userPrefs?.photo} />
                    ) : (
                        <AccountCircle />
                    )}
                </MenuButton>
                <Menu>

                    {user && user.uid ? (<MenuItem onClick={() => navigate('/settings')}>Settings</MenuItem>) : null}
                    <MenuItem onClick={() => window.open("https://discord.gg/VYJrsDssuy", '_blank')}>Help (Discord)</MenuItem>
                    <MenuItem onClick={() => window.open("https://zameermfm.medium.com/introducing-thinkpost-io-33df61ce4bc7", '_blank')}>About</MenuItem>

                    {!initializing && user && user.uid ? user.isAnonymous ? (<MenuItem onClick={() => {
                        navigate('/login');
                    }}>Sign Up</MenuItem>) :
                        (<MenuItem onClick={() => {
                            if (allEditorsData.unsavedChanges) {
                                const confirmNavigation = window.confirm("You have unsaved changes. Are you sure you want to logout?");
                                if (!confirmNavigation) {
                                    return;
                                }
                            }
                            signout(() => {
                                navigate('/login');
                            })
                        }}>Sign Out</MenuItem>
                        ) : (
                        <MenuItem onClick={() => {
                            dispatch(setAllTabs([])); //to reset the tabs when anonymous login logs into their own personal account
                            navigate('/login');
                        }}>Sign In</MenuItem>
                    )
                    }
                </Menu>
            </Dropdown>
            {/* )} */}
        </Fragment >
    );
}

export type ToolbarProps = Omit<BoxProps<"header">, "children"> & {
    hideAllOptions?: boolean;
    title?: string;
};