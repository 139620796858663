import { collection, getDocs } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import { db } from "../firebase";

export function keyGen(keyLength: number) {
    var i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    var charactersLength = characters.length;

    for (i = 0; i < keyLength; i++) {
        key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
    }

    return key;
}

export const getEmptyForMode = (mode: string) => {
    return mode === "edit" ? "{}" : mode === "code" ? "{}" : "[]"
}

export const ifArrayOrObjectOrString = (data: any) => {
    if (typeof data === "object") {
        return Array.isArray(data) ? "array" : "object";
    } else if (typeof data === "string") {
        return "string";
    }
}

export const getRightData = (panelData: any) => {
    if (!panelData || !panelData.data) {
        return "{}";
    }

    let data = panelData.data;
    if (ifArrayOrObjectOrString(panelData.data) === "array" && panelData.data.length === 0) {
        data = "[]";
    } else if (ifArrayOrObjectOrString(panelData.data) === "object" && Object.keys(panelData.data).length === 0) {
        data = "{}";
    } else if (ifArrayOrObjectOrString(panelData.data) === "string" && panelData.data.trim() === "") {
        data = "{}";
    }

    // console.log("erroring Data", data);
    return JSON.parse(data);

}

export const firstTabOfWid = async (wid: string) => {
    const querySnapshot = await getDocs(collection(db, "spaces", wid, "tabs"));
    const tabFiltered = querySnapshot.docs.filter((doc) => !(doc.data().archived || doc.data().deleted));
    const firstDoc = tabFiltered.length > 0 ? tabFiltered[0] : null;
    // console.log("First doc", firstDoc?.data().id, firstDoc?.id);
    return firstDoc ? firstDoc?.data().id : 1;
}

export const firstWidNameOfUser = async (userId: string) => {
    // console.log("User ID", userId);
    const querySnapshot = await getDocs(collection(db, "data", userId, "spaces"));
    const firstDoc = querySnapshot.docs.length > 0 ? querySnapshot.docs[0] : null;
    // console.log("First doc", firstDoc);
    return firstDoc ? { data: firstDoc.data() } : null;
}

export const getLastWorkedWid = async (ruser: any) => {
    if (ruser && ruser.uid) {
        try {
            const rworkspace = await getDocs(collection(db, "data", ruser.uid, "userInfo"));

            for (let i = 0; i < rworkspace.docs.length; i++) {
                const doc = rworkspace.docs[i];
                if (doc.data().lastWorked) {
                    return doc.data().lastWorked;
                }
            }

            return null;
        } catch (e) {
            Sentry.captureException(e);
            return null;
            // console.log("Error fetching workspace", e);
        }
    }
}

export const returnOnlyUniqueArray = (arr: any) => {
    return arr.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
}

export const returnOnlyUniqueArrayBasedOnAttribute = (arr: any, attribute: string) => {
    return arr.filter((value: any, index: any, self: any) => self.map((x: any) => x[attribute].toString()).indexOf(value[attribute].toString()) === index);
}