/* SPDX-FileCopyrightText: 2014-present Kriasoft */
/* SPDX-License-Identifier: MIT */

import { Box, GlobalStyles } from "@mui/joy";
import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Toolbar } from "./toolbar";
import { isMobile } from "react-device-detect";
import { MobileToolbar } from "./mobileToolbar";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { SimpleToolbar } from "./simpleToolbar";


/**
 * The main application layout.
 */
export function MainLayout(): JSX.Element {
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);

    return (
        <Fragment>
            <GlobalStyles
                styles={{
                    "#root": {
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gridTemplateRows: "auto 1fr",
                        height: "100dvh",
                    },
                }}
            />
            {isMobile ? (<MobileToolbar sx={{ gridArea: "1 / 1 / 2 / -1" }} />) : <Toolbar hideAllOptions={false} sx={allEditorsData.showNavBar ? { gridArea: "1 / 1 / 2 / -1" } : { display: 'none' }} />}
            {/* <TabPanel value={0}> */}
            <Box sx={allEditorsData.showNavBar ? (isMobile ? { gridArea: "2 / 2 / -1 / -1", pt: "70px" } : { gridArea: "1 / 2 / -1 / -1", pt: "35px" }) : { gridArea: "1 / 2 / -1 / -1" }}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </Box>
            {/* </TabPanel> */}
        </Fragment>
    );
}

export function MainLayoutWithSimpleToolbar({ title }: { title: string }): JSX.Element {
    return (
        <Fragment>
            <GlobalStyles
                styles={{
                    "#root": {
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gridTemplateRows: "auto 1fr",
                        height: "100dvh",
                    },
                }}
            />
            <SimpleToolbar title={title} sx={{ gridArea: "1 / 1 / 2 / -1" }} />
            <Box sx={{ gridArea: "1 / 2 / -1 / -1", pt: "35px" }}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </Box>
            {/* </TabPanel> */}
        </Fragment>
    );
}

export function SubWithLayout(): JSX.Element {
    return (
        <Fragment>
            <GlobalStyles
                styles={{
                    "#root": {
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        gridTemplateRows: "auto 1fr",
                        height: "100dvh",
                    },
                }}
            />
            <Toolbar hideAllOptions={true} sx={{ gridArea: "1 / 2 / 2 / -1" }} />
            {/* <TabPanel value={0}> */}
            <Box sx={{ gridArea: "1 / 2 / -1 / -1", pt: "35px" }}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </Box>
            {/* </TabPanel> */}
        </Fragment>
    );
}

/**
 * The minimal app layout to be used on pages such Login/Signup,
 * Privacy Policy, Terms of Use, etc.
 */
export function BaseLayout(): JSX.Element {
    return (
        <Fragment>
            <GlobalStyles
                styles={{
                    "#root": {
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        minHeight: "100vh",
                    },
                }}
            />

            <Box sx={{ gridArea: "1 / 1 / 2 / 2 " }}>

            </Box>

            <Box sx={{ gridArea: "1 / 1 / -1 / -1", pt: "0px" }}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </Box>
        </Fragment>
    );
}