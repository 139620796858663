import { Add, ChevronLeft, Delete, Edit, LunchDining } from "@mui/icons-material";
import { Button, CardContent, CircularProgress, Container, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, IconButton, Input, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, ModalDialog, Stack, Typography, useColorScheme } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../core/AuthProvider";
import { collection, doc, getDocs, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { createNewSpace } from "./functions/spacemgmt";
import { isMobile } from "react-device-detect";
import Modal from '@mui/joy/Modal';
import { AppDispatch } from "../state/store";
import { useDispatch } from "react-redux";
import { setAllTabs, setAllTabsIncludingDeleted, setAllTabsUnsavedData } from "../state/allEditorsData/allEditorDataSlice";
import * as Sentry from "@sentry/react";
import { firstTabOfWid } from "../utilities/common";

export const Explorer = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showNewSpaceModal, setShowNewSpaceModal] = useState<boolean>(false);
    const [showTitleError, setShowTitleError] = useState<boolean>(false);
    const [initWorkspaceName, setInitWorkspaceName] = useState<string>("");
    const [currentlyEditing, setCurrentlyEditing] = useState<boolean>(false);
    const [overlayLoading, setOverlayLoading] = useState<boolean>(false);
    const [workspaceData, setWorkspaceData] = useState<any[]>([]);
    const [newSpaceName, setNewSpaceName] = useState<string>("");
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const navigate = useNavigate();

    const { mode, systemMode } = useColorScheme();

    const dispatch = useDispatch<AppDispatch>();

    const getAllWorkspaces = async () => {
        if (!user) return;
        setLoading(true);

        // const allspacesLocal = sessionStorage.getItem("allspaces");
        // if (allspacesLocal) {
        //     setWorkspaceData(JSON.parse(allspacesLocal));
        //     setLoading(false);
        //     return;
        // }

        Sentry.addBreadcrumb({
            category: 'Explorer',
            message: 'User ' + user.uid + ' Fetching all workspaces',
            level: 'info'
        });
        const userId = user.uid;
        const querySnapshot = await getDocs(collection(db, "data", userId, "spaces"));
        const workspaceFetchData: any[] = [];

        for (const doc of querySnapshot.docs) {
            const workspaceData = doc.data();
            workspaceData.inputMode = 0;
            workspaceFetchData.push(workspaceData);
        };
        // console.log('workspaceFetchData', workspaceFetchData)
        const recentFirstData = workspaceFetchData.sort((a, b) => b.modified - a.modified);
        sessionStorage.setItem("allspaces", JSON.stringify(recentFirstData));
        setWorkspaceData(recentFirstData);
        // console.log('recentFirstData', recentFirstData)
        // setWorkspaceData([
        //     {
        //         "created": 1717078039572,
        //         "id": "k9qNvPsuq7cm",
        //         "title": "N",
        //         "modified": 1717078039572,
        //         "tabs": [
        //             {
        //                 "id": 1,
        //                 "title": "Tab 1"
        //             },
        //             {
        //                 "title": "Tab 2",
        //                 "no": 2,
        //                 "id": "tab2"
        //             }
        //         ],
        //         "inputMode": 0
        //     },
        //     {
        //         "title": "Viewdocs",
        //         "modified": 1717075600804,
        //         "id": "yOADXhgM0coL",
        //         "created": 1716917836000,
        //         "tabs": [
        //             {
        //                 "id": 1,
        //                 "title": "Tab 1",
        //                 "no": 1
        //             }
        //         ],
        //         "inputMode": 0
        //     },
        //     {
        //         "modified": 1716917836000,
        //         "title": "Spaces App",
        //         "created": 1716917836000,
        //         "id": "cucHn20wLTIq",
        //         "tabs": [
        //             {
        //                 "title": "App Overall",
        //                 "id": 1
        //             },
        //             {
        //                 "id": 2,
        //                 "title": "AI Stuff"
        //             },
        //             {
        //                 "title": "User UX",
        //                 "id": 3
        //             }
        //         ],
        //         "inputMode": 0
        //     }
        // ]);
        setLoading(false);
    }

    useEffect(() => {
        // console.log('user loading', user)
        getAllWorkspaces();


    }, [user]);
    useEffect(() => {
        document.title = "Spaces - ThinkPost";
    }, [])

    const setWorkspaceTitle = (wid: string, title: string) => {
        const userId = user?.uid;
        if (!userId) return;

        setDoc(doc(db, "data", userId, "spaces", wid), {
            title,
            modified: new Date().getTime()
        }, { merge: true });

        const allSpaces = JSON.parse(sessionStorage.getItem("allspaces") || "[]");
        const updatedSpaces = allSpaces.map((space: any) => {
            if (space.wid === wid) {
                space.title = title;
            }
            return space;
        });
        sessionStorage.setItem("allspaces", JSON.stringify(updatedSpaces));
    }

    return (
        <><Modal open={showNewSpaceModal} onClose={() =>
            setShowNewSpaceModal(false)
        }>
            <ModalDialog>
                <DialogTitle>Create new project</DialogTitle>
                <DialogContent>Fill in the information of the project.</DialogContent>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input value={newSpaceName} autoFocus required placeholder="New Space (optional)" onChange={
                            (event: any) => {
                                setNewSpaceName(event.target.value);
                            }
                        } />
                    </FormControl>
                    <Button onClick={async () => {
                        setOverlayLoading(true);
                        setShowNewSpaceModal(false);
                        const { wid, tid } = await createNewSpace(user, newSpaceName, false);
                        setOverlayLoading(false);

                        const allSpaces = JSON.parse(sessionStorage.getItem("allspaces") || "[]");
                        allSpaces.push({ wid: wid, title: newSpaceName, created: new Date().getTime(), modified: new Date().getTime(), createdby: user?.uid, userId: user?.uid });
                        sessionStorage.setItem("allspaces", JSON.stringify(allSpaces));

                        dispatch(setAllTabs([]));

                        sessionStorage.setItem("userId", user?.uid);
                        sessionStorage.setItem("wid", wid);
                        sessionStorage.setItem("tid", "1");

                        navigate('/' + wid + '/' + tid);
                    }}>Start</Button>
                </Stack>
            </ModalDialog>
        </Modal>
            <Container sx={!isMobile ? { py: 2 } : null}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {!isMobile ? <Grid xs={3} /> : null}
                    <Grid xs={isMobile ? 12 : 6}>
                        <CardContent sx={{ "display": "flex", "flexDirection": 'row', "justifyContent": "space-between", "marginTop": isMobile ? "20px" : "0px" }}>
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                <IconButton onClick={() => {
                                    const wid = sessionStorage.getItem("wid");
                                    const tid = sessionStorage.getItem("tid");

                                    // console.log(wid, tid)

                                    if (wid && tid) {
                                        navigate(`/${wid}/${tid}`);
                                    } else {
                                        navigate(`/`);
                                    }
                                }} variant="plain" sx={{ height: "50px", width: "60px" }}>
                                    <ChevronLeft sx={{ height: "40px", width: "40px" }} />
                                </IconButton>
                                <div style={{ marginLeft: "30px" }}>
                                    <Typography level={isMobile ? "h3" : "h1"}>Spaces</Typography>
                                    <Typography>Browse all spaces</Typography>
                                </div>
                            </div>
                            {!loading ? (
                                <div style={{ display: 'flex', width: "100px", alignItems: 'center', justifyContent: 'center' }}>
                                    <IconButton disabled={currentlyEditing} variant="outlined" onClick={async () => {
                                        setShowNewSpaceModal(true);
                                    }
                                    }><Add style={{ height: "40px", width: "40px" }} /></IconButton>
                                </div>) : null}
                        </CardContent>
                        {!loading ? (
                            <>

                                <List
                                    aria-labelledby="ellipsis-list-demo"
                                    sx={{ '--ListItemDecorator-size': '70px', '--List-gap': '20px', 'marginTop': '20px' }}
                                >
                                    {
                                        workspaceData.map((workspace, ind) => {
                                            // console.log('workspace123', workspace)
                                            return (
                                                <ListItem key={ind}>
                                                    <ListItemButton onClick={() => {
                                                        if (currentlyEditing) return;

                                                        dispatch(setAllTabs([]));
                                                        dispatch(setAllTabsUnsavedData({}));
                                                        dispatch(setAllTabsIncludingDeleted([]));


                                                        sessionStorage.setItem("userId", user?.uid);
                                                        sessionStorage.setItem("wid", workspace.wid);
                                                        sessionStorage.setItem("tid", "1");

                                                        Sentry.addBreadcrumb({
                                                            category: 'Explorer',
                                                            message: 'User ' + user?.uid + ' Navigating to workspace ' + workspace.wid,
                                                            level: 'info'
                                                        });

                                                        firstTabOfWid(workspace.wid).then((tabId) => {
                                                            navigate('/' + workspace.wid + '/' + tabId);
                                                        });

                                                    }}>
                                                        <ListItemDecorator>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "50px", width: "50px", borderRadius: "25px", background: '#F0F4F8' }}>
                                                                <LunchDining color="primary" style={{ height: "32px", width: "32px" }} />
                                                            </div>
                                                        </ListItemDecorator>
                                                        <ListItemContent>
                                                            {
                                                                workspace.inputMode === 1 ? (
                                                                    <><Input onFocus={(e) => { e.stopPropagation(); setInitWorkspaceName(workspace.title) }} error={showTitleError} style={{ backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? 'gainsboro' : 'black' }} sx={{ "fontSize": "21px" }} value={workspace.title} placeholder="Space name" variant="plain" onChange={(event: any) => {

                                                                        if (event.target.value.length > 30) {
                                                                            setShowTitleError(true);
                                                                            return;
                                                                        }
                                                                        setShowTitleError(false);
                                                                        setWorkspaceData(workspaceData.map((w, i) => {
                                                                            if (i === ind) {
                                                                                w.title = event.target.value;
                                                                            }
                                                                            return w;
                                                                        }));
                                                                    }} onKeyDown={(event: any) => {
                                                                        if (event.key === 'Enter') {
                                                                            if (event.target.value.trim() === "" || event.target.value.length > 30) {
                                                                                setShowTitleError(true);
                                                                                return;
                                                                            }

                                                                            setShowTitleError(false);
                                                                            setCurrentlyEditing(false);
                                                                            setWorkspaceData(workspaceData.map((w, i) => {
                                                                                if (i === ind) {
                                                                                    w.title = event.target.value;
                                                                                    w.inputMode = 0;
                                                                                    setWorkspaceTitle(workspace.wid, event.target.value);
                                                                                }

                                                                                return w;
                                                                            }));
                                                                        }
                                                                    }} onBlur={(e) => {
                                                                        e.stopPropagation();
                                                                        setShowTitleError(false);
                                                                        setCurrentlyEditing(false);
                                                                        setWorkspaceData(workspaceData.map((w, i) => {
                                                                            if (i === ind) {
                                                                                w.inputMode = 0;
                                                                                w.title = initWorkspaceName;
                                                                            }
                                                                            return w;
                                                                        }));
                                                                    }} />
                                                                        {showTitleError ? <FormHelperText>{"Enter a title under 20 characters"}</FormHelperText> : null}</>
                                                                ) : <Typography level="h4">{workspace.title}</Typography>
                                                            }

                                                            <Typography sx={{ 'marginTop': '5px' }} level="body-sm" noWrap>
                                                                Modified on {new Date(workspace.modified).toLocaleString()}
                                                            </Typography>
                                                        </ListItemContent>
                                                        {!isMobile ?
                                                            <ListItemDecorator>
                                                                <IconButton disabled={currentlyEditing}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setCurrentlyEditing(true);
                                                                        setWorkspaceData(workspaceData.map((w, i) => {
                                                                            if (i === ind) {
                                                                                w.inputMode = 1;
                                                                            }
                                                                            return w;
                                                                        }));
                                                                    }}
                                                                ><Edit /></IconButton>
                                                                <IconButton disabled={currentlyEditing || workspaceData.length === 1} onClick={async (e) => {
                                                                    e.stopPropagation();
                                                                    if (!user) return;

                                                                    const confirmed = window.confirm("Are you sure you want to delete this space? This action cannot be undone.");

                                                                    if (confirmed) {
                                                                        const userId = user.uid;
                                                                        //delete panels
                                                                        setOverlayLoading(true);

                                                                        const batch = writeBatch(db);
                                                                        // console.log("workspace.wid", workspace.wid)
                                                                        const workspaceTabs = (await getDocs(collection(db, "spaces", workspace.wid, "tabs"))).docs.map(doc => doc.data());

                                                                        // console.log("Workspace tabs", workspaceTabs);
                                                                        if (workspaceTabs.length === 0) {
                                                                            alert("Error while deleting workspace. Please try again.");
                                                                            return
                                                                        }

                                                                        for (const tab of workspaceTabs) {
                                                                            const querySnapshot = await getDocs(collection(db, "spaces", workspace.wid, "tabs", tab.id, "panels"));
                                                                            // console.log("Query snapshot", querySnapshot.docs.length);
                                                                            for (const doc of querySnapshot.docs) {
                                                                                // await deleteDoc(doc.ref);
                                                                                batch.delete(doc.ref);
                                                                            }
                                                                        }
                                                                        //delete tabs
                                                                        for (const tab of workspaceTabs) {
                                                                            // await deleteDoc(doc(db, workspace.id, workspace.id, "tabs", tab.id));
                                                                            batch.delete(doc(db, "spaces", workspace.wid, "tabs", tab.id));
                                                                        }
                                                                        //delete workspace
                                                                        // await deleteDoc(doc(db, workspace.id, workspace.id));
                                                                        batch.delete(doc(db, "spaces", workspace.wid));
                                                                        // await deleteDoc(doc(db, "data", userId, "spaces", workspace.id));
                                                                        batch.delete(doc(db, "data", userId, "spaces", workspace.wid));
                                                                        setWorkspaceData(workspaceData.filter((_w, i) => i !== ind));
                                                                        await batch.commit();
                                                                        setOverlayLoading(false);
                                                                    }
                                                                }
                                                                }><Delete /></IconButton>
                                                            </ListItemDecorator>
                                                            : null
                                                        }
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })
                                    }
                                </List>
                                {
                                    overlayLoading ? <div style={{ display: 'flex', position: 'absolute', left: 0, right: 0, top: 0, backgroundColor: 'white', opacity: 0.7, height: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 2 }}><CircularProgress /></div> : null

                                }
                            </>
                        ) : <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}><CircularProgress /></div>}

                    </Grid>
                </Grid>
            </Container ></>);
}
