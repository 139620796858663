export function parseTableToMarkdown(blocks: any) {
    //blocks structure
    // {
    //     "withHeadings": true,
    //     "content" : [ [ "Kine", "Pigs", "Chicken" ], [ "1 pcs", "3 pcs", "12 pcs" ], [ "100$", "200$", "150$" ] ]
    //   }

    const blocksClone = JSON.parse(JSON.stringify(blocks));

    let tableData = '';
    let tableHeadings = blocksClone.content[0].join(' | ');
    let tableContent = '';

    // if (blocks.withHeadings) {
    tableHeadings = `| ${blocksClone.content[0].join(' | ')} |`;
    // tableContent = `|${'-'.repeat(tableHeadings.length - 2)}|\n`;
    tableContent = `|${blocksClone.content[0].map(() => '---').join('|')}|\n`;
    blocksClone.content.shift();
    // }

    tableContent += blocksClone.content.map((row: any) => `| ${row.join(' | ')} |`).join('\n');

    tableData = `${tableHeadings}\n${tableContent}\n`;

    return tableData;
}
