import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface dataState {
    dataBlocks: Array<{ id: any, data: any, mode: string, type: any, tabId: string }>;
    loadingEditorData: boolean;
    wholeEditorLoading: boolean;
    unsavedChanges: boolean;
    showNavBar: boolean;
    allTabs: any;
    currentTabShared: boolean;
    isSpaceIsOwner: boolean;
    currentWorkspaceSmallInfo: any;
    allTabsIncludingDeleted: any;
    codeEditorStyle: any;
    userPrefs: any;
    onNormalPage: boolean;
    cTabexportLayoutOrder: any;
    drawPanelExportTriggerFlag: any;
    drawingUploadingImageStatus: any;
    drawingFileUploadingImageStatus: any;
    currentTabArchived: boolean;
    readonlyLastUpdatedTime: number | null;
    allTabsUnsavedData: any;
    isNewTabOpening: boolean;
    isSpaceIsShared: boolean;
    idMode: number;
    isLoadingTabFinished: boolean; //keep track of loading status of tab from either navigation or page load until panel data is loaded
    allTabsData: any;
}
const initialState: dataState = {
    dataBlocks: [],
    loadingEditorData: true,
    wholeEditorLoading: false,
    unsavedChanges: false,
    showNavBar: true,
    allTabs: [],
    currentTabShared: false,
    isSpaceIsOwner: false,
    currentWorkspaceSmallInfo: {},
    allTabsIncludingDeleted: [],
    codeEditorStyle: "default",
    userPrefs: {},
    onNormalPage: false,
    cTabexportLayoutOrder: [],
    drawPanelExportTriggerFlag: {},
    drawingUploadingImageStatus: [],
    drawingFileUploadingImageStatus: [],
    currentTabArchived: false,
    readonlyLastUpdatedTime: 0,
    allTabsUnsavedData: {},
    isNewTabOpening: false,
    isSpaceIsShared: false,
    idMode: -1,
    isLoadingTabFinished: false,
    allTabsData: {}
};

export const allEditorDataSlice = createSlice({
    name: "allEditorsData",
    initialState,
    reducers: {
        setAllEditorsData: (state, action: PayloadAction<any>) => {
            const availableData = [...state.dataBlocks];
            const index = availableData.findIndex((data) => data.id === action.payload.id);
            if (index !== -1) {
                // availableData[index] = action.payload;
                availableData[index] = {
                    ...availableData[index],
                    ...action.payload
                };
            } else {
                availableData.push(action.payload);
            }

            const tabId = action.payload.tabId;
            if (tabId) {
                const allTabsUnsavedData = { ...state.allTabsUnsavedData };
                allTabsUnsavedData[tabId] = availableData;
                state.allTabsUnsavedData = allTabsUnsavedData;
            }
            state.dataBlocks = availableData;
        },
        setLoadingEditorData: (state, action: PayloadAction<boolean>) => {
            state.loadingEditorData = action.payload;
        },
        replaceAllEditorsData: (state, action: PayloadAction<any>) => {
            state.dataBlocks = action.payload;
        },
        setWholeEditorLoading: (state, action: PayloadAction<boolean>) => {
            state.wholeEditorLoading = action.payload;
        },
        setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload;
        },
        setShowNavBar: (state, action: PayloadAction<boolean>) => {
            state.showNavBar = action.payload;
        },
        setAllTabs: (state, action: PayloadAction<any>) => {
            state.allTabs = action.payload;
        },
        setCurrentTabShared: (state, action: PayloadAction<boolean>) => {
            state.currentTabShared = action.payload;
        },
        setIsSpaceIsOwner: (state, action: PayloadAction<boolean>) => {
            state.isSpaceIsOwner = action.payload;
        },
        setCurrentWorkspaceSmallInfo: (state, action: PayloadAction<any>) => {
            state.currentWorkspaceSmallInfo = action.payload;
        },
        setAllTabsIncludingDeleted: (state, action: PayloadAction<any>) => {
            state.allTabsIncludingDeleted = action.payload;
        },
        setCodeEditorStyle: (state, action: PayloadAction<any>) => {
            state.codeEditorStyle = action.payload;
        },
        setUserPrefs: (state, action: PayloadAction<any>) => {
            state.userPrefs = action.payload;
        },
        setOnNormalPage: (state, action: PayloadAction<boolean>) => {
            state.onNormalPage = action.payload;
        },
        setExportLayoutOrder: (state, action: PayloadAction<any>) => {
            state.cTabexportLayoutOrder = action.payload;
        },
        setDrawPanelExportTriggerFlag: (state, action: PayloadAction<any>) => {
            state.drawPanelExportTriggerFlag = action.payload;
        },
        setDrawingUploadingImageStatus: (state, action: PayloadAction<any>) => {
            const availableData = [...state.drawingUploadingImageStatus];
            const index = availableData.findIndex((data) => data.id === action.payload.id);
            if (index !== -1) {
                availableData[index] = {
                    ...availableData[index],
                    ...action.payload
                };
            } else {
                availableData.push(action.payload);
            }
        },
        setDrawingFileUploadingImageStatus: (state, action: PayloadAction<any>) => {
            const availableData = [...state.drawingFileUploadingImageStatus];
            const index = availableData.findIndex((data) => data.id === action.payload.id);
            if (index !== -1) {
                availableData[index] = {
                    ...availableData[index],
                    ...action.payload
                };
            } else {
                availableData.push(action.payload);
            }
        },
        setCurrentTabArchived: (state, action: PayloadAction<boolean>) => {
            state.currentTabArchived = action.payload;
        },
        setReadonlyLastUpdatedTime: (state, action: PayloadAction<number>) => {
            state.readonlyLastUpdatedTime = action.payload
        },
        setAllTabsUnsavedData: (state, action: PayloadAction<any>) => {
            state.allTabsUnsavedData = action.payload;
        },
        setIsNewTabOpening: (state, action: PayloadAction<boolean>) => {
            state.isNewTabOpening = action.payload;
        },
        setIsSpaceIsShared: (state, action: PayloadAction<boolean>) => {
            state.isSpaceIsShared = action.payload;
        },
        setIdMode: (state, action: PayloadAction<number>) => {
            state.idMode = action.payload;
        },
        setIsLoadingTabFinished: (state, action: PayloadAction<boolean>) => {
            state.isLoadingTabFinished = action.payload;
        },
        setAllTabsData: (state, action: PayloadAction<any>) => {
            // console.log("set all tabs data action.payload", action.payload);
            const allTabsData = { ...state.allTabsData };
            // action.payload structure =  {
            //     tabId: tab.id,
            //     id: doc.id,
            //     data: doc.data()
            // }

            if (action.payload && action.payload.tabId) {
                if (allTabsData[action.payload.tabId] && allTabsData[action.payload.tabId].length > 0) {
                    const index = allTabsData[action.payload.tabId].findIndex((data: any) => data.id === action.payload.id);
                    if (index !== -1) {
                        allTabsData[action.payload.tabId][index] = {
                            ...allTabsData[action.payload.tabId][index],
                            ...action.payload
                        };
                    } else {
                        allTabsData[action.payload.tabId].push(action.payload);
                    }
                } else {
                    allTabsData[action.payload.tabId] = [action.payload];
                }
            }


            // console.log("allTabsData", allTabsData);
            state.allTabsData = allTabsData;
        }
    },
});

export const { setAllEditorsData, setLoadingEditorData, replaceAllEditorsData, setWholeEditorLoading, setUnsavedChanges,
    setShowNavBar, setAllTabs, setCurrentTabShared, setIsSpaceIsOwner, setCurrentWorkspaceSmallInfo, setAllTabsIncludingDeleted, setCodeEditorStyle,
    setUserPrefs, setOnNormalPage, setExportLayoutOrder, setDrawPanelExportTriggerFlag, setDrawingUploadingImageStatus, setDrawingFileUploadingImageStatus,
    setCurrentTabArchived, setReadonlyLastUpdatedTime, setAllTabsUnsavedData, setIsNewTabOpening, setIsSpaceIsShared, setIdMode, setIsLoadingTabFinished,
    setAllTabsData
} = allEditorDataSlice.actions;

export default allEditorDataSlice.reducer;
