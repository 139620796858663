import { Sheet, Typography, useColorScheme } from "@mui/joy";
import { Excalidraw, exportToBlob, useHandleLibrary } from "@excalidraw/excalidraw";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { setAllEditorsData, setDrawingFileUploadingImageStatus, setDrawingUploadingImageStatus, setUnsavedChanges } from "../../state/allEditorsData/allEditorDataSlice"
import lodashIsEqual from "lodash.isequal";
import { AuthContext } from "../../core/AuthProvider";
import ajax from "@codexteam/ajax";
import * as Sentry from "@sentry/react";
import { Blob } from "blob-polyfill";
import { useParams } from "react-router-dom";
import { ExcalidrawImperativeAPI, LibraryItems } from "@excalidraw/excalidraw/types/types";

const LIBRARY_KEY = 'excalidraw-library';

const saveLibrary = (data: unknown) => {
    const json = JSON.stringify(data);
    localStorage.setItem(LIBRARY_KEY, json);
}

const loadLibrary = () => {
    const json = localStorage.getItem(LIBRARY_KEY);
    return json ? JSON.parse(json) : null;
}

const ExcalidrawEditor = forwardRef(({ id, data, readonly, showReference }: { id: string, data?: any, readonly?: boolean, zenModeEnabled?: boolean, showReference?: boolean }, ref: React.Ref<any>) => {
    const { mode, systemMode } = useColorScheme();
    const dispatch = useDispatch<AppDispatch>();
    const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
    const [drawData, setDrawData] = useState<any>(null);
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const envRunning = import.meta.env.PROD;
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const apiUrl = import.meta.env.VITE_ENDPOINT_URL_BASE;
    const tid = useParams<{ tid: string }>().tid || "";
    // console.log('showReference', showReference)

    const getCanvasSize = (sceneElements: any) => {
        // Initialize variables to hold min and max coordinates
        let minX = Infinity, minY = Infinity;
        let maxX = -Infinity, maxY = -Infinity;

        // Iterate through all elements to find bounding box
        sceneElements.forEach((element: any) => {
            // Assuming element has x, y, width, and height properties
            if (element.x < minX) minX = element.x;
            if (element.y < minY) minY = element.y;
            if (element.x + element.width > maxX) maxX = element.x + element.width;
            if (element.y + element.height > maxY) maxY = element.y + element.height;
        });

        // Calculate canvas size
        const canvasWidth = maxX - minX;
        const canvasHeight = maxY - minY;

        return { width: canvasWidth + 100, height: canvasHeight + 100 };
    }

    useEffect(() => {
        if (data && !allEditorsData.isSpaceIsOwner && excalidrawAPI) {
            setDrawData(data);
            excalidrawAPI.updateScene({ elements: data });
        }
    }, [data, allEditorsData.isSpaceIsOwner, excalidrawAPI])

    useEffect(() => {
        // console.log('allEditorsData.drawPanelExportTriggerFlag', allEditorsData.drawPanelExportTriggerFlag, id, allEditorsData.drawPanelExportTriggerFlag[id]);
        if (allEditorsData.drawPanelExportTriggerFlag[id]) {
            if (excalidrawAPI && drawData) {
                dispatch(setDrawingUploadingImageStatus({ id, status: 1 }));
                // console.log('exporting for ', id)
                exportToBlob({
                    elements: drawData,
                    files: null,
                    getDimensions: () => { const size = getCanvasSize(excalidrawAPI.getSceneElements()); return size; }
                }).then((blob: any) => {
                    // console.log('blob for ', id, blob)

                    const excalidrawSchema = {
                        type: "excalidraw",
                        version: 2,
                        source: "thinkpost.io",
                        elements: drawData
                    }

                    const jsonString = JSON.stringify(excalidrawSchema);

                    // Create a Blob object with the JSON string and 
                    // set the content type as "application/json"
                    const fileBlob =
                        new Blob([jsonString], { type: "application/json" });

                    // Blob object representing the JSON data
                    // console.log('fileBlob', fileBlob);

                    uploadFileToServer(fileBlob);
                    uploadImageToServer(blob);
                }).catch((error: any) => {
                    Sentry.captureException(error);
                    dispatch(setDrawingUploadingImageStatus({ id, status: 0 }));
                    dispatch(setDrawingFileUploadingImageStatus({ id, status: 0 }));
                });
            }
        }
    }, [allEditorsData.drawPanelExportTriggerFlag, id, drawData])

    const uploadFileToServer = async (file: any) => {
        if (file) {
            // console.log('uploading file')
            const formData = new FormData();
            formData.append('file', file, 'drawing.excalidraw');

            let idTokenNew = "";
            if (envRunning) {
                idTokenNew = await user.getIdToken();
                localStorage.setItem('userIdno', idTokenNew as string);
            }

            ajax.post({
                url: apiUrl + 'upload-file-post',
                data: formData,
                type: ajax.contentType.JSON,
                headers: envRunning && idTokenNew && {
                    Authorization: idTokenNew
                },
            }).then((response: any) => {
                // console.log('response id', response)
                const newObj = { id, data: drawData, mode: "draw", exportedFilePath: response.body.file.url, tabId: tid };
                const prevObj = allEditorsData.dataBlocks.find((data) => data.id === id);
                dispatch(setAllEditorsData({ ...prevObj, ...newObj }));
                dispatch(setDrawingFileUploadingImageStatus({ id, status: 2, exportedFilePath: response.body.file.url }));
            }).catch((error: any) => {
                // console.log('error', error)
                dispatch(setDrawingFileUploadingImageStatus({ id, status: 0 }));
                Sentry.captureException(error);
            });
        }
    }

    const uploadImageToServer = async (file: any) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            let idTokenNew = "";
            if (envRunning) {
                idTokenNew = await user.getIdToken();
                localStorage.setItem('userIdno', idTokenNew as string);
            }

            ajax.post({
                url: apiUrl + 'image',
                data: formData,
                type: ajax.contentType.JSON,
                headers: envRunning && idTokenNew && {
                    Authorization: idTokenNew
                },
            }).then((response: any) => {
                // console.log('response id', id)
                const newObj = { id, data: drawData, mode: "draw", exportedPath: response.body.file.url, tabId: tid };
                const prevObj = allEditorsData.dataBlocks.find((data) => data.id === id);
                dispatch(setAllEditorsData({ ...prevObj, ...newObj }));
                dispatch(setDrawingUploadingImageStatus({ id, status: 2, exportedPath: response.body.file.url }));
            }).catch((error: any) => {
                dispatch(setDrawingUploadingImageStatus({ id, status: 0 }));
                Sentry.captureException(error);
            });
        }
    }


    useImperativeHandle(ref, () => {
        return {
            scrolToContent() {
                if (excalidrawAPI) {
                    // console.log('scrolling to content')
                    excalidrawAPI.scrollToContent();
                }
            },
            refresh() {
                if (excalidrawAPI) {
                    excalidrawAPI.refresh();
                }
            },
            reset() {
                if (excalidrawAPI) {
                    excalidrawAPI.resetScene();
                    excalidrawAPI.refresh();
                }
            },
            setZenMode(option: boolean) {
                if (excalidrawAPI) {
                    (excalidrawAPI as any).updateScene({ zenModeEnabled: option });
                }
            }
        };
    }, [excalidrawAPI]);


    useEffect(() => {
        let interval = null;
        if (excalidrawAPI) {
            interval = setInterval(() => {
                const allElemts = excalidrawAPI.getSceneElements();
                const allElementsCloned = JSON.parse(JSON.stringify(allElemts));
                if (!lodashIsEqual(allElementsCloned, drawData)) {
                    setDrawData(allElementsCloned);
                }
            }, 2000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [excalidrawAPI, drawData]);

    useEffect(() => {
        if (allEditorsData.isSpaceIsOwner) {
            if (drawData && !lodashIsEqual(data, drawData)) {
                dispatch(setAllEditorsData({ id, data: drawData, mode: "draw", tabId: tid }));
                dispatch(setUnsavedChanges(true));
            }
        }
    }, [drawData, data, allEditorsData.isSpaceIsOwner]);

    useHandleLibrary({ excalidrawAPI });

    useEffect(() => {
        if (!excalidrawAPI) return;
        const libraryItems = loadLibrary();
        if (libraryItems) {
            // console.log('loaded library', libraryItems);
            excalidrawAPI.updateLibrary({ libraryItems });
        }
    }, [excalidrawAPI]);

    const onLibraryChange = (items: LibraryItems) => {
        saveLibrary(items);
    };

    // console.log('showref', showReference)

    return (<><div style={{ width: "100%", height: "100%", paddingTop: "25px" }}><Excalidraw initialData={{
        elements: data
    }} excalidrawAPI={(api: any) => setExcalidrawAPI(api)} isCollaborating={false} viewModeEnabled={readonly} UIOptions={{
        canvasActions: { changeViewBackgroundColor: false, toggleTheme: false, clearCanvas: false },
        tools: { image: false },
        dockedSidebarBreakpoint: 1000
    }} theme={
        mode === "dark" || (systemMode && systemMode === 'dark') ? "dark" : "light"
    } onLibraryChange={onLibraryChange} /></div>
        {showReference &&
            <div style={{ position: "absolute", bottom: "0px", right: "0px", left: "0px", top: '0px', padding: "40px", zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="glassbg">
                <Sheet variant="outlined" color="neutral" sx={{ display: "flex", flexDirection: 'column', height: "500px", padding: "20px" }}>
                    <Typography level={'h2'} sx={{ fontWeight: "bold" }}>Tips</Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginTop: "20px", flexWrap: 'wrap' }}>
                            <Typography level="body-sm">You can turn on the Zen mode (Basic editor):</Typography>
                            <Typography level="title-md">Right click on the drawing pad and select zen mode</Typography>
                            <Typography level="body-sm" sx={{ marginTop: "20px" }}>You can import/export any excalidraw diagrams:</Typography>
                            <Typography level="title-md">Click on the menu on the left and you will find the options on the dropdown.</Typography>
                            <Typography level="body-sm" sx={{ marginTop: "20px" }}>You can import any excalidraw libraries:</Typography>
                            <Typography level="title-md">To access the library from their catalogue, Download the library first. Please note that the 'Library' option
                                is only available for wider panels. In full screen drawing mode, Navigate to the top right corner and click on 'Library'.
                                In the side panel that appears, select 'Open' from the menu options to import your file. (Adding library through the app is not functional yet)</Typography>
                        </div>
                    </div>
                </Sheet>
            </div>
        }
    </>
    );
});

export default ExcalidrawEditor;