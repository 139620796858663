import { parseAttachFilesToMarkdown } from "./BlockTypeParsers/AttachFileTypeParser";
import { parseCheckboxToMarkdown } from "./BlockTypeParsers/CheckboxTypeParser";
import { parseCodeToMarkdown } from "./BlockTypeParsers/CodeTypeParser";
import { parseHeaderToMarkdown } from "./BlockTypeParsers/HeaderTypeParser";
import { parseImageToMarkdown } from "./BlockTypeParsers/ImageTypeParser";
import { parseLinksToMarkdown } from "./BlockTypeParsers/LinkFileTypeParser";
import { parseListToMarkdown } from "./BlockTypeParsers/ListTypeParser";
import { parseMathToMarkdown } from "./BlockTypeParsers/MathTypeParser";
import { parseParagraphToMarkdown } from "./BlockTypeParsers/ParagraphTypeParser";
import { parseQuoteToMarkdown } from "./BlockTypeParsers/QuoteTypeParser";
import { parseTableToMarkdown } from "./BlockTypeParsers/TableTypeParser";

export const mainParser = (data: any) => {
    const parsedData = data.map((item: any) => {
        // iterate through editor data and parse the single blocks to markdown syntax
        switch (item.type) {
            case 'header':
                return parseHeaderToMarkdown(item.data);
            case 'myparagraph':
                return parseParagraphToMarkdown(item.data);
            case 'NestedList':
                return parseListToMarkdown(item.data);
            case 'image':
                return parseImageToMarkdown(item.data);
            case 'quote':
                return parseQuoteToMarkdown(item.data);
            case 'checkbox':
                return parseCheckboxToMarkdown(item.data);
            case 'code':
                return parseCodeToMarkdown(item.data);
            case 'checklist':
                return parseCheckboxToMarkdown(item.data);
            case 'attaches':
                return parseAttachFilesToMarkdown(item.data);
            case 'linkTool':
                return parseLinksToMarkdown(item.data);
            case 'table':
                return parseTableToMarkdown(item.data);
            case 'math':
                return parseMathToMarkdown(item.data);
            default:
                break;
        }
    });
    return parsedData;

}