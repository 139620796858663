import { DialogContent, DialogTitle, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Person, Update } from "@mui/icons-material";
import { format } from 'date-fns';

const ShowTabInfoPanel = ({ tabInfo, showPanel, setShowPanel }: { tabInfo: any, showPanel: boolean, setShowPanel: any }) => {
    const [tabData, setTabData] = useState<any>({});
    const [tabAuthorInfo, setTabAuthorInfo] = useState<any>({});

    useEffect(() => {
        if (tabInfo) {
            setTabData(tabInfo);
            getUserInfoOfTabAuthor(tabInfo);
        }
    }, [tabInfo]);

    const getUserInfoOfTabAuthor = async (data: any) => {
        const authorId = data?.createdby;
        if (authorId) {
            // API call to get user info
            // setTabAuthorInfo(response);
            const authInfoDocs = await getDocs(collection(db, "data", authorId, "userInfo"));
            if (authInfoDocs.docs.length > 0) {
                const authInfo = authInfoDocs.docs.find(doc => doc.id === authorId);
                if (authInfo) {
                    setTabAuthorInfo(authInfo.data());
                }
            }

        }
    }

    return (
        <Modal
            aria-labelledby="show Tab Info"
            aria-describedby="showing tab information"
            open={showPanel}
            onClose={() => setShowPanel(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <ModalDialog>
                <DialogTitle>Tab information</DialogTitle>
                <ModalClose variant="plain" sx={{ m: 1 }} />

                <div style={{ width: "100%", height: "100px", marginTop: "50px" }}>
                    <DialogContent>
                        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <Person />
                            <Typography level="body-md" sx={{ marginLeft: "10px" }}>{tabAuthorInfo ? (tabAuthorInfo.name ? tabAuthorInfo.name + " (" + tabAuthorInfo.email + ")" : tabAuthorInfo.email) : ""}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                            <Update />
                            <Typography level="body-md" sx={{ marginLeft: "10px" }}>{"Last updated: " + (tabData && tabData.modified ? format(new Date(tabData.modified), "yyyy-MM-dd HH:mm:ss.SSSxxx") : "")}</Typography>
                        </div>
                    </DialogContent>
                </div>
            </ModalDialog>
        </Modal>
    );
}

export default ShowTabInfoPanel;