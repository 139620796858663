import { PeopleOutlineOutlined, SettingsBackupRestoreOutlined } from "@mui/icons-material";
import { Button, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, Modal, ModalClose, ModalDialog, Tooltip } from "@mui/joy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { returnOnlyUniqueArrayBasedOnAttribute } from "../../utilities/common";

const ArchivedTabsModal = ({ allTabsInPages, modOpen, setModOpen, restoreTab, loadTabFromArchive }: { allTabsInPages: any, modOpen: boolean, setModOpen: any, restoreTab: any, loadTabFromArchive: any }) => {
    const tid = useParams<{ tid: string }>().tid || "";

    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        if (allTabsInPages) {
            // console.log('allTabsInPages refreshed', allTabsInPages);
            let allTabs: any = [];
            allTabsInPages.forEach((tab: any) => {
                if (tab.archived && !tab.deleted) {
                    allTabs.push(tab);
                }
            });
            setTabs(allTabs);

        }
    }, [allTabsInPages])

    const tabsUnique = returnOnlyUniqueArrayBasedOnAttribute(tabs, "id");

    return (
        <Modal
            aria-labelledby="archivedtabs"
            aria-describedby="archived tabs"
            open={modOpen}
            onClose={() => setModOpen(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <ModalDialog sx={{ width: "400px" }}>
                <DialogTitle>Archived Tabs</DialogTitle>
                <DialogContent>You may continue working with these tabs, but sharing and deleting are restricted. Tabs can be shared/deleted after restoring it.</DialogContent>
                <ModalClose variant="plain" sx={{ m: 1 }} />
                <div style={{ height: "250px", width: "360px", display: 'flex', overflowY: 'scroll' }}>
                    <List>
                        {
                            tabsUnique.map((tab: any, index: any) => {
                                return (
                                    <ListItem variant={tab.id.toString() === tid.toString() ? "soft" : "plain"} color="primary" key={tab.name + index}
                                        endAction={
                                            <>
                                                {tab.public ? (
                                                    <Tooltip title="Shared" size="sm" sx={{ zIndex: 102 }} >
                                                        <IconButton disabled={true} aria-label="Shared" size="sm" color="primary">
                                                            <PeopleOutlineOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : null}

                                                <Tooltip title="Restore Tab" size="sm" sx={{ zIndex: 102 }} >
                                                    <IconButton onClick={() => {
                                                        setModOpen(false);
                                                        restoreTab(tab.id);

                                                        //this should be taken care by refreshing alltabsinpages
                                                        // const tabsClones = [...tabs];
                                                        // const index = tabsClones.findIndex((tabClone: any) => tabClone.id === tab.id);
                                                        // tabsClones.splice(index, 1);
                                                        // setTabs(tabsClones);

                                                    }} aria-label="Restore Tab" size="sm" color="primary">
                                                        <SettingsBackupRestoreOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemButton onClick={() => {
                                            setModOpen(false);
                                            loadTabFromArchive(tab);
                                        }}>{tab.name}</ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </div>
                <Button onClick={() => setModOpen(false)}>Close</Button>

            </ModalDialog>
        </Modal>
    );
}

export default ArchivedTabsModal;