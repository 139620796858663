import { Sheet, Typography, useColorScheme } from "@mui/joy";
import { createRef, ForwardedRef, forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../state/store";
import { setAllEditorsData, setUnsavedChanges } from "../../../state/allEditorsData/allEditorDataSlice"
// import debounce from "lodash.debounce";
import { LibsContext } from "../../../core/LibsProvider";
// import { ifArrayOrObjectOrString } from "../../utilities/common";
import isEqual from "lodash.isequal";
import { useParams } from "react-router-dom";


const MMathSheet = forwardRef(({ id, showRefs, data, readonly, onEdChange }: { id: string, showRefs: boolean, data: any, readonly?: boolean, type?: any, onEdChange?: any }, ref: ForwardedRef<any>) => {

    useImperativeHandle(ref, () => {
        return {
            reset: () => {
                setRowHeights([]);
                setRowRefs([]);
                // setVariables({});
                setTimeout(() => {
                    addNewRow();
                }, 300);

            },
        };
    }, []);


    const dispatch = useDispatch<AppDispatch>();
    const [rowHeights, setRowHeights] = useState<any>([]);
    const [rowRefs, setRowRefs] = useState<any>([]);
    const [showReference, setShowReference] = useState<boolean>(false);
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [dataLoadedForVariables, setDataLoadedForVariables] = useState<boolean>(false);
    const { math } = useContext(LibsContext);
    const tid = useParams<{ tid: string }>().tid || "";
    // const [variables, setVariables] = useState<any>({});
    // const [variablesAndRows, setVariablesAndRows] = useState<any>({});
    const { mode, systemMode } = useColorScheme();
    // const [textChanged, setTextChanged] = useState<boolean>(false);



    useEffect(() => {
        if (data && !allEditorsData.isSpaceIsOwner) {
            setRowHeights(data);
        }
    }, [data, allEditorsData.isSpaceIsOwner])

    useEffect(() => {
        if (!dataLoaded && rowHeights && rowHeights.length >= 0) {
            // console.log("dataLoaded", dataLoaded);
            setDataLoaded(true);
            setDataLoadedForVariables(true);
        }
    }, [rowHeights, dataLoaded])

    const addNewRow = () => {
        setRowHeights((prev: any) => {
            return [...prev, { id: rowHeights.length, expression: "", height: 1, text: "" }];
        });
        setRowRefs((prev: any) => {
            return [...prev, createRef()];
        });
    }

    useEffect(() => {
        if (!dataLoaded) return;
        if (isEqual(rowHeights, data)) return;

        const rowHeightFilteredWithExpression = rowHeights.filter((obj: any) => obj.expression.trim() !== "");
        const rowHeightsCloned = JSON.parse(JSON.stringify(rowHeightFilteredWithExpression));

        // console.log("setAllEditorsData", rowHeightsCloned);
        onEdChange && onEdChange(rowHeightsCloned);
        dispatch(setAllEditorsData({
            id, data: rowHeightsCloned, mode: "math", tabId: tid

            // type: {
            //     userVars: variables
            // }
        }));
        dispatch(setUnsavedChanges(true));
    }, [rowHeights, dataLoaded, data])

    useEffect(() => {
        if (rowHeights.length === 0) addNewRow();

    }, []);

    useEffect(() => {
        setShowReference(showRefs);
    }, [showRefs]);

    // const debouncedSave = debounce(() => {
    //     const rowHeightFilteredWithExpression = rowHeights.filter((obj: any) => obj.expression.trim() !== "");
    //     const rowHeightsCloned = JSON.parse(JSON.stringify(rowHeightFilteredWithExpression));
    //     setTextChanged(false);
    //     dispatch(setAllEditorsData({ id, data: rowHeightsCloned, mode: "math" }));
    //     dispatch(setUnsavedChanges(true));
    // }, 1000);

    useEffect(() => {
        if (data.length > 0 && !dataLoadedForVariables) {
            setRowHeights(JSON.parse(JSON.stringify(data)));

            // if (type && type.userVars) {
            //     setVariables(type.userVars);
            // }

            for (let i = 0; i < data.length; i++) {
                setRowRefs((prev: any) => {
                    return [...prev, createRef()];
                });
            }
        }
    }, [data]);

    // useEffect(() => {
    //     if (textChanged && rowHeights.length > 0) {
    //         debouncedSave();
    //     }
    //     return () => {
    //         debouncedSave.cancel();
    //     };
    // }, [textChanged]);

    // useEffect(() => {
    //     console.log("variablesAndRows", variablesAndRows);
    // }, [variablesAndRows])


    return (
        <>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                }}
            >
                <div style={{ display: "flex", flex: 0.6 }}></div>
                <div
                    style={{
                        display: "flex",
                        flex: 0.4,
                        backgroundColor: mode === "light" || (mode === "system" && systemMode === "light") ? "whitesmoke" : "#1E1E1E",
                    }}
                ></div>
                <div
                    style={{
                        position: "absolute",
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        paddingTop: "30px",
                        flexDirection: "column",
                        overflowY: "scroll",
                        paddingBottom: "300px"
                    }}
                >
                    {rowHeights.map((obj: any, index: any) => (
                        <div key={index} style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                            <div
                                style={{
                                    display: "flex",
                                    flex: 0.6,
                                    border: "solid #E0E0E0",
                                    borderWidth: "0px 0px 1px 0px",
                                    height: obj.height + 10 + "px",

                                }}
                            >
                                <TextareaAutosize
                                    disabled={readonly}
                                    placeholder={(!allEditorsData.isSpaceIsOwner) ? "" : "Enter math expression here, press enter to add a new row..."}
                                    ref={rowRefs[index]}
                                    onHeightChange={(h) => {
                                        setRowHeights((prev: any) => {
                                            prev[index].height = h;
                                            return [...prev];
                                        });
                                    }}
                                    value={obj.expression}
                                    minRows={1}
                                    style={{
                                        paddingTop: "10px",
                                        paddingLeft: "30px",
                                        paddingRight: "10px",
                                        width: "100%",
                                        outline: "none",
                                        border: "none",
                                        resize: "none",
                                        fontFamily: "inherit",
                                        color: mode === "light" || (mode === "system" && systemMode === "light") ? '#32383E' : 'gainsboro',
                                    }}
                                    onChange={(e) => {
                                        if (!allEditorsData.isSpaceIsOwner) return;
                                        let evalValue = "";
                                        try {
                                            const textVal = e.target.value;
                                            //     let expression = textVal
                                            //     if (/^[a-zA-Z]+\s*=\s*-?\d+(\.\d+)?(?:\s*,\s*[a-zA-Z]+\s*=\s*-?\d+(\.\d+)?)*$/.test(textVal)) {
                                            //         // It is a variable assignment expression

                                            //         // console.log("textVal.split('=')", textVal.split('='));
                                            //         const [variable, value] = textVal.split('=').map((x) => x.trim());

                                            //         const newObj = { [variable]: value };
                                            //         setVariables((prev: any) => {
                                            //             return { ...prev, ...newObj };
                                            //         });



                                            //         // console.log("variable", newObj);

                                            //         // expression = textVal.replace(/[^0-9+\-*/()^%.]/g, '');
                                            //         evalValue = math?.evaluate(expression);
                                            //         // Store the variable and its value for later use
                                            //         // Example: variables[variable] = value;
                                            //     } else {
                                            //         // console.log('variables s', variables, textVal)
                                            //         // It is not a variable assignment expression
                                            //         if (Object.keys(variables).length > 0) {
                                            //             // console.log("proccess", Object.keys(variables).map((key) => {
                                            //             //     return textVal.replace(new RegExp(key, 'g'), variables[key]);
                                            //             // }));
                                            //             // expression = Object.keys(variables).map((key) => {
                                            //             //     return textVal.replace(new RegExp(key, 'g'), variables[key]);
                                            //             // })[0];
                                            //             expression = Object.keys(variables).reduce((acc, key) => {
                                            //                 // const variable = key;
                                            //                 // const indexesOfThisVariables = variablesAndRows[variable] || [];

                                            //                 // if (indexesOfThisVariables.length >= 0 && !indexesOfThisVariables.includes(index)) {
                                            //                 //     setVariablesAndRows((prev: any) => {
                                            //                 //         const newObj = { [variable]: [...indexesOfThisVariables, index] };
                                            //                 //         return { ...prev, ...newObj };
                                            //                 //     });
                                            //                 // }


                                            //                 return acc.replace(new RegExp(key, 'g'), variables[key]);
                                            //             }, textVal);
                                            //         }
                                            //         // console.log("expression before", expression);
                                            //         expression = expression.replace(/[^0-9+\-*/()^%.]/g, '');
                                            //         // console.log("expression", expression);
                                            //         evalValue = math?.evaluate(expression);
                                            //         // Evaluate the expression using math.evaluate()
                                            //         // Example: evalValue = math?.evaluate(expression);
                                            //     }
                                            const removeSpaces = textVal.replace(/\s/g, '');
                                            // const expression = removeSpaces.replace(/[^0-9+\-*/()^%.]/g, '');
                                            const expression = removeSpaces.replace(/[^0-9+\-*/()^%.]|(?<=[a-zA-Z])\d+(?=[a-zA-Z])/g, '');
                                            evalValue = math?.evaluate(expression);
                                        } catch (error) {
                                            //     // console.log("Error in math expression", error);
                                        }

                                        const rowHeightsCloned = JSON.parse(JSON.stringify(rowHeights));
                                        rowHeightsCloned[index].text = evalValue;
                                        rowHeightsCloned[index].expression = e.target.value;
                                        setRowHeights(rowHeightsCloned);



                                        // setTextChanged(true);
                                    }}
                                    autoFocus={index === rowHeights.length - 1}
                                    onKeyDown={(e) => {
                                        if (!allEditorsData.isSpaceIsOwner) return;
                                        if (e.key === "Enter") {
                                            addNewRow();
                                            e.preventDefault();
                                        } else if (e.key === "ArrowUp") {
                                            const currentIndex = index;
                                            if (currentIndex > 0) {
                                                const previousTextarea = rowRefs[currentIndex - 1]?.current;
                                                if (previousTextarea) {
                                                    previousTextarea.focus();
                                                }
                                            }
                                        } else if (e.key === "Backspace" || e.key === "Delete") {
                                            const currentTextarea = rowRefs[index].current;
                                            // console.log(currentTextarea, currentTextarea?.value)                                            
                                            if (currentTextarea && currentTextarea.value === "") {
                                                const currentIndex = index;

                                                if (rowHeights.length === 1) return;

                                                const rowHeightsCloned = JSON.parse(JSON.stringify(rowHeights));
                                                rowHeightsCloned.splice(currentIndex, 1);
                                                setRowHeights(rowHeightsCloned);
                                                const previousTextarea = rowRefs[currentIndex - 1]?.current;
                                                if (previousTextarea) {
                                                    previousTextarea.focus();
                                                    //move the cursor to last character of the text
                                                    previousTextarea.setSelectionRange(previousTextarea.value.length, previousTextarea.value.length);
                                                    e.preventDefault();
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flex: 0.4,
                                    height: obj.height,
                                    alignItems: "center",
                                }}
                            >
                                <div style={{ paddingLeft: "25px", paddingTop: "10px", }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>{
                                        obj.text
                                    }</Typography>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showReference &&
                <div style={{ position: "absolute", bottom: "0px", right: "0px", left: "0px", top: '0px', padding: "40px", zIndex: 9, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="glassbg">
                    <Sheet variant="outlined" color="neutral" sx={{ display: "flex", flexDirection: 'column', height: "400px", padding: "20px", }}>
                        <Typography level={'h2'} sx={{ fontWeight: "bold" }}>Reference</Typography>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginTop: "20px", flexWrap: 'wrap' }}>
                                <Typography level="body-sm">Labels can be included in your calculations. For instance, you can use expressions like:</Typography>
                                <Typography level="title-md">Oranges 45 + Apples 50 (95)</Typography>
                                <Typography level="body-sm" sx={{ marginTop: "20px" }}>However, please note that labels cannot contain hyphens, but minus symbol is valid. For example, the expression:</Typography>
                                <Typography level="title-md">Oranges-45 + Apples-50 (is not valid), Oranges -45 + Apples -50 (valid)</Typography>
                                <Typography level="body-sm" sx={{ marginTop: "20px" }}>Any mathematical expressions:</Typography>
                                <Typography level="title-md">(2 + 3) * 4</Typography>
                                {/* <Typography level="title-md">(2 + 3) * 4, Variables can be assigned to be used later, a = 3.4, b = 5 / 2, a * b</Typography> */}
                            </div>
                        </div>
                    </Sheet>
                </div>
            }
        </>
    );
});

export default MMathSheet;