import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface mnDataState {
    currentPanel: string;
}
const initialState: mnDataState = {
    currentPanel: "hpanel_0_0",
};

export const mobileNavDataDataSlice = createSlice({
    name: "mobileNavData",
    initialState,
    reducers: {
        setCurrentPanel: (state, action: PayloadAction<string>) => {
            state.currentPanel = action.payload;
        },
    },
});

export const { setCurrentPanel } = mobileNavDataDataSlice.actions;

export default mobileNavDataDataSlice.reducer;
