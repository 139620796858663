import EditorComponent from "./Editor";
import React, { createRef, useContext, useEffect, useRef } from 'react';
import { Panel, PanelGroup, PanelResizeHandle, ImperativePanelHandle } from "react-resizable-panels";
import IconButton from '@mui/joy/IconButton';
import { Add, AutoAwesome, DisplaySettings, Fullscreen, FullscreenExit, Help, PushPin, Remove } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, writeBatch } from "firebase/firestore";
import { AuthContext } from "../../core/AuthProvider";
import { Dropdown, Menu, MenuButton, MenuItem, Tooltip, useColorScheme } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { setLoadingEditorData, replaceAllEditorsData, setShowNavBar, setCodeEditorStyle, setIsNewTabOpening, setWholeEditorLoading } from "../../state/allEditorsData/allEditorDataSlice"
import ExcalidrawEditor from "./ExcaliDraw";
import MathSheet from "./MathSheet";
import CodeEditor from "./CodeEditor";
import Joyride, { STATUS } from "react-joyride";
import * as Sentry from "@sentry/react";
import { getEmptyForMode, getRightData } from "../../utilities/common";

export type PanelGroupStorage = {
    getItem(name: string): string | null;
    setItem(name: string, value: string): void;
};


const steps = [
    {
        target: "#main_settings_btn",
        content: "You can change the editor type here",
    },
    {
        target: ".ce-block",
        content: "You can type \"/\" to see all the available blocks",
    },
    {
        target: ".panel-open-btn",
        content: "You can open the panels here",
    },
    {
        target: ".save-delete-btn",
        content: "You can find save and delete tab options here",
    },
    {
        target: ".explorer-btn",
        content: "You can Add/Browse your spaces by clicking here, you need to sign in to access this feature",
    }
];


const Panels = ({ panelData, idMode }: { panelData: any, idMode: number }) => {
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        // console.log('idMode', idMode)
        if (idMode === 0) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 1) {


            // console.log('setting mode 1')
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "draw" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("draw"), mode: "draw" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )

        } else if (idMode === 2) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "draw" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "draw" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("draw"), mode: "draw" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("draw"), mode: "draw" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 3) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "math" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("math"), mode: "math" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 4) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "code" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("code"), mode: "code" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 5) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "code" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "code" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(99);
            panelHPanel11Ref?.current?.resize(0);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "code" },
                    { id: "hpanel_0_1", data: getEmptyForMode("code"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("edit"), mode: "code" },
                    { id: "hpanel_1_1", data: getEmptyForMode("code"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 99 },
                    { id: "hpanel_1_1", size: 0 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 6) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "draw" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "math" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(50);
            panelHPanel11Ref?.current?.resize(50);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("draw"), mode: "draw" },
                    { id: "hpanel_1_1", data: getEmptyForMode("math"), mode: "math" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 99 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 50 },
                    { id: "hpanel_1_1", size: 50 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 7) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 99, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 0, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "code" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "math" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(99);
            panelHPanel01Ref?.current?.resize(0);
            panelHPanel10Ref?.current?.resize(50);
            panelHPanel11Ref?.current?.resize(50);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("code"), mode: "code" },
                    { id: "hpanel_1_1", data: getEmptyForMode("math"), mode: "math" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 50 },
                    { id: "hpanel_0_1", size: 0 },
                    { id: "hpanel_1_0", size: 50 },
                    { id: "hpanel_1_1", size: 50 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 8) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            // savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(50);
            panelHPanel01Ref?.current?.resize(50);
            panelHPanel10Ref?.current?.resize(50);
            panelHPanel11Ref?.current?.resize(50);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_1", data: getEmptyForMode("edit"), mode: "edit" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 50 },
                    { id: "hpanel_0_1", size: 50 },
                    { id: "hpanel_1_0", size: 50 },
                    { id: "hpanel_1_1", size: 50 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )
        } else if (idMode === 9) {
            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "edit" }));
            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: 50, mode: "math" }));

            setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: 50 }));
            setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: 50 }));

            savePanelData(false);

            panelVPanel00Ref?.current?.resize(50);
            panelVPanel10Ref?.current?.resize(50);

            panelHPanel00Ref?.current?.resize(50);
            panelHPanel01Ref?.current?.resize(50);
            panelHPanel10Ref?.current?.resize(50);
            panelHPanel11Ref?.current?.resize(50);

            saveModeArrayData(
                [
                    { id: "hpanel_0_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_0_1", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_0", data: getEmptyForMode("edit"), mode: "edit" },
                    { id: "hpanel_1_1", data: getEmptyForMode("math"), mode: "math" },
                ]
            )

            savePanelDataDirectly(
                [
                    { id: "hpanel_0_0", size: 50 },
                    { id: "hpanel_0_1", size: 50 },
                    { id: "hpanel_1_0", size: 50 },
                    { id: "hpanel_1_1", size: 50 },
                    { id: "vpanel_0_0", size: 50 },
                    { id: "vpanel_1_0", size: 50 },
                ]
            )

        }

        if (idMode === 0 || idMode === 1 || idMode === 2 || idMode === 3 || idMode === 4 || idMode === 5 || idMode === 6 || idMode === 7 || idMode === 9) {
            dispatch(setIsNewTabOpening(false));
            dispatch(setWholeEditorLoading(false));
        }

    }, [idMode])

    const { mode, systemMode } = useColorScheme();

    const [loading, setLoading] = React.useState<boolean>(true);
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";

    const [panelVPanel00, setPanelVPanel00] = React.useState<any>({ id: "vpanel_0_0", size: 50, data: {}, mode: '', type: {} });
    const [panelVPanel10, setPanelVPanel10] = React.useState<any>({ id: "vpanel_1_0", size: 50, data: {}, mode: '', type: {} });
    // const [panelVPanel20, setPanelVPanel20] = React.useState<any>({ id: "vpanel_2_0", size: 0.1, data: {}, mode: '', type: {} });
    const [panelHPanel00, setPanelHPanel00] = React.useState<any>({ id: "hpanel_0_0", size: 99, data: {}, mode: "edit", type: {} });
    const [panelHPanel01, setPanelHPanel01] = React.useState<any>({ id: "hpanel_0_1", size: 0, data: {}, mode: '', type: {} });
    const [panelHPanel10, setPanelHPanel10] = React.useState<any>({ id: "hpanel_1_0", size: 99, data: {}, mode: "edit", type: {} });
    const [panelHPanel11, setPanelHPanel11] = React.useState<any>({ id: "hpanel_1_1", size: 0.1, data: {}, mode: '', type: {} });
    // const [panelHPanel20, setPanelHPanel20] = React.useState<any>({ id: "hpanel_2_0", size: 30, data: {}, mode: "edit", type: {} });
    // const [panelHPanel21, setPanelHPanel21] = React.useState<any>({ id: "hpanel_2_1", size: 0.1, data: {}, mode: '', type: {} });


    const [panelH01Size, setPanelH01Size] = React.useState<number>(0.1);
    const [panelH11Size, setPanelH11Size] = React.useState<number>(0.1);
    // const [panelH21Size, setPanelH21Size] = React.useState<number>(0.1);

    const [panelV00Size, setPanelV00Size] = React.useState<number>(50);
    const [panelV10Size, setPanelV10Size] = React.useState<number>(50);
    // const [panelV20Size, setPanelV20Size] = React.useState<number>(0.1);

    const panelVPanel00Ref = createRef<ImperativePanelHandle>();
    const panelVPanel10Ref = createRef<ImperativePanelHandle>();
    const panelVPanel20Ref = createRef<ImperativePanelHandle>();
    const panelHPanel00Ref = createRef<ImperativePanelHandle>();
    const panelHPanel01Ref = createRef<ImperativePanelHandle>();
    const panelHPanel10Ref = createRef<ImperativePanelHandle>();
    const panelHPanel11Ref = createRef<ImperativePanelHandle>();
    // const panelHPanel20Ref = createRef<ImperativePanelHandle>();
    // const panelHPanel21Ref = createRef<ImperativePanelHandle>();

    const drawHPanel00Ref = createRef<any>();
    const drawHPanel10Ref = createRef<any>();

    const codeHPanel00Ref = useRef<any>(null);
    const codeHPanel10Ref = useRef<any>(null);
    const codeHPanel01Ref = useRef<any>(null);
    const codeHPanel11Ref = useRef<any>(null);

    const mathHPanel00Ref = useRef<any>(null);
    const mathHPanel10Ref = useRef<any>(null);
    const mathHPanel01Ref = useRef<any>(null);
    const mathHPanel11Ref = useRef<any>(null);

    const editorHPanel00Ref = useRef<any>(null);
    const editorHPanel10Ref = useRef<any>(null);
    const editorHPanel01Ref = useRef<any>(null);
    const editorHPanel11Ref = useRef<any>(null);


    // const drawHPanel20Ref = createRef<any>();

    useEffect(() => {
        if (panelHPanel01Ref.current) {
            setPanelH01Size(panelHPanel01Ref.current.getSize());
        }
    }, [panelHPanel01Ref])

    useEffect(() => {
        if (panelHPanel11Ref.current) {
            setPanelH11Size(panelHPanel11Ref.current.getSize());
        }
    }, [panelHPanel11Ref])

    // useEffect(() => {
    //     if (panelHPanel21Ref.current) {
    //         setPanelH21Size(panelHPanel21Ref.current.getSize());
    //     }
    // }, [panelHPanel21Ref])

    useEffect(() => {
        if (panelVPanel10Ref.current) {
            setPanelV10Size(panelVPanel10Ref.current.getSize());
        }
    }, [panelVPanel10Ref])

    useEffect(() => {
        if (panelVPanel00Ref.current) {
            setPanelV00Size(panelVPanel00Ref.current.getSize());
        }
    }, [panelVPanel00Ref])

    // useEffect(() => {
    //     if (panelVPanel20Ref.current) {
    //         setPanelV20Size(panelVPanel20Ref.current.getSize());
    //     }
    // }, [panelVPanel20Ref])


    useEffect(() => {

        if (panelData.length === 0 && idMode === -1) {
            // console.log("resetting all data");
            //empty all the states
            setPanelVPanel00({ id: "vpanel_0_0", data: {}, mode: '', size: 50, type: {} });
            setPanelVPanel10({ id: "vpanel_1_0", data: {}, mode: '', size: 50, type: {} });
            // setPanelVPanel20({ id: "vpanel_2_0", data: {}, mode: '', size: 0.1, type: {} });
            setPanelHPanel00({ id: "hpanel_0_0", data: {}, mode: 'edit', size: 99, type: {} });
            setPanelHPanel01({ id: "hpanel_0_1", data: {}, mode: 'edit', size: 0, type: {} });
            setPanelHPanel10({ id: "hpanel_1_0", data: {}, mode: 'edit', size: 99, type: {} });
            setPanelHPanel11({ id: "hpanel_1_1", data: {}, mode: 'edit', size: 0.1, type: {} });
            // setPanelHPanel20({ id: "hpanel_2_0", data: {}, mode: '', size: 30, type: {} });
            // setPanelHPanel21({ id: "hpanel_2_1", data: {}, mode: '', size: 0.1, type: {} });

            drawHPanel00Ref.current?.reset();
            drawHPanel10Ref.current?.reset();
            // drawHPanel20Ref.current?.reset();
            editorHPanel00Ref.current?.reset();
            editorHPanel10Ref.current?.reset();
            editorHPanel01Ref.current?.reset();
            editorHPanel11Ref.current?.reset();
            codeHPanel00Ref.current?.resetCodes();
            codeHPanel10Ref.current?.resetCodes();
            codeHPanel01Ref.current?.resetCodes();
            codeHPanel11Ref.current?.resetCodes();
            mathHPanel00Ref.current?.reset();
            mathHPanel10Ref.current?.reset();
            mathHPanel01Ref.current?.reset();
            mathHPanel11Ref.current?.reset();


            return;
        }
    }, [panelData, idMode])

    useEffect(() => {
        // console.log("Firing");

        // console.log("All panels data", idMode, panelData);

        if (panelData.length === 0) {
            return;
        }


        if (idMode === -1) {


            const panelVPanel10Data = panelData.find((panel: any) => panel.id === "vpanel_1_0");
            const panelVPanel20Data = panelData.find((panel: any) => panel.id === "vpanel_2_0");
            const panelVPanel00Data = panelData.find((panel: any) => panel.id === "vpanel_0_0");
            const panelHPanel00Data = panelData.find((panel: any) => panel.id === "hpanel_0_0");
            const panelHPanel01Data = panelData.find((panel: any) => panel.id === "hpanel_0_1");
            const panelHPanel10Data = panelData.find((panel: any) => panel.id === "hpanel_1_0");
            const panelHPanel11Data = panelData.find((panel: any) => panel.id === "hpanel_1_1");
            // const panelHPanel20Data = panelData.find((panel: any) => panel.id === "hpanel_2_0");
            // const panelHPanel21Data = panelData.find((panel: any) => panel.id === "hpanel_2_1");




            panelVPanel00Ref.current?.resize(panelVPanel00Data.size);
            panelVPanel10Ref.current?.resize(panelVPanel10Data.size);
            panelVPanel20Ref.current?.resize(panelVPanel20Data.size);
            panelHPanel00Ref.current?.resize(panelHPanel00Data.size);
            panelHPanel01Ref.current?.resize(panelHPanel01Data.size);
            panelHPanel10Ref.current?.resize(panelHPanel10Data.size);
            panelHPanel11Ref.current?.resize(panelHPanel11Data.size);
            // panelHPanel20Ref.current?.resize(panelHPanel20Data.size);
            // panelHPanel21Ref.current?.resize(panelHPanel21Data.size);

            // console.log('idmode', idMode, panelHPanel10Data);
            setPanelVPanel10(panelVPanel10Data);
            // setPanelVPanel20(panelVPanel20Data); //investigate the mode undefined -> first mathsheet then drawing and then undefined
            setPanelVPanel00(panelVPanel00Data);
            setPanelHPanel00({
                id: "hpanel_0_0", data: getRightData(panelHPanel00Data), mode: panelHPanel00Data.mode, size: panelHPanel00Data.size,
                type: panelHPanel00Data.type
            });
            setPanelHPanel01({
                id: "hpanel_0_1", data: getRightData(panelHPanel01Data), mode: panelHPanel01Data.mode, size: panelHPanel01Data.size,
                type: panelHPanel01Data.type
            });
            setPanelHPanel10({
                id: "hpanel_1_0", data: getRightData(panelHPanel10Data), mode: panelHPanel10Data.mode, size: panelHPanel10Data.size,
                type: panelHPanel10Data.type
            });
            setPanelHPanel11({
                id: "hpanel_1_1", data: getRightData(panelHPanel11Data), mode: panelHPanel11Data.mode, size: panelHPanel11Data.size,
                type: panelHPanel11Data.type
            });
            // setPanelHPanel20({
            //     id: "hpanel_2_0", data: getRightData(panelHPanel20Data), mode: panelHPanel20Data.mode, size: panelHPanel20Data.size,
            //     type: panelHPanel20Data.type
            // });
            // setPanelHPanel21({
            //     id: "hpanel_2_1", data: getRightData(panelHPanel21Data), mode: panelHPanel21Data.mode, size: panelHPanel21Data.size,
            //     type: panelHPanel21Data.type
            // });

            dispatch(replaceAllEditorsData([
                { id: "hpanel_0_0", data: getRightData(panelHPanel00Data), mode: panelHPanel00Data.mode, type: panelHPanel00Data.type, size: panelHPanel00Data.size },
                { id: "hpanel_0_1", data: getRightData(panelHPanel01Data), mode: panelHPanel01Data.mode, type: panelHPanel01Data.type, size: panelHPanel01Data.size },
                { id: "hpanel_1_0", data: getRightData(panelHPanel10Data), mode: panelHPanel10Data.mode, type: panelHPanel10Data.type, size: panelHPanel10Data.size },
                { id: "hpanel_1_1", data: getRightData(panelHPanel11Data), mode: panelHPanel11Data.mode, type: panelHPanel11Data.type, size: panelHPanel11Data.size },
                // { id: "hpanel_2_0", data: getRightData(panelHPanel20Data), mode: panelHPanel20Data.mode, type: panelHPanel20Data.type },
                // { id: "hpanel_2_1", data: getRightData(panelHPanel21Data), mode: panelHPanel21Data.mode, type: panelHPanel21Data.type },
            ]));
        }
        setLoading(false);
        // console.log("dispatching false")
        dispatch(setLoadingEditorData(false));

    }, [panelData, idMode])

    // console.log('main reading', panelHPanel10);

    const savePanelDataDirectly = async (data: any) => {

        // data = [{ id: "hpanel_0_0", size: 99 }]

        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);
        for (let i = 0; i < data.length; i++) {
            const panelRef = data[i].id;
            batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelRef), {
                id: panelRef,
                size: data[i].size,
            }, { merge: true });
        }
        await batch.commit();
    }

    const savePanelData = async (dragging: boolean) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;
        if (dragging) return;

        Sentry.addBreadcrumb({
            category: "auth",
            message: "User " + user.uid + " saved the panel data",
            level: "info",
        });

        const ids = ["vpanel_0_0", "vpanel_1_0",
            //  "vpanel_2_0",
            "hpanel_0_0", "hpanel_0_1", "hpanel_1_0", "hpanel_1_1",
            //    "hpanel_2_0", "hpanel_2_1"
        ];
        const sizes = [panelVPanel00Ref.current?.getSize(), panelVPanel10Ref.current?.getSize(),
        // panelVPanel20Ref.current?.getSize(),
        panelHPanel00Ref.current?.getSize(), panelHPanel01Ref.current?.getSize(), panelHPanel10Ref.current?.getSize(),
        panelHPanel11Ref.current?.getSize(),
            // panelHPanel20Ref.current?.getSize(), panelHPanel21Ref.current?.getSize()
        ];

        //set states
        // setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: sizes[0] }));
        // setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: sizes[1] }));
        // setPanelVPanel20((prevPanel: any) => ({ ...prevPanel, size: sizes[2] }));
        // setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: sizes[3] }));
        // setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: sizes[4] }));
        // setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: sizes[5] }));
        // setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: sizes[6] }));
        // setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, size: sizes[7] }));
        // setPanelHPanel21((prevPanel: any) => ({ ...prevPanel, size: sizes[8] }));

        if (panelVPanel00Ref.current) setPanelVPanel00((prevPanel: any) => ({ ...prevPanel, size: panelVPanel00Ref.current?.getSize() }));

        if (panelVPanel10Ref.current) setPanelVPanel10((prevPanel: any) => ({ ...prevPanel, size: panelVPanel10Ref.current?.getSize() }));

        if (panelHPanel00Ref.current) setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, size: panelHPanel00Ref.current?.getSize() }));

        if (panelHPanel01Ref.current) setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, size: panelHPanel01Ref.current?.getSize() }));

        if (panelHPanel10Ref.current) setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, size: panelHPanel10Ref.current?.getSize() }));

        if (panelHPanel11Ref.current) setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, size: panelHPanel11Ref.current?.getSize() }));


        const allEditorsDataBlocksCloned = JSON.parse(JSON.stringify(allEditorsData.dataBlocks));
        let panelHPanel00Data = allEditorsDataBlocksCloned.find((block: any) => block.id === "hpanel_0_0");
        let panelHPanel01Data = allEditorsDataBlocksCloned.find((block: any) => block.id === "hpanel_0_1");
        let panelHPanel10Data = allEditorsDataBlocksCloned.find((block: any) => block.id === "hpanel_1_0");
        let panelHPanel11Data = allEditorsDataBlocksCloned.find((block: any) => block.id === "hpanel_1_1");

        panelHPanel00Data.size = sizes[2];
        panelHPanel01Data.size = sizes[3];
        panelHPanel10Data.size = sizes[4];
        panelHPanel11Data.size = sizes[5];

        dispatch(replaceAllEditorsData
            ([
                panelHPanel00Data,
                panelHPanel01Data,
                panelHPanel10Data,
                panelHPanel11Data,
            ])
        );

        //allEditorsData.allTabsUnsavedData[tid]
        // const allTabsUnsavedDataCloned = JSON.parse(JSON.stringify(allEditorsData.allTabsUnsavedData));
        // const allPanelsData = allTabsUnsavedDataCloned[tid];
        // if (!allPanelsData || allPanelsData.length === 0) return;

        // const hpanel_0_0Data = allPanelsData.find((panel: any) => panel.id === "hpanel_0_0");
        // const hpanel_0_1Data = allPanelsData.find((panel: any) => panel.id === "hpanel_0_1");
        // const hpanel_1_0Data = allPanelsData.find((panel: any) => panel.id === "hpanel_1_0");
        // const hpanel_1_1Data = allPanelsData.find((panel: any) => panel.id === "hpanel_1_1");

        // hpanel_0_0Data.size = sizes[2];
        // hpanel_0_1Data.size = sizes[3];
        // hpanel_1_0Data.size = sizes[4];
        // hpanel_1_1Data.size = sizes[5];

        // allPanelsData[0] = hpanel_0_0Data;
        // allPanelsData[1] = hpanel_0_1Data;
        // allPanelsData[2] = hpanel_1_0Data;
        // allPanelsData[3] = hpanel_1_1Data;

        // allTabsUnsavedDataCloned[tid] = allPanelsData;

        // dispatch(setAllTabsUnsavedData(allTabsUnsavedDataCloned));

        const batch = writeBatch(db);
        for (let i = 0; i < ids.length; i++) {
            const panelRef = ids[i];
            batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelRef), {
                id: panelRef,
                size: sizes[i],
            }, { merge: true });
        }
        await batch.commit();
    }

    const saveModeArrayData = async (arr: any) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);

        for (let i = 0; i < arr.length; i++) {
            const panelId = arr[i].id;
            const mode = arr[i].mode;

            // console.log("Panel ID", panelId, "Mode", mode);
            batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
                id: panelId,
                mode: mode
            }, { merge: true });
        }
        await batch.commit();
    }

    const saveModeData = async (mode: string, panelId: string) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
            id: panelId,
            mode: mode,
            data: getEmptyForMode(mode),
            type: {}
        }, { merge: true });


        await batch.commit();
    }

    const saveTypeData = async (type: any, panelId: string) => {
        const userId = user?.uid;
        if (!userId || !wid || !tid) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelId), {
            id: panelId,
            type,
        }, { merge: true });

        batch.commit();
    }

    const saveEditorStyle = async (style: any) => {
        const userId = user?.uid;
        if (!userId) return;

        const batch = writeBatch(db);

        batch.set(doc(db, "data", userId, "userInfo", "prefs"), {
            codeEditorStyle: style,
        }, { merge: true });

        batch.commit();
    }


    const menuBlock = (panelId: string) => {


        return (
            <Dropdown>
                <Tooltip title="Switch Panel Type" size="sm" sx={{ zIndex: 102 }} >
                    <MenuButton
                        slots={{ root: IconButton }}
                        slotProps={{ root: { variant: 'plain', color: 'neutral', style: { zIndex: 10 }, id: "main_settings_btn", disabled: (allEditorsData.currentTabShared) } }}
                    >
                        <AutoAwesome sx={{ height: "20px" }} />
                    </MenuButton>
                </Tooltip>
                <Menu>

                    {panelId === "hpanel_0_0" || panelId === "hpanel_1_0" ? (<MenuItem onClick={() => {

                        const confirmResult = window.confirm("Changing the view will cause unsaved data to be lost. Are you sure you want to continue?");
                        if (!confirmResult) {
                            return;
                        }

                        Sentry.addBreadcrumb({
                            category: "auth",
                            message: "User " + user.uid + " changed the editor mode to Drawing Editor",
                            level: "info",
                        });

                        if (panelId === "hpanel_0_0") {
                            // console.log("PanelHPanel00", panelHPanel00.mode);

                            // setPanelHPanel00({ id: "hpanel_0_0", size: 30, data: [], mode: "draw" });
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, data: [], mode: "draw" }));
                            saveModeData("draw", panelId)


                        } else if (panelId === "hpanel_1_0") {

                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, data: [], mode: "draw" }));
                            saveModeData("draw", panelId)

                        }

                    }}>Drawing Editor</MenuItem>) : null}
                    <MenuItem onClick={() => {
                        const confirmResult = window.confirm("Changing the view will cause unsaved data to be lost. Are you sure you want to continue?");
                        if (!confirmResult) {
                            return;
                        }

                        Sentry.addBreadcrumb({
                            category: "auth",
                            message: "User" + user.uid + " changed the editor mode to Math Editor",
                            level: "info",
                        });

                        if (panelId === "hpanel_0_0") {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, data: [], mode: "math", type: {} }));
                            saveModeData("math", panelId)
                        } else if (panelId === "hpanel_1_0") {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, data: [], mode: "math", type: {} }));
                            saveModeData("math", panelId)
                        } else if (panelId === "hpanel_0_1") {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, data: [], mode: "math", type: {} }));
                            saveModeData("math", panelId)
                        } else if (panelId === "hpanel_1_1") {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, data: [], mode: "math", type: {} }));
                            saveModeData("math", panelId)
                        }
                        // else if (panelId === "hpanel_2_0") {
                        //     setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, data: [], mode: "math" }));
                        //     saveModeData("math", panelId)
                        // }
                    }}>Math Editor</MenuItem>
                    <MenuItem onClick={() => {
                        const confirmResult = window.confirm("Changing the view will cause unsaved data to be lost. Are you sure you want to continue?");
                        if (!confirmResult) {
                            return;
                        }

                        Sentry.addBreadcrumb({
                            category: "auth",
                            message: "User " + user.uid + " changed the editor mode to Text Editor",
                            level: "info",
                        });

                        if (panelId === "hpanel_0_0") {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, data: {}, mode: "edit", type: {} }));
                            saveModeData("edit", panelId)
                        } else if (panelId === "hpanel_1_0") {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, data: {}, mode: "edit", type: {} }));
                            saveModeData("edit", panelId)
                        } else if (panelId === "hpanel_0_1") {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, data: {}, mode: "edit", type: {} }));
                            saveModeData("edit", panelId)
                        } else if (panelId === "hpanel_1_1") {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, data: {}, mode: "edit", type: {} }));
                            saveModeData("edit", panelId)
                        }
                        // else if (panelId === "hpanel_2_0") {
                        //     setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, data: {}, mode: "edit" }));
                        //     saveModeData("edit", panelId)
                        // }
                    }}>Text Editor</MenuItem>
                    <MenuItem onClick={() => {
                        const confirmResult = window.confirm("Changing the view will cause unsaved data to be lost. Are you sure you want to continue?");
                        if (!confirmResult) {
                            return;
                        }

                        Sentry.addBreadcrumb({
                            category: "auth",
                            message: "User " + user.uid + " changed the editor mode to Code Editor",
                            level: "info",
                        });

                        if (panelId === "hpanel_0_0") {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, data: "", mode: "code", type: {} }));
                            saveModeData("code", panelId)
                        } else if (panelId === "hpanel_1_0") {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, data: "", mode: "code", type: {} }));
                            saveModeData("code", panelId)
                        } else if (panelId === "hpanel_0_1") {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, data: "", mode: "code", type: {} }));
                            saveModeData("code", panelId)
                        } else if (panelId === "hpanel_1_1") {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, data: "", mode: "code", type: {} }));
                            saveModeData("code", panelId)
                        }
                        // else if (panelId === "hpanel_2_0") {
                        //     setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, data: "", mode: "code" }));
                        //     saveModeData("code", panelId)
                        // }
                    }}>Code Editor</MenuItem>
                    <MenuItem onClick={() => {
                        const confirmReset = window.confirm("Resetting will cause data to be lost. Are you sure you want to continue?");
                        if (!confirmReset) {
                            return
                        }

                        if (panelId === "hpanel_0_0") {
                            if (panelHPanel00.mode === 'draw' && drawHPanel00Ref.current) {
                                drawHPanel00Ref.current.reset();
                            } else if (panelHPanel00.mode === 'edit' || panelHPanel10.mode === undefined) {
                                setPanelHPanel00(
                                    (prevPanel: any) => ({ ...prevPanel, data: {} })
                                )

                                if (editorHPanel00Ref.current) {
                                    editorHPanel00Ref.current.reset();
                                }
                            } else if (panelHPanel00.mode === 'math' && panelHPanel00.data) {
                                setPanelHPanel00(
                                    (prevPanel: any) => ({ ...prevPanel, data: [] })
                                )

                                if (mathHPanel00Ref.current) {
                                    mathHPanel00Ref.current.reset();
                                }
                            }
                            else if (panelHPanel00.mode === 'code') {
                                setPanelHPanel00(
                                    (prevPanel: any) => ({ ...prevPanel, data: "" })
                                )

                                if (codeHPanel00Ref.current) {
                                    codeHPanel00Ref.current.resetCodes();
                                }
                            }
                        } else if (panelId === "hpanel_1_0") {
                            if (panelHPanel10.mode === 'draw' && drawHPanel10Ref.current) {
                                drawHPanel10Ref.current.reset();
                            } else if (panelHPanel10.mode === 'edit' || panelHPanel10.mode === undefined) {
                                setPanelHPanel10(
                                    (prevPanel: any) => ({ ...prevPanel, data: {} })
                                )
                                if (editorHPanel10Ref.current) {
                                    editorHPanel10Ref.current.reset();
                                }
                            } else if (panelHPanel10.mode === 'math' && panelHPanel10.data) {
                                setPanelHPanel10(
                                    (prevPanel: any) => ({ ...prevPanel, data: [] })
                                )

                                if (mathHPanel10Ref.current) {
                                    mathHPanel10Ref.current.reset();
                                }
                            } else if (panelHPanel10.mode === 'code') {
                                setPanelHPanel10(
                                    (prevPanel: any) => ({ ...prevPanel, data: "" })
                                )

                                if (codeHPanel10Ref.current) {
                                    codeHPanel10Ref.current.resetCodes();
                                }
                            }
                        } else if (panelId === "hpanel_0_1") {
                            if (panelHPanel01.mode === 'math' && panelHPanel01.data) {
                                setPanelHPanel01(
                                    (prevPanel: any) => ({ ...prevPanel, data: [] })
                                )

                                if (mathHPanel01Ref.current) {
                                    mathHPanel01Ref.current.reset();
                                }
                            } else if (panelHPanel01.mode === 'code') {
                                setPanelHPanel01(
                                    (prevPanel: any) => ({ ...prevPanel, data: "" })
                                )

                                if (codeHPanel01Ref.current) {
                                    codeHPanel01Ref.current.resetCodes();
                                }
                            } else if (panelHPanel01.mode === 'edit' || panelHPanel10.mode === undefined) {
                                setPanelHPanel01(
                                    (prevPanel: any) => ({ ...prevPanel, data: {} })
                                )
                                if (editorHPanel01Ref.current) {
                                    editorHPanel01Ref.current.reset();
                                }
                            }
                        } else if (panelId === "hpanel_1_1") {
                            if (panelHPanel11.mode === 'math' && panelHPanel11.data) {
                                setPanelHPanel11(
                                    (prevPanel: any) => ({ ...prevPanel, data: [] })
                                )

                                if (mathHPanel11Ref.current) {
                                    mathHPanel11Ref.current.reset();
                                }
                            } else if (panelHPanel11.mode === 'code') {
                                setPanelHPanel11(
                                    (prevPanel: any) => ({ ...prevPanel, data: "" })
                                )

                                if (codeHPanel11Ref.current) {
                                    codeHPanel11Ref.current.resetCodes();
                                }
                            } else if (panelHPanel11.mode === 'edit' || panelHPanel10.mode === undefined) {
                                setPanelHPanel11(
                                    (prevPanel: any) => ({ ...prevPanel, data: {} })
                                )
                                if (editorHPanel11Ref.current) {
                                    editorHPanel11Ref.current.reset();
                                }
                            }
                        }
                    }}>Reset</MenuItem>
                    {/* {(panelId === "hpanel_0_0" && panelHPanel00.mode === 'draw') || (panelId === "hpanel_1_0" && panelHPanel10.mode === "draw") || (panelId === "hpanel_2_0" && panelHPanel20.mode === "draw") ? (
                        <MenuItem onClick={() => {
                            if (panelId === "hpanel_0_0") {
                                if (drawHPanel00Ref.current) {
                                    drawHPanel00Ref.current.refresh();
                                }
                            } else if (panelId === "hpanel_1_0") {
                                if (drawHPanel10Ref.current) {
                                    drawHPanel10Ref.current.refresh();
                                }
                            } else if (panelId === "hpanel_2_0") {
                                if (drawHPanel20Ref.current) {
                                    drawHPanel20Ref.current.refresh();
                                }
                            }
                        }}>Refresh</MenuItem>
                    ) : null} */}
                    {/* */}
                    {/* <MenuItem>Export</MenuItem> */}
                </Menu>
            </Dropdown >
        )
    }
    const getRightIcon = (panelId: string) => {
        if (panelId === "vpanel_0_0") {
            return panelVPanel00.prevSize ? <FullscreenExit /> : <Fullscreen />
        } else if (panelId === "vpanel_1_0") {
            return panelVPanel10.prevSize ? <FullscreenExit /> : <Fullscreen />
        }
        // else if (panelId === "vpanel_2_0") {
        //     return panelVPanel20.prevSize ? <FullscreenExit /> : <Fullscreen />
        // }
    }

    const customizeButton = (panelId: string) => {
        if (panelId === "hpanel_0_0" && panelHPanel00.mode === 'code') {
            return (
                <Tooltip title="Cutomize panel" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel00.showCustom) {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, showCustom: true }));
                        } else {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, showCustom: false }));
                        }
                    }}>
                        <DisplaySettings />
                    </IconButton>
                </Tooltip>
            )
        }
        else if (panelId === "hpanel_1_0" && panelHPanel10.mode === 'code') {
            return (
                <Tooltip title="Cutomize panel" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel10.showCustom) {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, showCustom: true }));
                        } else {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, showCustom: false }));
                        }
                    }}>
                        <DisplaySettings />
                    </IconButton>
                </Tooltip>
            )
        } else if (panelId === "hpanel_0_1" && panelHPanel01.mode === 'code') {
            return (
                <Tooltip title="Cutomize panel" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel01.showCustom) {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, showCustom: true }));
                        } else {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, showCustom: false }));
                        }
                    }}>
                        <DisplaySettings />
                    </IconButton>
                </Tooltip>
            )
        } else if (panelId === "hpanel_1_1" && panelHPanel11.mode === 'code') {
            return (
                <Tooltip title="Cutomize panel" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel11.showCustom) {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, showCustom: true }));
                        } else {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, showCustom: false }));
                        }
                    }}>
                        <DisplaySettings />
                    </IconButton>
                </Tooltip>
            )
        }
        // else if (panelId === "hpanel_2_0" && panelHPanel20.mode === 'code') {
        //     return (
        //         <IconButton style={{ opacity: 0.5, zIndex: 10 }} variant="plain" onClick={() => {
        //             if (!panelHPanel20.showCustom) {
        //                 setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, showCustom: true }));
        //             } else {
        //                 setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, showCustom: false }));
        //             }
        //         }}>
        //             <DisplaySettings />
        //         </IconButton>
        //     )
        // }
    }

    const helpButton = (panelId: string) => {

        if (panelId === "hpanel_0_0" && (panelHPanel00.mode === 'math' || panelHPanel00.mode === 'draw')) {
            return (
                <Tooltip title="Show Panel Information" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel00.showInfo) {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, showInfo: true }));
                        } else {
                            setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, showInfo: false }));
                        }
                    }}>
                        <Help />
                    </IconButton>
                </Tooltip>
            )
        } else if (panelId === "hpanel_1_0" && (panelHPanel10.mode === 'math' || panelHPanel10.mode === 'draw')) {

            return (
                <Tooltip title="Show Panel Information" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel10.showInfo) {
                            // console.log('panelHPanel10-main', panelHPanel10)
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, showInfo: true }));
                        } else {
                            setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, showInfo: false }));
                        }
                    }}>
                        <Help />
                    </IconButton>
                </Tooltip>
            )
        } else if (panelId === "hpanel_0_1" && (panelHPanel01.mode === 'math' || panelHPanel01.mode === 'draw')) {
            return (
                <Tooltip title="Show Panel Information" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel01.showInfo) {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, showInfo: true }));
                        } else {
                            setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, showInfo: false }));
                        }
                    }}>
                        <Help />
                    </IconButton>
                </Tooltip>
            )
        } else if (panelId === "hpanel_1_1" && (panelHPanel11.mode === 'math' || panelHPanel11.mode === 'draw')) {
            return (
                <Tooltip title="Show Panel Information" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        if (!panelHPanel11.showInfo) {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, showInfo: true }));
                        } else {
                            setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, showInfo: false }));
                        }
                    }}>
                        <Help />
                    </IconButton>
                </Tooltip>
            )
        }
        // else if (panelId === "hpanel_2_0" && (panelHPanel20.mode === 'math' || panelHPanel20.mode === 'draw')) {
        //     return (
        //         <IconButton style={{ opacity: 0.5, zIndex: 10 }} variant="plain" onClick={() => {
        //             if (!panelHPanel20.showInfo) {
        //                 setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, showInfo: true }));
        //             } else {
        //                 setPanelHPanel20((prevPanel: any) => ({ ...prevPanel, showInfo: false }));
        //             }
        //         }}>
        //             <Help />
        //         </IconButton>
        //     )
        // }
    }
    const fullScreenBtn = (mainPanelId: string) => {
        return (
            <Tooltip title="Expand to full screen" size="sm" sx={{ zIndex: 102 }} >
                <IconButton style={{ zIndex: 10 }} variant="plain" onClick={async () => {

                    if (!wid || !tid) return;

                    if (!panelVPanel00Ref.current) return;
                    if (!panelVPanel10Ref.current) return;

                    if (mainPanelId === "vpanel_0_0") {
                        if (panelVPanel00.size > 80) {
                            panelVPanel00Ref.current?.resize(panelVPanel00.prevSize || 30);
                            panelVPanel10Ref.current?.resize(panelVPanel10.prevSize || 30);
                            // panelVPanel20Ref.current?.resize(panelVPanel20.prevSize || 30);

                            setPanelVPanel00({ id: "vpanel_0_0", size: panelVPanel00.prevSize || 30, data: {} });
                            setPanelVPanel10({ id: "vpanel_0_0", size: panelVPanel10.prevSize || 30, data: {} });

                            // setPanelVPanel20({ id: "vpanel_0_0", size: panelVPanel20.prevSize || 30, data: {} });
                        } else {
                            // console.log("panelVPanel00Ref.current?.getSize()", panelVPanel00Ref.current?.getSize())
                            setPanelVPanel00({ id: "vpanel_0_0", prevSize: panelVPanel00Ref.current?.getSize(), size: 100, data: {} });
                            setPanelVPanel10({ id: "vpanel_1_0", prevSize: panelVPanel10Ref.current?.getSize(), size: 0, data: {} });
                            // setPanelVPanel20({ id: "vpanel_2_0", prevSize: panelVPanel20Ref.current?.getSize(), size: 0, data: {} });

                            panelVPanel00Ref.current?.resize(100);
                            panelVPanel10Ref.current?.resize(0);
                            // panelVPanel20Ref.current?.resize(0);
                        }
                    } else if (mainPanelId === "vpanel_1_0") {
                        if (panelVPanel10.size > 80) {
                            panelVPanel00Ref.current?.resize(panelVPanel00.prevSize || 30);
                            panelVPanel10Ref.current?.resize(panelVPanel10.prevSize || 30);
                            // panelVPanel20Ref.current?.resize(panelVPanel20.prevSize || 30);

                            setPanelVPanel00({ id: "vpanel_0_0", size: panelVPanel00.prevSize || 30, data: {} });
                            setPanelVPanel10({ id: "vpanel_0_0", size: panelVPanel10.prevSize || 30, data: {} });
                            // setPanelVPanel20({ id: "vpanel_0_0", size: panelVPanel20.prevSize || 30, data: {} });
                        } else {
                            setPanelVPanel00({ id: "vpanel_0_0", prevSize: panelVPanel00Ref.current?.getSize(), size: 0, data: {} });
                            setPanelVPanel10({ id: "vpanel_1_0", prevSize: panelVPanel10Ref.current?.getSize(), size: 100, data: {} });
                            // setPanelVPanel20({ id: "vpanel_2_0", prevSize: panelVPanel20Ref.current?.getSize(), size: 0, data: {} });

                            panelVPanel00Ref.current?.resize(0);
                            panelVPanel10Ref.current?.resize(100);
                            // panelVPanel20Ref.current?.resize(0);
                        }
                    }
                    // else if (mainPanelId === "vpanel_2_0") {
                    //     if (panelVPanel20.size > 80) {
                    //         panelVPanel00Ref.current?.resize(panelVPanel00.prevSize || 30);
                    //         panelVPanel10Ref.current?.resize(panelVPanel10.prevSize || 30);
                    //         panelVPanel20Ref.current?.resize(panelVPanel20.prevSize || 30);

                    //         setPanelVPanel00({ id: "vpanel_0_0", size: panelVPanel00.prevSize || 30, data: {} });
                    //         setPanelVPanel10({ id: "vpanel_0_0", size: panelVPanel10.prevSize || 30, data: {} });
                    //         setPanelVPanel20({ id: "vpanel_0_0", size: panelVPanel20.prevSize || 30, data: {} });
                    //     } else {
                    //         setPanelVPanel00({ id: "vpanel_0_0", prevSize: panelVPanel00Ref.current?.getSize(), size: 0, data: {} });
                    //         setPanelVPanel10({ id: "vpanel_1_0", prevSize: panelVPanel10Ref.current?.getSize(), size: 0, data: {} });
                    //         setPanelVPanel20({ id: "vpanel_2_0", prevSize: panelVPanel20Ref.current?.getSize(), size: 100, data: {} });

                    //         panelVPanel00Ref.current?.resize(0);
                    //         panelVPanel10Ref.current?.resize(0);
                    //         panelVPanel20Ref.current?.resize(100);
                    //     }
                    // }

                    if (user?.uid && allEditorsData.currentTabShared && !allEditorsData.isSpaceIsOwner) {
                        const ids = ["vpanel_0_0", "vpanel_1_0",
                            // "vpanel_2_0"
                        ];
                        const sizes = [panelVPanel00Ref.current?.getSize(), panelVPanel10Ref.current?.getSize(), panelVPanel20Ref.current?.getSize()];
                        const batch = writeBatch(db);
                        for (let i = 0; i < ids.length; i++) {
                            const panelRef = ids[i];
                            batch.set(doc(db, "spaces", wid, "tabs", tid, "panels", panelRef), {
                                id: panelRef,
                                size: sizes[i],
                            }, { merge: true });
                        }
                        await batch.commit();
                    }
                }}>{getRightIcon(mainPanelId)}</IconButton></Tooltip>
        )
    }


    const getMainPanelView = (panelId: string, panelData: any) => {
        // if (panelId === "hpanel_0_0") {

        // console.log('panelId', panelId, panelData)

        let drawpanelcRef = null;
        let codepanelcRef = null;
        let mathpanelcRef = null;
        let editorPanelRef = null;
        // let panelData = null;


        if (panelId === "hpanel_0_0") {
            drawpanelcRef = drawHPanel00Ref;
            codepanelcRef = codeHPanel00Ref;
            mathpanelcRef = mathHPanel00Ref;
            editorPanelRef = editorHPanel00Ref;
            // panelData = panelHPanel00;
        } else if (panelId === "hpanel_1_0") {
            drawpanelcRef = drawHPanel10Ref;
            codepanelcRef = codeHPanel10Ref;
            mathpanelcRef = mathHPanel10Ref;
            editorPanelRef = editorHPanel10Ref;
            // panelData = panelHPanel10;
        } else if (panelId === "hpanel_0_1") {
            codepanelcRef = codeHPanel01Ref;
            mathpanelcRef = mathHPanel01Ref;
            editorPanelRef = editorHPanel01Ref;
            // panelData = panelHPanel01;
        } else if (panelId === "hpanel_1_1") {
            codepanelcRef = codeHPanel11Ref;
            mathpanelcRef = mathHPanel11Ref;
            editorPanelRef = editorHPanel11Ref;
            // panelData = panelHPanel11;
        }

        if (panelData.mode === 'math') {
            return <MathSheet ref={mathpanelcRef} type={panelData.type} id={panelId} data={panelData.data} showRefs={panelData.showInfo ?
                true : false
            } />
        } else if (panelData.mode === 'draw') {
            return panelData.data.length >= 0 ? <ExcalidrawEditor showReference={panelData.showInfo ?
                true : false} readonly={allEditorsData.currentTabShared} data={panelData.data} ref={drawpanelcRef} id={panelId} /> : <ExcalidrawEditor readonly={allEditorsData.currentTabShared} ref={drawpanelcRef} id={panelId} />
        } else if (panelData.mode === 'code') {
            return <CodeEditor ref={codepanelcRef} showCustom={
                panelData.showCustom ?
                    true : false
            } readonly={allEditorsData.currentTabShared} id={panelId} data={panelData.data} langu={panelData.type && panelData.type.lang ? panelData.type.lang : null} change={(lang: string, editorStyle: string) => {
                const newObj = panelData.type;
                const newType = { ...newObj, lang };
                saveTypeData(newType, panelId);
                // setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, type: newType }));

                if (panelId === "hpanel_0_0") {
                    setPanelHPanel00((prevPanel: any) => ({ ...prevPanel, type: newType }));
                } else if (panelId === "hpanel_1_0") {
                    setPanelHPanel10((prevPanel: any) => ({ ...prevPanel, type: newType }));
                } else if (panelId === "hpanel_0_1") {
                    setPanelHPanel01((prevPanel: any) => ({ ...prevPanel, type: newType }));
                } else if (panelId === "hpanel_1_1") {
                    setPanelHPanel11((prevPanel: any) => ({ ...prevPanel, type: newType }));
                }

                saveEditorStyle(editorStyle);
                dispatch(setCodeEditorStyle(editorStyle));
            }} />
        }
        return <div className="editor-wrapper"><EditorComponent ref={editorPanelRef} readonly={allEditorsData.currentTabShared} loading={loading} data={panelData.data} style={{ height: (panelData.size - 5) + "vh", marginTop: allEditorsData.currentTabShared ? "0px" : "30px" }} id={
            panelId.slice(-4)
        } /></div>
    }

    // const checkIsTheresData = (data: any, mode: string) => {
    //     if (mode === 'draw') {
    //         return Object.keys(data).length > 0
    //     } else if (mode === 'math') {
    //         return data.length > 0
    //     }
    //     else if (mode === 'code') {
    //         return data.length > 0
    //     }
    //     else if (mode === 'edit') {
    //         return Object.keys(data).length > 0 && data.blocks?.length > 0
    //     }
    // }

    const showShowNavBarButton = () => {
        if (!allEditorsData.showNavBar) {
            return (
                <Tooltip title="Pin title bar" size="sm" sx={{ zIndex: 102 }} >
                    <IconButton style={{ zIndex: 10 }} variant="plain" onClick={() => {
                        dispatch(setShowNavBar(true));
                        sessionStorage.setItem("hideNavBar", "true");
                    }}>
                        <PushPin sx={{ height: "20px" }} />
                    </IconButton>
                </Tooltip>
            )
        }
    }

    // const getArrowColor = (panelId: string, direction: string, data1: any, mode1: any, data2?: any, mode2?: any) => {
    //     //left arrow
    //     if ((panelId === "vpanel_0_0" && direction === 'left')
    //         // || (panelId === "vpanel_2_0" && direction === 'right')
    //     ) {
    //         return checkIsTheresData(data1, mode1) || checkIsTheresData(data2, mode2) ? "success" : "inherit" as any
    //     } else {
    //         return checkIsTheresData(data1, mode1) ? "success" : "inherit" as any
    //     }
    // }

    // console.log('panelHPanel10', panelHPanel10)
    // console.log('panelHPanel01Ref.current', panelHPanel01Ref.current)
    // allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_0_0')?.data || {} => for colors we needed to get from redux because of the updated state there,
    // the component state doens't cover it
    return (
        <>
            <Joyride steps={steps} continuous={true} showSkipButton={true} floaterProps={{
                styles: {
                    wrapper: {
                        zIndex: 1000
                    },
                },
            }}
                styles={{
                    options: {
                        zIndex: 1000,
                    }
                }} run={localStorage.getItem("onboarding") === null || localStorage.getItem("onboarding") === "" ? true : false} callback={({ status }) => {
                    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
                        localStorage.setItem("onboarding", "true");
                    }
                }} />
            <PanelGroup direction="horizontal" >
                <Panel
                    id={"vpanel_0_0"}
                    key={"vpanel_0_0"} defaultSize={panelVPanel00.size} minSize={0} className="panel-container" ref={panelVPanel00Ref} collapsible collapsedSize={0.2} >
                    <PanelGroup direction="vertical">
                        <Panel
                            id={"hpanel_0_0"}
                            key={"hpanel_0_0"} minSize={20} ref={panelHPanel00Ref}>
                            <div style={{ position: 'relative', width: "100%", height: "100%" }}>

                                <div className='main-space-top-left-button-container' >
                                    {panelHPanel01.size < 10 ? (
                                        <Tooltip title="Pop open bottom panel" size="sm" sx={{ zIndex: 102 }} >
                                            <IconButton className="panel-open-btn" variant="plain" style={{ zIndex: 10 }} onClick={() => {
                                                if (!panelHPanel01Ref.current) return;
                                                // console.log('OO panelHPanel01Ref.current.getSize()', panelHPanel01Ref.current.getSize());
                                                if (panelHPanel01Ref.current.getSize() < 10) {
                                                    panelHPanel01Ref.current.resize(50)
                                                    setPanelHPanel01((prevState: any) => ({ ...prevState, size: 50 }))

                                                    if (panelHPanel00Ref.current) panelHPanel00Ref.current.resize(50);
                                                    setPanelHPanel00((prevState: any) => ({ ...prevState, size: 50 }))

                                                } else {
                                                    panelHPanel01Ref.current.resize(0.1)
                                                    setPanelHPanel01((prevState: any) => ({ ...prevState, size: 0.1 }))

                                                    if (panelHPanel00Ref.current) panelHPanel00Ref.current.resize(99);
                                                    setPanelHPanel00((prevState: any) => ({ ...prevState, size: 99 }))
                                                }
                                                savePanelData(false)
                                            }}>
                                                <Add />
                                                {/* <Add color={getArrowColor("vpanel_0_0", "bottom", allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_0_1')?.data || {}, panelHPanel01.mode || 'edit')} /> */}

                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {showShowNavBarButton()}
                                    {helpButton("hpanel_0_0")}
                                    {customizeButton("hpanel_0_0")}
                                    {fullScreenBtn("vpanel_0_0")}
                                    {menuBlock("hpanel_0_0")}
                                </div>
                                {getMainPanelView("hpanel_0_0", panelHPanel00)}
                            </div>
                        </Panel>
                        <PanelResizeHandle className="hr-panel-handler" style={{ backgroundColor: panelH01Size < 5 ? "transparent" : mode === "light" || (mode === "system" && systemMode === "light") ? "gainsboro" : "#555555" }} onDragging={(dragging) => {
                            if (allEditorsData.currentTabShared) return;
                            savePanelData(dragging)
                        }} />
                        <Panel
                            id={"hpanel_0_1"}
                            key={"hpanel_0_1"} defaultSize={panelHPanel01.size} minSize={0.1} ref={panelHPanel01Ref} collapsible={true} collapsedSize={0.2} style={{ opacity: panelH01Size < 5 ? 0 : 1 }}>
                            <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                                <div className='main-space-top-left-button-container' >
                                    {panelHPanel01.size < 10 ?
                                        null : (<Tooltip title="Close panel" size="sm" sx={{ zIndex: 102 }} >
                                            <IconButton className="panel-open-btn" variant="plain" style={{ zIndex: 10 }} onClick={() => {
                                                if (!panelHPanel01Ref.current) return;
                                                // console.log('OO panelHPanel01Ref.current.getSize()', panelHPanel01Ref.current.getSize());
                                                if (panelHPanel01Ref.current.getSize() < 10) {
                                                    panelHPanel01Ref.current.resize(50)
                                                    setPanelHPanel01((prevState: any) => ({ ...prevState, size: 50 }))

                                                    if (panelHPanel00Ref.current) panelHPanel00Ref.current.resize(50);
                                                    setPanelHPanel00((prevState: any) => ({ ...prevState, size: 50 }))

                                                } else {
                                                    panelHPanel01Ref.current.resize(0.1)
                                                    setPanelHPanel01((prevState: any) => ({ ...prevState, size: 0.1 }))

                                                    if (panelHPanel00Ref.current) panelHPanel00Ref.current.resize(99);
                                                    setPanelHPanel00((prevState: any) => ({ ...prevState, size: 99 }))
                                                }
                                                savePanelData(false)
                                            }}>

                                                <Remove />

                                            </IconButton>
                                        </Tooltip>)}
                                    {showShowNavBarButton()}
                                    {helpButton("hpanel_0_1")}
                                    {customizeButton("hpanel_0_1")}
                                    {menuBlock("hpanel_0_1")}
                                </div>
                                {getMainPanelView("hpanel_0_1", panelHPanel01)}
                            </div>
                        </Panel>
                    </PanelGroup>
                </Panel >
                <PanelResizeHandle onDragging={(dragging) => {
                    if (allEditorsData.currentTabShared) return;
                    savePanelData(dragging)
                }}
                    className="vt-panel-handler" style={{ backgroundColor: panelV00Size < 5 ? "transparent" : mode === "light" || (mode === "system" && systemMode === "light") ? "gainsboro" : "#555555" }} />
                <Panel
                    id={"vpanel_1_0"}
                    key={"vpanel_1_0"} defaultSize={panelVPanel10.size} minSize={0} className="panel-container" ref={panelVPanel10Ref} style={{ opacity: panelV10Size < 5 ? 0 : 1 }}>
                    <PanelGroup direction="vertical">
                        <Panel
                            id={"hpanel_1_0"}
                            key={"hpanel_1_0"} defaultSize={panelHPanel10.size} minSize={0} ref={panelHPanel10Ref}>
                            <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                                <div className='main-space-top-left-button-container' >
                                    {panelHPanel11.size < 10 ? (<Tooltip title="Pop open bottom panel" size="sm" sx={{ zIndex: 102 }} >
                                        <IconButton variant="plain" style={{ zIndex: 10 }} onClick={() => {
                                            if (!panelHPanel11Ref.current) return;
                                            if (panelHPanel11Ref.current.getSize() < 10) {
                                                panelHPanel11Ref.current.resize(50)
                                                setPanelHPanel10((prevState: any) => ({ ...prevState, size: 50 }))
                                                if (panelHPanel10Ref.current) panelHPanel10Ref.current.resize(50)
                                                setPanelHPanel11((prevState: any) => ({ ...prevState, size: 50 }))
                                            } else {
                                                panelHPanel11Ref.current.resize(0.1)
                                                setPanelHPanel11((prevState: any) => ({ ...prevState, size: 0.1 }))
                                                if (panelHPanel10Ref.current) panelHPanel10Ref.current.resize(99)
                                                setPanelHPanel10((prevState: any) => ({ ...prevState, size: 99 }))
                                            }
                                            savePanelData(false)
                                        }}>

                                            <Add />

                                        </IconButton>
                                    </Tooltip>
                                    ) : null}
                                    {showShowNavBarButton()}
                                    {helpButton("hpanel_1_0")}
                                    {customizeButton("hpanel_1_0")}
                                    {fullScreenBtn("vpanel_1_0")}
                                    {menuBlock("hpanel_1_0")}
                                </div>
                                {getMainPanelView("hpanel_1_0", panelHPanel10)}
                            </div>
                        </Panel>
                        <PanelResizeHandle className="hr-panel-handler" style={{ backgroundColor: panelH11Size < 5 ? "transparent" : mode === "light" || (mode === "system" && systemMode === "light") ? "gainsboro" : "#555555" }} onDragging={(dragging) => {
                            if (allEditorsData.currentTabShared) return;
                            savePanelData(dragging)
                        }} />
                        <Panel
                            id={"hpanel_1_1"}
                            key={"hpanel_1_1"} defaultSize={panelHPanel11.size} minSize={0.1} ref={panelHPanel11Ref} collapsible={true} collapsedSize={0.2} style={{ opacity: panelH11Size < 5 ? 0 : 1 }}>
                            <div style={{ position: 'relative', width: "100%", height: "100%" }}>
                                <div className='main-space-top-left-button-container' >
                                    {panelHPanel11.size < 10 ? null : (
                                        <Tooltip title="Close panel" size="sm" sx={{ zIndex: 102 }} >
                                            <IconButton variant="plain" style={{ zIndex: 10 }} onClick={() => {
                                                if (!panelHPanel11Ref.current) return;
                                                if (panelHPanel11Ref.current.getSize() < 10) {
                                                    panelHPanel11Ref.current.resize(50)
                                                    setPanelHPanel10((prevState: any) => ({ ...prevState, size: 50 }))
                                                    if (panelHPanel10Ref.current) panelHPanel10Ref.current.resize(50)
                                                    setPanelHPanel11((prevState: any) => ({ ...prevState, size: 50 }))
                                                } else {
                                                    panelHPanel11Ref.current.resize(0.1)
                                                    setPanelHPanel11((prevState: any) => ({ ...prevState, size: 0.1 }))
                                                    if (panelHPanel10Ref.current) panelHPanel10Ref.current.resize(99)
                                                    setPanelHPanel10((prevState: any) => ({ ...prevState, size: 99 }))
                                                }
                                                savePanelData(false)
                                            }}>

                                                <Remove />

                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {showShowNavBarButton()}
                                    {helpButton("hpanel_1_1")}
                                    {customizeButton("hpanel_1_1")}
                                    {menuBlock("hpanel_1_1")}
                                </div>
                                {getMainPanelView("hpanel_1_1", panelHPanel11)}
                            </div>
                        </Panel>
                    </PanelGroup>
                </Panel >
                {/* <PanelResizeHandle onDragging={(dragging) => {
                    if (allEditorsData.currentTabShared) return;
                    savePanelData(dragging)
                }}
                    className="vt-panel-handler" style={{ backgroundColor: panelV20Size < 5 ? "transparent" : "gainsboro" }} />
                <Panel
                    id={"vpanel_2_0"}
                    key={"vpanel_2_0"} defaultSize={panelVPanel20.size} minSize={0.3} className="panel-container" ref={panelVPanel20Ref} collapsible={true} collapsedSize={0.2} style={{ opacity: panelV20Size < 5 ? 0 : 1 }}>
                    <PanelGroup direction="vertical">
                        <Panel
                            id={"hpanel_2_0"}
                            key={"hpanel_2_0"} defaultSize={panelHPanel20.size} minSize={0} ref={panelHPanel20Ref}>
                            <div className='main-space-top-left-button-container' >
                                <IconButton variant="plain" style={{ opacity: 0.5, zIndex: 10 }} onClick={() => {
                                    if (!panelVPanel20Ref.current) return;
                                    if (panelVPanel20Ref.current.getSize() < 10) {
                                        panelVPanel20Ref.current.resize(30)
                                        setPanelVPanel20((prevState: any) => ({ ...prevState, id: "vpanel_2_0", size: 30 }))
                                    } else {
                                        panelVPanel20Ref.current.resize(0.1)
                                        setPanelVPanel20((prevState: any) => ({ ...prevState, id: "vpanel_2_0", size: 0.1 }))
                                    }
                                    savePanelData(false)
                                }}>
                                    <KeyboardArrowRight color={getArrowColor("vpanel_2_0", "right", allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_2_0')?.data || {}, panelHPanel20.mode || 'edit', allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_2_1')?.data || {}, panelHPanel21.mode || 'edit')} />
                                </IconButton>
                                <IconButton variant="plain" style={{ opacity: 0.5, zIndex: 10 }} onClick={() => {
                                    if (!panelHPanel21Ref.current) return;
                                    if (panelHPanel21Ref.current.getSize() < 10) {
                                        panelHPanel21Ref.current.resize(50)
                                        setPanelHPanel21((prevState: any) => ({ ...prevState, id: "hpanel_2_1", size: 50 }))

                                        if (panelHPanel20Ref.current) panelHPanel20Ref.current.resize(50);
                                        setPanelHPanel20((prevState: any) => ({ ...prevState, size: 50 }))
                                    } else {
                                        panelHPanel21Ref.current.resize(0.1)
                                        setPanelHPanel21((prevState: any) => ({ ...prevState, id: "hpanel_2_1", size: 0.1 }))

                                        if (panelHPanel20Ref.current) panelHPanel20Ref.current.resize(100);
                                        setPanelHPanel20((prevState: any) => ({ ...prevState, size: 100 }))
                                    }
                                    savePanelData(false)
                                }}>
                                    <ExpandLess color={getArrowColor("vpanel_2_0", "bottom", allEditorsData.dataBlocks.find((elem) => elem.id === 'hpanel_2_1')?.data || {}, panelHPanel21.mode || 'edit')} />
                                </IconButton>
                                {showShowNavBarButton()}
                                {helpButton("hpanel_2_0")}
                                {customizeButton("hpanel_2_0")}
                                {fullScreenBtn("vpanel_2_0")}
                                {menuBlock("hpanel_2_0")}
                            </div>
                            {getMainPanelView("hpanel_2_0")}
                        </Panel>
                        <PanelResizeHandle className="hr-panel-handler" style={{ backgroundColor: panelH21Size < 5 ? "transparent" : "gainsboro" }} onDragging={(dragging) => {
                            if (allEditorsData.currentTabShared) return;
                            savePanelData(dragging)
                        }} />
                        <Panel
                            id={"hpanel_2_1"}
                            key={"hpanel_2_1"} defaultSize={panelHPanel21.size} minSize={0.1} ref={panelHPanel21Ref} collapsible={true} collapsedSize={0.2} style={{ opacity: panelH21Size < 5 ? 0 : 1 }}>
                            <div className="editor-wrapper">
                                <EditorComponent readonly={allEditorsData.currentTabShared} loading={loading} data={panelHPanel21.data} style={{ height: (panelHPanel21.size - 8) + "vh", marginTop: allEditorsData.currentTabShared ? "0px" : "30px" }} id={"_2_1"} />
                            </div>
                        </Panel>
                    </PanelGroup>
                </Panel > */}
            </PanelGroup >
        </>
    );
};

export default Panels;