
import { useParams } from "react-router-dom";
import Panels from "../components/MainUI/Panels";
import { useContext, useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { LinearProgress, Typography, useColorScheme } from "@mui/joy";
import { AuthContext } from "../core/AuthProvider";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { setAllEditorsData, setAllTabs, setAllTabsData, setAllTabsIncludingDeleted, setCurrentTabArchived, setCurrentTabShared, setCurrentWorkspaceSmallInfo, setExportLayoutOrder, setIdMode, setIsLoadingTabFinished, setIsNewTabOpening, setIsSpaceIsOwner, setIsSpaceIsShared, setLoadingEditorData, setReadonlyLastUpdatedTime, setWholeEditorLoading } from "../state/allEditorsData/allEditorDataSlice"
import { setCurrentPanel } from "../state/mobileNavData/mobileNavData";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import MobilePanels from "../components/MainUI/MobilePanels";
import { ErrorOutline } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import LayoutSelection from "./components/LayoutSelection";


export const Mainspace = (): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const allEditorsData = useSelector((state: RootState) => state.allEditorsData);
    const authContext = useContext(AuthContext);
    const { user, initializing } = authContext;
    const [noSpace, setNoSpace] = useState<boolean>(false);
    const [initDataLoaded, setInitDataLoaded] = useState<boolean>(false);
    const [panelData, setPanelData] = useState<any[]>([]);
    const wid = useParams<{ wid: string }>().wid || "";
    const tid = useParams<{ tid: string }>().tid || "";
    const { mode, systemMode } = useColorScheme();


    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            // console.log('allEditorsData.isSpaceIsOwner',)
            if (allEditorsData.unsavedChanges && allEditorsData.isSpaceIsOwner) {
                event.preventDefault();
                return "";
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [allEditorsData.unsavedChanges, allEditorsData.isSpaceIsOwner]);

    const panelFetchDataAll = async () => {

        // console.log("Panel fetch data all", wid, tid);
        const panelFetchData: any[] = [];
        try {
            const querySnapshot = await getDocs(collection(db, "spaces", wid, "tabs", tid, "panels"));

            querySnapshot.forEach((doc) => {
                // console.log(doc.data());

                // console.log('allEditorsData.allTabsUnsavedData[tid]', allEditorsData.allTabsUnsavedData[tid])
                let appendingData: any = {};
                if (allEditorsData.allTabsUnsavedData[tid]) {
                    appendingData = allEditorsData.allTabsUnsavedData[tid].find((panel: any) => panel.id === doc.id);
                    // console.log('appendingData', appendingData)
                    if (appendingData?.data) {
                        // console.log('before appendingData.data', appendingData.data)
                        let appendingDataCloned: any = JSON.parse(JSON.stringify(appendingData));
                        // const stringifiedData = JSON.stringify(appendingData.data);
                        // appendingDataCloned.data = stringifiedData;
                        // console.log('appendingData.data', stringifiedData)

                        appendingData = appendingDataCloned;
                    }
                    // console.log('tabDatFromOurStore', appendingData)
                }
                // console.log('docdata', doc.data())
                // panelFetchData.push(doc.data());
                if (appendingData && Object.keys(appendingData).length > 0) {
                    const docDataCloned = JSON.parse(JSON.stringify(doc.data()));
                    const docDataAssembly = {
                        data: JSON.parse(docDataCloned.data),
                        id: docDataCloned.id,
                        type: docDataCloned.type,
                        mode: docDataCloned.mode,
                        size: docDataCloned.size,
                    }


                    // console.log('before merge data', docDataAssembly)
                    const merged = { ...docDataAssembly, ...appendingData };

                    const mergedClone = JSON.parse(JSON.stringify(merged));

                    mergedClone.data = JSON.stringify(merged.data);
                    // console.log('after merge data', mergedClone)
                    panelFetchData.push(mergedClone);
                    dispatch(setAllTabsData({
                        tabId: tid,
                        id: doc.id,
                        data: mergedClone
                    }))
                } else {
                    const dataCloned = JSON.parse(JSON.stringify(doc.data()));

                    // console.log('dataCloned', dataCloned)
                    panelFetchData.push({ ...doc.data() });

                    //coming is already string
                    // console.log('before dataCloned.data', dataCloned)
                    // const dataToString = JSON.stringify(dataCloned.data);
                    // dataCloned.data = dataToString;
                    // console.log('after dataCloned.data', dataCloned)
                    dispatch(setAllTabsData({
                        tabId: tid,
                        id: doc.id,
                        data: dataCloned
                    }))
                }


            });
            setInitDataLoaded(true);
            // console.log('panelFetchData', panelFetchData)


            setForPanels(panelFetchData);
        } catch (e) {
            Sentry.captureException(e);
            // console.log("Error fetching data", e);
        }
    }

    const setForPanels = (panelFetchData: any) => {

        // console.log("Panel fetch data", panelFetchData);
        // if (panelFetchData.length === 0) {
        //     console.log('No panel data found')
        //     setNoSpace(true);
        //     return;
        // } else {
        //     setNoSpace(false);
        // }
        // console.log({ panelData });
        setPanelData(panelFetchData);
        dispatch(setIsLoadingTabFinished(true));
        sessionStorage.setItem("wid", wid);
        sessionStorage.setItem("tid", tid);

        // console.log("reached the end of loading")
        dispatch(setWholeEditorLoading(false));
    }

    useEffect(() => {
        if (wid && tid) {
            // console.log('setting init data loaded false')
            setInitDataLoaded(false);
            setPanelData([]);
            dispatch(setWholeEditorLoading(true));
        }
    }, [wid, tid])


    useEffect(() => {
        let unsubscribe: any;
        // console.log('change of effect', wid, tid, allEditorsData.allTabs.length, allEditorsData.isSpaceIsOwner, allEditorsData.currentTabShared, initDataLoaded)
        if (wid && tid && !allEditorsData.isSpaceIsOwner && allEditorsData.currentTabShared) {
            // console.log('can never come gere')
            const panelRef = query(collection(db, "spaces", wid, "tabs", tid, "panels"));
            unsubscribe = onSnapshot(panelRef, (querySnapshot) => {
                const panelFetchData: any[] = [];
                querySnapshot.forEach((doc) => {
                    const docdata = doc.data();
                    panelFetchData.push(docdata);

                });
                dispatch(setReadonlyLastUpdatedTime(new Date().getTime()));
                setForPanels(panelFetchData);

            });

        } else if (wid && tid && allEditorsData.isSpaceIsOwner && !allEditorsData.currentTabShared && !initDataLoaded) {
            // console.log("panel fetch original")
            // setTimeout(() => {
            panelFetchDataFromRedux();

            // }, 2000);
        }
        // else if (initDataLoaded) {
        //     setNoSpace(true);
        // }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };

    }, [wid, tid, allEditorsData.isSpaceIsOwner, allEditorsData.currentTabShared, allEditorsData.allTabsUnsavedData, initDataLoaded])

    const panelFetchDataFromRedux = () => {
        // console.log("Panel fetch data from redux", wid, tid);
        // const panelFetchData: any[] = [];
        // const tabData = allEditorsData.allTabsData[tid];
        // console.log('all tabData', tabData)
        // if (tabData) {
        //     tabData.forEach((doc: any) => {
        //         console.log('coming inside')
        //         let appendingData: any = {};
        //         if (allEditorsData.allTabsUnsavedData[tid]) {
        //             appendingData = allEditorsData.allTabsUnsavedData[tid].find((panel: any) => panel.id === doc.id);
        //             console.log('appendingData for real', appendingData)
        //             if (appendingData?.data) {
        //                 // console.log('before appendingData.data', appendingData.data)
        //                 let appendingDataCloned: any = JSON.parse(JSON.stringify(appendingData));
        //                 // const stringifiedData = JSON.stringify(appendingData.data);
        //                 // appendingDataCloned.data = stringifiedData;
        //                 // console.log('appendingData.data', stringifiedData)

        //                 appendingData = appendingDataCloned;
        //             }
        //             // console.log('tabDatFromOurStore', appendingData)
        //         }

        //         console.log('doc.data true', doc.data)
        //         console.log('appendingData true', appendingData)
        //         // panelFetchData.push({ ...doc.data });

        //         if (appendingData) {
        //             console.log('doc comparison', doc.data, appendingData)

        //             const docDataCloned = JSON.parse(JSON.stringify(doc.data));

        //             const docDataAssembly = {
        //                 data: JSON.parse(docDataCloned.data),
        //                 id: doc.id,
        //                 type: doc.type,
        //                 mode: doc.mode,
        //                 size: doc.size,
        //             }
        //             const merged = { ...docDataAssembly, ...appendingData };
        //             console.log('merged for real', merged)
        //             const dataToString = JSON.stringify(merged.data);
        //             merged.data = dataToString;

        //             console.log('merged real', merged)
        //             panelFetchData.push(merged);
        //         } else {
        //             const dataCloned = JSON.parse(JSON.stringify(doc.data));

        //             console.log('dataCloned', dataCloned)
        //             const dataToString = JSON.stringify(dataCloned.data);

        //             const docDataAssembly = {
        //                 data: dataToString,
        //                 id: doc.id,
        //                 type: doc.type,
        //                 mode: doc.mode,
        //                 size: doc.size,
        //             }

        //             console.log('dataCloned real', docDataAssembly)
        //             panelFetchData.push(docDataAssembly);
        //         }
        //     });
        //     console.log("setting inside")
        //     setForPanels(panelFetchData);
        // }
        // console.log("setting outside")
        panelFetchDataAll();
    }

    const getAllDocs = async () => {

        // let notLoggedIn = false;
        // if (!user) {
        //     notLoggedIn = true;
        // }

        // console.log("Getting all docs", wid, tid);

        dispatch(setLoadingEditorData(true));
        // const userId = user.uid;
        // console.log("Fetching data for ", wid, tid);



        // console.log("Workspace data", workspaceData);
        // let isDocCreatedByUser = false;
        // const currentWorkspace = workspaceData.find((workspace: any) => workspace.wid === wid);

        // console.log("Current workspace", currentWorkspace, workspace.empty || !currentWorkspace, currentWorkspace.createdby, userId);
        // if (workspace.empty || !currentWorkspace) {
        //     console.log("No workspace found");
        //     setNoSpace(true);
        //     return;
        // } else if (currentWorkspace.createdby !== userId) {
        //     dispatch(setIsSpaceIsOwner(false));
        //     console.log("Not the creator");
        // } else if (currentWorkspace.createdby === userId) {
        //     console.log("Creator");
        //     dispatch(setIsSpaceIsOwner(true));
        //     isDocCreatedByUser = true;
        // }
        const tabs: any[] = [];
        const allTabs: any[] = [];
        let isWholeSpaceShared = false;
        //user can be a new user, a registered user who is not creater
        const tabRef = collection(db, "spaces", wid, "tabs");
        let userIdOfSpace = "";
        getDocs(query(tabRef, orderBy('no', 'asc'))).then((tabData: any) => {
            // console.log("Tabs", tabData.docs.length);
            // userIdOfSpace = tabData[0].data().createdby; //only one user will create all the tabs in a space
            tabData.forEach((doc: any) => {
                if (doc.data().createdby) {
                    userIdOfSpace = doc.data().createdby;
                }

                if (doc.data().id === "1") {
                    if (doc.data().spaceShared) {
                        // console.log("Whole space shared");
                        isWholeSpaceShared = true;
                        dispatch(setIsSpaceIsShared(true));
                    }
                }

                if (!doc.data().id) {
                    return;
                }
                // console.log("Tab doc", doc.id, doc.data());
                if (user?.uid) {

                    if (user?.uid && userIdOfSpace === user?.uid) {
                        let passThrough = false;

                        if (getifAttrbuteNotThereOrFalse(doc.data(), 'archived')) {//if not archived and archived and current tab
                            passThrough = true;
                        } else {
                            if (tid === doc.id) {
                                passThrough = true;
                            }
                        }

                        if (getifAttrbuteNotThereOrFalse(doc.data(), 'deleted') && passThrough) { //if not deleted or archived
                            tabs.push({
                                id: doc.id,
                                name: doc.data().title,
                                showInput: false,
                                public: doc.data().public,
                                no: doc.data().no,
                                archived: doc.data().archived,
                            });
                        }


                        allTabs.push({
                            id: doc.id,
                            name: doc.data().title,
                            showInput: false,
                            public: doc.data().public,
                            no: doc.data().no,
                            archived: doc.data().archived,
                        });

                        if (doc.id.toString() === tid.toString()) {
                            dispatch(setCurrentTabShared(false));
                            dispatch(setIsSpaceIsOwner(true));
                        }



                        dispatch(setExportLayoutOrder([]));
                        getDocs(collection(db, "spaces", wid, "tabs", tid, "tabInfo")).then((exportLayout) => {
                            exportLayout.forEach((doc) => {
                                if (doc.id === 'exportlayout') {
                                    dispatch(setExportLayoutOrder(doc.data()['layout']));
                                }
                            });
                        });

                    } else {
                        let passThrough = false;

                        if (getifAttrbuteNotThereOrFalse(doc.data(), 'deleted') && (doc.data().public || isWholeSpaceShared)) {
                            if (!doc.data().public && doc.data().archived) {
                                passThrough = false;
                            } else {
                                passThrough = true;
                            }
                        }

                        if (passThrough) {
                            if (doc.id.toString() === tid.toString()) {
                                dispatch(setCurrentTabShared(true));
                            }
                            if (getifAttrbuteNotThereOrFalse(doc.data(), 'deleted') && getifAttrbuteNotThereOrFalse(doc.data(), 'archived')) {
                                tabs.push({
                                    id: doc.id,
                                    name: doc.data().title,
                                    showInput: false,
                                    public: doc.data().public,
                                    no: doc.data().no,
                                    archived: doc.data().archived,
                                });
                            }
                        } else {
                            if (doc.id.toString() === tid.toString()) {
                                dispatch(setCurrentTabShared(false));
                            }
                        }

                        dispatch(setIsSpaceIsOwner(false));
                        allTabs.push({
                            id: doc.id,
                            name: doc.data().title,
                            showInput: false,
                            public: doc.data().public,
                            no: doc.data().no,
                            archived: doc.data().archived,
                        });
                    }

                } else {
                    let passThrough = false;

                    if (getifAttrbuteNotThereOrFalse(doc.data(), 'deleted') && (doc.data().public || isWholeSpaceShared)) {
                        if (!doc.data().public && doc.data().archived) {
                            passThrough = false;
                        } else {
                            passThrough = true;
                        }
                    }

                    if (passThrough) {
                        tabs.push({
                            id: doc.id,
                            name: doc.data().title,
                            showInput: false,
                            public: doc.data().public,
                            no: doc.data().no,
                            archived: doc.data().archived,
                        });

                        if (doc.id.toString() === tid.toString()) {
                            dispatch(setCurrentTabShared(true));
                            dispatch(setIsSpaceIsOwner(false));
                        }
                    }
                    allTabs.push({
                        id: doc.id,
                        name: doc.data().title,
                        showInput: false,
                        public: doc.data().public,
                        no: doc.data().no,
                        archived: doc.data().archived,
                    });
                }

                if (doc.id.toString() === tid.toString()) {
                    // console.log("Current tab archived", doc.data());
                    if (doc.data().archived) {
                        dispatch(setCurrentTabArchived(true));
                    }
                }
            });
            dispatch(setAllTabs(tabs));
            dispatch(setAllEditorsData([]));
            // console.log('adding to setAllTabsIncludingDeleted', allTabs.length);
            dispatch(setAllTabsIncludingDeleted(allTabs));

            if (allTabs.length === 0) {
                setNoSpace(true);
                return;
            }



            getDocs(collection(db, "data", userIdOfSpace, "spaces")).then((workspace) => {
                // console.log("Workspace");
                // let workspaceData: any[] = [];
                workspace.forEach((doc) => {
                    // console.log("Workspace doc", doc.data());
                    // workspaceData.push(doc.data());
                    if (doc.data().wid === wid) {
                        dispatch(setCurrentWorkspaceSmallInfo(doc.data()));
                        if (doc.data().modified) {
                            dispatch(setReadonlyLastUpdatedTime(doc.data().modified));
                        }
                    }
                });

            }).catch((e) => {
                Sentry.captureException(e);
                // console.log("Error fetching workspace", e);
            })

        }).catch((e) => {
            Sentry.captureException(e);
            // console.log("Error fetching tabs", e)
        });

    }

    const getifAttrbuteNotThereOrFalse = (data: any, booleanAttribute: string) => {
        if ((data[booleanAttribute] === null || data[booleanAttribute] === undefined) || (data[booleanAttribute] !== null && data[booleanAttribute] !== undefined && data[booleanAttribute] === false)) {
            return true;
        }
    }

    useEffect(() => {

    }, [allEditorsData.isNewTabOpening])

    useEffect(() => {
        // console.log("Mainspace useEffect", { tid, wid })
        if (wid === "" || tid === "") {
            setNoSpace(true);
            return;
        } else {
            setNoSpace(false);
        }

        if (!initializing) {
            getAllDocs();
            if (isMobile) {
                dispatch(setCurrentPanel("hpanel_0_0"));
            }
        }
    }, [wid, tid, initializing, user])

    // console.log("id mode change", allEditorsData.idMode);
    // console.log("loading changes", allEditorsData.wholeEditorLoading)

    return (
        <div className="main-space">
            {/* {allEditorsData.isNewTabOpening ? (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', paddingTop: "80px" }}>
                <LayoutSelection onClick={(id: number) => {
                    setIdMode(id);
                    dispatch(setIsNewTabOpening(false));
                }} />
            </div>
        )
            : */}
            {/* ( */}
            <>
                {initializing || allEditorsData.wholeEditorLoading ? (<div className="linear-progress-container">
                    {noSpace ? (
                        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <ErrorOutline style={{ height: "50px", width: "50px" }} />
                            <Typography>Space not found</Typography>
                        </div>
                    ) : <div className="linear-progress">
                        <LinearProgress size="md" />
                    </div>}

                </div>) : isMobile ? <MobilePanels panelData={panelData} /> : (
                    <Panels idMode={allEditorsData.idMode} panelData={panelData} />
                )
                }
                {allEditorsData.isNewTabOpening && (
                    <div style={{ position: 'absolute', bottom: 0, right: 0, top: "37px", left: 0, zIndex: 1000, padding: "10px", background: mode === "light" || (mode === "system" && systemMode === "light") ? 'white' : '#0B0D0E', height: 'calc(100%-37px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LayoutSelection onClick={(id: number) => {
                            dispatch(setIdMode(id));
                            dispatch(setIsNewTabOpening(false));
                        }} /></div>
                )}
            </>
            {/* ) */}
            {/* } */}
        </div >
    );
};
