import { ChevronLeft, Close } from "@mui/icons-material";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    FormLabel,
    IconButton,
    Input,
    Sheet,
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from "@mui/joy";
import attributes from "../utilities/attributes";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../core/AuthProvider";
import { updateProfile, updatePassword, updateEmail } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { collection, doc, getDocs, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import * as Sentry from "@sentry/react";


const Settings = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { user, signout } = authContext;

    const [userFullName, setUserFullName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState(false);
    const [userFullNameError, setUserFullNameError] = useState(false);
    const [userEmailError, setUserEmailError] = useState(false);

    const [passwordEnabled, setPasswordEnabled] = useState(false);
    const [userNewPassword, setUserNewPassword] = useState("");
    const [userConfirmPassword, setUserConfirmPassword] = useState("");

    const [userPasswordError, setUserPasswordError] = useState(false);
    const [userFullNameErrorText, setUserFullNameErrorText] = useState("");
    const [userEmailErrorText, setUserEmailErrorText] = useState("");
    const [userPasswordErrorText, setUserPasswordErrorText] = useState("");

    const [disabledUserInfoSaveBtn, setDisabledUserInfoSaveBtn] = useState(true);
    const [disabledPasswordSaveBtn, setDisabledPasswordSaveBtn] = useState(true);

    const [overlayLoading, setOverlayLoading] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            setUserFullName(user.displayName || "");
            setUserEmail(user.email);
            setEmailVerified(user.emailVerified);

            if (user.providerData.length > 0) {
                // setEmailEnabled(true);
                for (let i = 0; i < user.providerData.length; i++) {
                    if (user.providerData[0].providerId === 'password') {
                        setPasswordEnabled(true);
                        break;
                    }
                }
            }
        }
    }, [user])


    const updateProfileDet = () => {

        if (user) {
            setDisabledUserInfoSaveBtn(true);
            Promise.allSettled([
                updateProfile(user, {
                    displayName: userFullName
                }),
                updateEmail(user, userEmail),
                setDoc(doc(db, "data", user?.uid, "userInfo", user?.uid), {
                    name: userFullName,
                    email: userEmail,
                    modified: new Date().getTime(),
                }, { merge: true })
            ]).then(() => {
                setDisabledUserInfoSaveBtn(false);
            }).catch(() => {
                alert("Error updating profile information, Please try again later");
                setDisabledUserInfoSaveBtn(false);
            });
        }
    }

    const deleteAccount = async () => {
        try {
            const userlastLoginAt = parseInt(user.metadata.lastLoginAt);
            const currentUnixTimeInMs = new Date().getTime();
            const diff = currentUnixTimeInMs - userlastLoginAt;

            // console.log("User last login at", diff);
            const tenMinsInMilliseconds = 10 * 60 * 1000;
            if (diff >= tenMinsInMilliseconds) {
                alert("You need to be logged in within at least 10 minutes before deleting the account.");
                return;
            }


            const confirmed = window.confirm("Are you sure you want to delete this account? This action cannot be undone.");

            if (confirmed) {
                const userId = user.uid;
                //delete panels
                setOverlayLoading(true);

                const batch = writeBatch(db);
                // console.log("workspace.wid", workspace.wid)
                const querySnapshot = await getDocs(collection(db, "data", userId, "spaces"));

                for (const wDoc of querySnapshot.docs) {
                    const wid = wDoc.data().wid;

                    // console.log("wid", wid)
                    const workspaceTabs = (await getDocs(collection(db, "spaces", wid, "tabs"))).docs.map(doc => doc.data());

                    // console.log("Workspace tabs", workspaceTabs);
                    if (workspaceTabs.length === 0) {
                        alert("Error while deleting workspace. Please logout/login and try again.");
                        return
                    }

                    for (const tab of workspaceTabs) {
                        const querySnapshot = await getDocs(collection(db, "spaces", wid, "tabs", tab.id, "panels"));
                        // console.log("Query snapshot", querySnapshot.docs.length);
                        for (const doc of querySnapshot.docs) {
                            // await deleteDoc(doc.ref);
                            batch.delete(doc.ref);
                        }

                        const tabInfos = await getDocs(collection(db, "spaces", wid, "tabs", tab.id, "tabInfo"));
                        for (const doc of tabInfos.docs) {
                            // await deleteDoc(doc.ref);
                            batch.delete(doc.ref);
                        }
                    }
                    //delete tabs
                    for (const tab of workspaceTabs) {
                        // await deleteDoc(doc(db, workspace.id, workspace.id, "tabs", tab.id));
                        batch.delete(doc(db, "spaces", wid, "tabs", tab.id));
                    }
                    //delete workspace
                    // await deleteDoc(doc(db, workspace.id, workspace.id));
                    batch.delete(doc(db, "spaces", wid));
                    // await deleteDoc(doc(db, "data", userId, "spaces", workspace.id));
                    batch.delete(doc(db, "data", userId, "spaces", wid));
                }


                //delete user data
                batch.delete(doc(db, "data", userId, "userInfo", userId));
                batch.delete(doc(db, "data", userId, "userInfo", "lastWorkedWid"));
                batch.delete(doc(db, "data", userId, "userInfo", "prefs"));
                batch.delete(doc(db, "data", userId));

                await batch.commit();

                sessionStorage.setItem("hideNavBar", "false");
                sessionStorage.setItem("allTabInfo", "");
                sessionStorage.setItem("allspaces", "");
                localStorage.setItem("userId", "");
                localStorage.setItem("userIdno", "");
                sessionStorage.setItem("wid", "");
                sessionStorage.setItem("tid", "");

                //reset redux states

                signout(async () => {
                    //delete user
                    await user.delete();
                    navigate("/login");
                });
            }
        } catch (e) {
            // console.log(e)
            Sentry.captureException(e);
            setOverlayLoading(false);
            alert("Error while deleting account. Please try again or You can open a support ticket.");
        }



    }

    const updatePasswordDet = () => {

        if (user) {
            if (userNewPassword !== userConfirmPassword) {
                setUserPasswordError(true);
                setUserPasswordErrorText("Passwords do not match");
                return;
            }

            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

            if (userNewPassword.length < 6 || !passwordRegex.test(userNewPassword)) {
                setUserPasswordError(true);
                setUserPasswordErrorText("Password must be at least 6 characters long including 1 uppercase, 1 lowercase, 1 number and 1 special character");
                return;
            }

            setDisabledPasswordSaveBtn(false);

            setUserPasswordError(false);
            setUserPasswordErrorText("");

            updatePassword(user, userNewPassword).then(() => {
                setDisabledPasswordSaveBtn(true);
            }).catch((e) => {
                setDisabledPasswordSaveBtn(false);
                if (e.code === "auth/requires-recent-login") {
                    alert("You need to re-login to update the password or you can use forgot password from the login.");
                } else {
                    alert("Error updating password, Please try again later");
                }

            });
        }
    }

    return (
        <div className={"page-content-wrapper"}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <IconButton onClick={() => {
                    const wid = sessionStorage.getItem("wid");
                    const tid = sessionStorage.getItem("tid");

                    // console.log(wid, tid)

                    if (wid && tid) {
                        navigate(`/${wid}/${tid}`);
                    } else {
                        navigate(`/`);
                    }
                }} variant="plain" sx={{ height: "50px", width: "60px" }}>
                    <ChevronLeft sx={{ height: "40px", width: "40px" }} />
                </IconButton>
                <h1 style={{ marginLeft: "20px" }}>Settings</h1>
            </div>
            <Tabs aria-label="Basic tabs" defaultValue={0}>
                <TabList>
                    <Tab>User settings</Tab>
                    {/* <Tab>Integrations</Tab>
                    <Tab>Subscriptions</Tab> */}
                </TabList>
                <TabPanel sx={{}} value={0}>
                    <Box sx={{ paddingBottom: "200px" }}>
                        <Sheet sx={{ height: "auto", marginTop: "20px" }}>
                            <b>User Information</b>
                            <FormControl sx={{ marginTop: "20px", width: attributes.fieldNormalWidth }} error={userFullNameError}>
                                <FormLabel>Name:</FormLabel>
                                <Input
                                    value={userFullName}
                                    onChange={(e) => {
                                        setDisabledUserInfoSaveBtn(false);
                                        if (!/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                            setUserFullNameError(true);
                                            setUserFullNameErrorText("Only alphabets and spaces are allowed");
                                        } else {
                                            setUserFullNameError(false);
                                            setUserFullNameErrorText("");
                                        }
                                        setUserFullName(e.target.value)
                                    }}
                                    placeholder="Name"
                                />
                                <FormHelperText>{userFullNameErrorText}</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ marginTop: "10px", width: attributes.fieldNormalWidth }} error={userEmailError}>
                                <FormLabel>Email:</FormLabel>
                                <Input
                                    type={"email"}
                                    value={userEmail}
                                    disabled={true}
                                    placeholder="Email"
                                    onChange={(e) => {
                                        setDisabledUserInfoSaveBtn(false);
                                        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                            e.target.value
                                        )) {
                                            setUserEmailError(true);
                                            setUserEmailErrorText("Invalid email address");
                                        } else {
                                            setUserEmailError(false);
                                            setUserEmailErrorText("");
                                        }
                                        setUserEmail(e.target.value)
                                    }}
                                />
                                <FormHelperText>{userEmailErrorText}</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ marginTop: "10px", width: attributes.fieldNormalWidth }} error={false}>
                                <Checkbox label="Verified" checked={emailVerified} disabled={true} />
                                <FormHelperText></FormHelperText>
                            </FormControl>
                            <FormControl sx={{ marginTop: "30px" }} error={false}>
                                <Button disabled={disabledUserInfoSaveBtn} onClick={updateProfileDet} sx={{ width: attributes.btnNormalWidth }}>Save Changes</Button>
                            </FormControl>
                        </Sheet>

                        <Sheet sx={{ height: "auto", marginTop: "40px" }}>
                            <b>Password Management (only available for email/password login users)</b>
                            <FormControl sx={{ marginTop: "20px", width: attributes.fieldNormalWidth }} error={userPasswordError}>
                                <FormLabel>New Password:</FormLabel>
                                <Input
                                    disabled={!passwordEnabled}
                                    value={userNewPassword}
                                    onChange={(e) => {
                                        setDisabledPasswordSaveBtn(false);
                                        setUserNewPassword(e.target.value)
                                    }}
                                    placeholder="Password"
                                    type={"password"}
                                />
                            </FormControl>
                            <FormControl sx={{ marginTop: "10px", width: attributes.fieldNormalWidth }} error={userPasswordError}>
                                <FormLabel>Confirm Password:</FormLabel>
                                <Input
                                    disabled={!passwordEnabled}
                                    value={userConfirmPassword}
                                    onChange={(e) => {
                                        setDisabledPasswordSaveBtn(false);
                                        setUserConfirmPassword(e.target.value)
                                    }}
                                    placeholder="Confirm password"
                                    type={"password"}
                                />
                                <FormHelperText>{
                                    userPasswordErrorText
                                }</FormHelperText>
                            </FormControl>
                            <FormControl sx={{ marginTop: "30px" }} error={false}>
                                <Button disabled={disabledPasswordSaveBtn || !passwordEnabled} onClick={updatePasswordDet} sx={{ width: attributes.btnNormalWidth }}>Update password</Button>
                            </FormControl>
                        </Sheet>
                        <Sheet sx={{ height: "100px", marginTop: "40px" }}>
                            <b>Account Management</b>
                            <FormControl sx={{ marginTop: "10px" }} error={false}>
                                <FormLabel>
                                    All data contained within spaces and tabs will be permanently erased when you delete the account.
                                </FormLabel>
                                <Button
                                    onClick={deleteAccount}
                                    sx={{ width: attributes.btnNormalWidth, marginTop: "30px" }}
                                    variant='solid'
                                    color="danger"
                                    startDecorator={<Close />}
                                >
                                    Delete
                                </Button>
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Sheet>
                    </Box>
                </TabPanel>
                {/* <TabPanel sx={{ backgroundColor: "yellow" }} value={1}>
                    <b>Second</b> tab panel
                </TabPanel>
                <TabPanel sx={{ backgroundColor: "green" }} value={2}>
                    <b>Third</b> tab panel
                </TabPanel> */}
            </Tabs>
            {
                overlayLoading ? <div style={{ display: 'flex', position: 'absolute', left: 0, right: 0, top: 0, backgroundColor: 'white', opacity: 0.7, height: '100%', alignItems: 'center', justifyContent: 'center', zIndex: 2 }}><CircularProgress /></div> : null

            }
        </div >
    );
};

export default Settings;
