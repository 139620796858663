import { CssBaseline, CssVarsProvider } from "@mui/joy";
import ReactDOM from 'react-dom/client'
import { Router } from "./route/index";
import { theme } from "./core/theme";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";

import './index.css'
import '@fontsource/inter';
import AuthProvider from "./core/AuthProvider";
import { store } from "./state/store";
import LibsProvider from "./core/LibsProvider";

import * as Sentry from "@sentry/react";

declare global {
  interface Window {
    EXCALIDRAW_ASSET_PATH: string;
  }
}

const envRunning = import.meta.env.PROD;


window.EXCALIDRAW_ASSET_PATH = "/";



if (envRunning) {
  ReactGA.initialize("G-HL0J5N162J");
  ReactGA.send({ hitType: "apploaded", page: window.location.pathname });
  Sentry.init({
    dsn: "https://c6184d3eea8201ce592ba74d66c08fb2@o842948.ingest.us.sentry.io/4507431180238848",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <CssVarsProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <AuthProvider>
        <LibsProvider>
          <Router />
        </LibsProvider>
      </AuthProvider>
    </Provider>
  </CssVarsProvider>,
)
