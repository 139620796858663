import { Grid, useColorScheme } from "@mui/joy";

const LayoutItem = ({ id, boxes, orderOfType, onClick, defaultPick }: { id: number, boxes: number, orderOfType?: any, onClick: any, defaultPick?: boolean }) => {

    const { mode, systemMode } = useColorScheme();

    const getIcon = (type: string) => {
        let icon = null;
        switch (type) {
            case "text":
                icon = (<svg className="svg-layout-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.5C1 4.10218 1 3.40326 1.22836 2.85195C1.53284 2.11687 2.11687 1.53284 2.85195 1.22836C3.40326 1 4.10218 1 5.5 1H20.5C21.8978 1 22.5967 1 23.148 1.22836C23.8831 1.53284 24.4672 2.11687 24.7716 2.85195C25 3.40326 25 4.10218 25 5.5M8.5 25H17.5M13 1V25" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>)
                    ;
                break;
            case "code":
                icon = (
                    <svg className="svg-layout-icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 27L33 18L24 9M12 9L3 18L12 27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                );
                break;
            case "number":
                icon = (
                    <svg className="svg-layout-fill-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.3871 27V16.0333H2V13H8.32258V27H5.3871ZM12.9894 27V20.7776C12.9894 20.1686 13.1806 19.6663 13.563 19.2708C13.9457 18.8753 14.432 18.6776 15.0217 18.6776H20.2152V16.0333H12.9894V13H21.1185C21.7078 13 22.1939 13.1977 22.5766 13.5932C22.9594 13.9884 23.1507 14.4906 23.1507 15.1V19.2224C23.1507 19.8314 22.9594 20.3337 22.5766 20.7292C22.1939 21.1247 21.7078 21.3224 21.1185 21.3224H15.9249V23.9667H23.1507V27H12.9894ZM26.8387 27V23.9667H34.0645V21.3224H29.0968V18.6776H34.0645V16.0333H26.8387V13H34.9677C35.5575 13 36.0435 13.1977 36.4259 13.5932C36.8086 13.9884 37 14.4906 37 15.1V24.9C37 25.5094 36.8086 26.0116 36.4259 26.4067C36.0435 26.8022 35.5575 27 34.9677 27H26.8387Z" />
                    </svg>

                );
                break;
            case "draw":
                icon = (
                    <svg className="svg-layout-icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16C1 24.2843 7.71573 31 16 31C18.4853 31 20.5 28.9853 20.5 26.5V25.75C20.5 25.0534 20.5 24.7051 20.5385 24.4126C20.8043 22.3933 22.3933 20.8043 24.4126 20.5385C24.7051 20.5 25.0534 20.5 25.75 20.5H26.5C28.9853 20.5 31 18.4853 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.5 17.5C9.32843 17.5 10 16.8284 10 16C10 15.1716 9.32843 14.5 8.5 14.5C7.67157 14.5 7 15.1716 7 16C7 16.8284 7.67157 17.5 8.5 17.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M22 11.5C22.8284 11.5 23.5 10.8284 23.5 10C23.5 9.17157 22.8284 8.5 22 8.5C21.1716 8.5 20.5 9.17157 20.5 10C20.5 10.8284 21.1716 11.5 22 11.5Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13 10C13.8284 10 14.5 9.32843 14.5 8.5C14.5 7.67157 13.8284 7 13 7C12.1716 7 11.5 7.67157 11.5 8.5C11.5 9.32843 12.1716 10 13 10Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                );
                break;
            default:
                icon = null;
        }
        return icon;
    }

    const itemClassName = mode === "light" || (mode === "system" && systemMode === "light") ? "layout-selection-item-inside-block" : "layout-selection-item-inside-block-dark";
    return (<Grid xs={2} sm={4} md={2} lg={2}>
        <div className={`${(mode === "light" || (mode === "system" && systemMode === "light")) ? "layout-selection-item" : "layout-selection-item-dark"} ${defaultPick ? 'selected' : ''}`}>
            {
                boxes === 2 ? (
                    <div onClick={() => onClick(id)} style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', gap: "15px" }}>
                        <div className={itemClassName}>
                            {getIcon(orderOfType?.gr00)}
                        </div>
                        <div className={itemClassName}>
                            {getIcon(orderOfType?.gr10)}
                        </div>
                    </div>
                ) : boxes === 3 ? (
                    <div onClick={() => onClick(id)} style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', gap: "15px" }}>
                        <div className={itemClassName}>
                            {getIcon(orderOfType?.gr00)}
                        </div>
                        <div style={{ display: 'flex', flex: 0.5, backgroundColor: 'transparent', flexDirection: 'column', gap: "15px" }}>
                            <div className={itemClassName}>
                                {getIcon(orderOfType?.gr10)}
                            </div>
                            <div className={itemClassName}>{getIcon(orderOfType?.gr11)}</div>
                        </div>
                    </div>
                ) : boxes === 4 ? (
                    <div onClick={() => onClick(id)} style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', gap: "15px" }}>
                        <div style={{ display: 'flex', flex: 0.5, backgroundColor: 'transparent', flexDirection: 'column', gap: "15px" }}>
                            <div className={itemClassName}>
                                {getIcon(orderOfType?.gr00)}
                            </div>
                            <div className={itemClassName}>
                                {getIcon(orderOfType?.gr01)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 0.5, backgroundColor: 'transparent', flexDirection: 'column', gap: "15px" }}>
                            <div className={itemClassName}>
                                {getIcon(orderOfType?.gr10)}
                            </div>
                            <div className={itemClassName}>
                                {getIcon(orderOfType?.gr11)}
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    </Grid >);
}

export default LayoutItem;